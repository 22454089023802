import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export interface FieldValue {
  field: React.ReactNode;
  value: React.ReactNode;
}

interface Props extends BoxProps {
  data: FieldValue[];
  fieldProps?: BoxProps;
  valueProps?: BoxProps;
}

/**
 * Displays a list of field-value pairs in a nice list format with the field right-aligned and the value left-aligned.
 * @param data
 * @param fieldFlex
 * @param rest
 * @constructor
 */
const FieldValueList = ({ data, fieldProps = { flex: 1 }, valueProps = { flex: 2 }, ...rest }: Props) => {
  return (
    <Box {...rest}>
      {data.map((item, index) => (
        <HStack key={index} w="full" alignItems="start" textAlign="left" pb={1}>
          <Box {...fieldProps}>
            <Text fontWeight="500">{item.field}</Text>
          </Box>
          <Box {...valueProps}>{item.value}</Box>
        </HStack>
      ))}
    </Box>
  );
};

export default FieldValueList;
