import { Node, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { createPartNoteEdge } from '../../../graph/EdgeFactory';
import { createNoteGroupNode } from '../../../graph/NodeFactory';
import { getNoteGroupNodeForElement, getNotePosition } from '../../../utils/notes';
import { useFlagNoteActions } from './useFlagNoteActions';

// Returns flag note menu actions for a design part node.
export const usePartFlagNoteActions = (closeMenu: VoidFunction, targetNode?: Node) => {
  const { getNodes } = useReactFlow();

  const noteGroupNodeForElement = getNoteGroupNodeForElement(getNodes(), targetNode?.id);

  const createNoteGroupNodeAndEdge = useCallback(() => {
    if (!targetNode) {
      return {};
    }

    const notePosition = getNotePosition(targetNode.position);
    const newNode = createNoteGroupNode(notePosition, { targetElementId: targetNode.id });
    const newEdge = createPartNoteEdge(newNode.id, targetNode.id);

    return { newNode, newEdge };
  }, [targetNode]);

  return useFlagNoteActions(closeMenu, { noteGroupNodeId: noteGroupNodeForElement?.id, createNoteGroupNodeAndEdge });
};
