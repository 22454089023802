import { groupBy } from 'lodash';

import { CableGroup } from '../hooks/useSchematicData.tsx';
import { ConductorData } from './mapConnectionToConductorData.ts';
import { mapGroupedConductors } from './mapGroupedConductors.ts';

/**
 * Get the twisting groups from the conductors. A twisting group is a group of conductors that are twisted together and
 * have the same cable name.
 * @param conductors
 */
export const getTwistingGroups = (conductors: ConductorData[]): CableGroup[] => {
  // Group by twistingGroup first
  const groupsByTwistingGroup = groupBy(
    conductors.filter((conductor) => conductor.twistingGroup !== null),
    (conductor) => conductor.twistingGroup,
  );

  // Flatten and further group by cableName within each twistingGroup
  const groupedByCableName = Object.values(groupsByTwistingGroup).flatMap((group) => {
    return Object.values(groupBy(group, (conductor) => conductor.cableName));
  });

  // Filter valid groups and map to CableGroup
  return groupedByCableName.filter((group) => group.length > 0).map(mapGroupedConductors);
};
