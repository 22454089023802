import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import React, { MouseEvent, ReactNode, useCallback, useState } from 'react';

interface Props extends ButtonProps {
  children: ReactNode;
  debounceTime?: number;
  isDisabledOnDebounce?: boolean;
  isLoadingOnDebounce?: boolean;
}

/**
 * Button component with debounce functionality
 * @param children Button content
 * @param debounceTime Debounce time in milliseconds
 * @param isDisabledOnDebounce Should the button be disabled while debounce is active
 * @param isLoadingOnDebounce Should the button show loading spinner while debounce is active
 * @param props Other Chakra button props
 * @constructor
 */
const Button: React.FC<Props> = ({
  children,
  debounceTime = 0,
  isDisabledOnDebounce = false,
  isLoadingOnDebounce = false,
  ...props
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const onClick = props.onClick;
  const isDisabled = props.isDisabled;
  const isLoading = props.isLoading;
  const colorScheme = props.colorScheme;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!isClicked && onClick) {
        setIsClicked(true);
        onClick(event);
        setTimeout(() => setIsClicked(false), debounceTime);
      }
    },
    [isClicked, onClick, debounceTime],
  );

  return (
    <ChakraButton
      {...props}
      onClick={handleClick}
      isDisabled={isDisabled || (isDisabledOnDebounce && isClicked)}
      isLoading={isLoading || (isLoadingOnDebounce && isClicked)}
      colorScheme={colorScheme}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
