import { EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';
import { getShieldingPath } from '../../utils/getShieldingPath.ts';

/**
 * ShieldingEdge component representing shielding between two conductors.
 * @constructor
 * @param props
 */
export const ShieldingEdge = (props: EdgeProps) => {
  const edgeStyle = {
    strokeDasharray: '4 4',
    strokeLinecap: 'square' as const,
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1.5,
    fill: 'none',
  };

  const [edgePath] = getShieldingPath(props, 2);

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
