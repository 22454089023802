import { HStack, Text } from '@chakra-ui/react';
import { DesignPart } from '@senrasystems/senra-ui';

interface Props {
  designPart: DesignPart;
  angle: number;
}

export const DesignPartLabel = ({ designPart, angle }: Props) => {
  const isFacingLeft = angle > 90 && angle < 270;

  return (
    <HStack
      position="absolute"
      top="10px"
      {...(isFacingLeft ? { right: '52px' } : { left: '52px' })}
      transform={`rotate(${angle}deg)`}
    >
      <HStack color="gray.900" fontFamily="mono" transform={`rotate(${-angle}deg)`}>
        <Text fontWeight="700">{designPart.name}</Text>
        <Text>{designPart.partData.partNumber}</Text>
      </HStack>
    </HStack>
  );
};
