import { Text } from '@chakra-ui/react';
import { Part, PartType, Wire } from '@senrasystems/senra-ui';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';
import { formatConductorDiameter } from '../../utils.tsx';

const WiresDashboard = () => {
  const methods = useForm<Wire>({ defaultValues: { type: PartType.WIRE } });

  const columns: CustomColumnDef[] = [
    {
      header: 'Gauge',
      renderFn: (part: Part) => {
        const wire = part as Wire;
        return <Text>{wire.gauge}</Text>;
      },
    },
    {
      header: 'Colors',
      renderFn: (part: Part) => {
        const wire = part as Wire;
        return <Text>{wire.colors.toString()}</Text>;
      },
    },
    {
      header: 'Inner Diameter',
      renderFn: (part: Part) => {
        const wire = part as Wire;
        return <Text>{formatConductorDiameter(wire.innerDiameter, wire.diameterUnitOfMeasurement)}</Text>;
      },
    },
    {
      header: 'Outer Diameter',
      renderFn: (part: Part) => {
        const wire = part as Wire;
        return <Text>{formatConductorDiameter(wire.outerDiameter, wire.diameterUnitOfMeasurement)}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.WIRE} columnDefs={columns} />
    </FormProvider>
  );
};

export default WiresDashboard;
