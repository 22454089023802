import { ResolvedConnection, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { flatMap } from 'lodash';
import { useMemo } from 'react';

import { useConnections } from '../../../hooks/useConnections';
import { compareConnections } from '../utils/compareConnections.ts';
import { createGroupedConnections } from '../utils/createGroupedConnections.ts';
import { getShieldingGroups } from '../utils/getShieldingGroups.ts';
import { getTwistingGroups } from '../utils/getTwistingGroups.ts';
import { ConductorData, mapConnectionToConductorData } from '../utils/mapConnectionToConductorData.ts';

export interface SchematicData {
  [designPartId: string]: {
    connectionGroup: ConnectionGroup;
  };
}

export interface ConnectionGroup {
  id: string;
  startPoint: ResolvedConnectionPoint | null;
  endPoint: ResolvedConnectionPoint | null;
  rows: ConnectionRow[];
  twistingGroups: CableGroup[];
  shieldingGroups: CableGroup[];
}

export interface ConnectionRow {
  connection: ResolvedConnection;
  showStartPoint: boolean;
  showEndPoint: boolean;
  showConductor: boolean;
}

export interface CableGroup {
  cableName: string;
  isTwisted: boolean;
  isShielded: boolean;
  shieldType: string;
  connectionPoints: [ResolvedConnectionPoint, ResolvedConnectionPoint];
}

/**
 * useSchematicData hook returns connection data organized for schematic visualization. It's a grouping of connections
 * by the destination design part, from the perspective of the selected design part.
 */
export const useSchematicData = () => {
  const { bidirectionalConnections: connections, isLoading, isSuccess, error } = useConnections();

  const sortedConnections = useMemo(() => {
    return connections.slice().sort(compareConnections);
  }, [connections]);

  const groupedConnections = useMemo(() => createGroupedConnections(sortedConnections), [sortedConnections]);

  const schematicData = useMemo((): SchematicData => {
    // Create a new schematicData object based on groupedConnections
    return Object.fromEntries(
      Object.entries(groupedConnections).map(([designPartId, { connectionGroup }]) => {
        // Extract connections from connectionGroup
        const connections = flatMap(connectionGroup.rows, (row) => row.connection);

        // Map connections to ConductorData and filter out null values
        const conductors = connections
          .map(mapConnectionToConductorData)
          .filter((data): data is ConductorData => data !== null);

        // Generate twistingGroups and shieldingGroups
        const twistingGroups = getTwistingGroups(conductors);
        const shieldingGroups = getShieldingGroups(conductors);

        // Return the updated group
        return [
          designPartId,
          {
            connectionGroup: {
              ...connectionGroup,
              twistingGroups,
              shieldingGroups,
            },
          },
        ];
      }),
    );
  }, [groupedConnections]);

  return {
    isLoading,
    isSuccess,
    error,
    schematicData: schematicData || {},
  };
};
