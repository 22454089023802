import { HStack } from '@chakra-ui/react';
import { IOrderTransition, Order, OrderStateEvent } from '@web/common/lib';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { apiTransitionOrder } from '../api/admin-api';
import { successToast } from '../common/toasts';
import EventActionButton from './buttons/EventActionButton';
import Confirm from './Confirm';

interface Props {
  order: Order;
  navigate: NavigateFunction;
  reloadOrders: () => void;
}

const OrderStateEventButtons = ({ order, navigate, reloadOrders }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  async function handleTransitionOrder<TTransition extends IOrderTransition>(transition: TTransition) {
    setSubmitting(true);
    const response = await apiTransitionOrder<TTransition>(transition);
    if (response) {
      reloadOrders();
      successToast('Order state updated');
    }
    setSubmitting(false);
  }

  const handleUploadPurchaseOrder = () => {
    navigate({ pathname: RouteNames.ORDERS.UPLOAD_PURCHASE_ORDER.replace(':orderId', order.id) });
  };

  const handleDecline = () => {
    navigate({ pathname: RouteNames.DECLINE_ORDER, search: `?o=${order.id}` });
  };

  const handleUploadQuote = () => {
    navigate({ pathname: RouteNames.MANUAL_QUOTE_UPLOAD.replace(':orderId', order.id) });
  };

  const ConfirmTransition = ({ event }: { event: OrderStateEvent }) => (
    <Confirm
      key={event}
      title="Confirm order transition"
      body={`Are you sure that you want to ${event.replace(/_/g, ' ')}?`}
      onConfirm={() => handleTransitionOrder({ orderId: order.id, event })}
    >
      <EventActionButton event={event} submitting={submitting} />
    </Confirm>
  );

  const getStateButtonJSX = (event: OrderStateEvent) => {
    if (order.stateEvents.length === 0) {
      return null;
    }

    switch (event) {
      case OrderStateEvent.UploadPurchaseOrder:
        return <EventActionButton key={event} event={event} onClick={handleUploadPurchaseOrder} />;
      case OrderStateEvent.Decline:
        return <EventActionButton key={event} event={event} onClick={handleDecline} />;
      case OrderStateEvent.UploadQuote:
        return <EventActionButton key={event} event={event} onClick={handleUploadQuote} />;
      default:
        return <ConfirmTransition event={event} />;
    }
  };

  return <HStack spacing={2}>{order.stateEvents.map((event) => getStateButtonJSX(event))}</HStack>;
};

export default OrderStateEventButtons;
