import { UUID } from '@senrasystems/senra-ui';
import { Edge, Node, Position, useReactFlow, XYPosition } from '@xyflow/react';

import { isSegmentNoteEdge } from '../../types';
import { calculateMidpoint, getTargetDirection } from '../../utils/geometry';

interface getPositionProps {
  targetNode?: Node;
  targetEdge?: Edge;
  getNode: (id: string) => Node | undefined;
}

const getTargetElementPosition = ({ targetNode, targetEdge, getNode }: getPositionProps): XYPosition | undefined => {
  if (targetNode) {
    return targetNode.position;
  } else if (targetEdge) {
    const edgeSourceNodeId = targetEdge.source;
    const edgeTargetNodeId = isSegmentNoteEdge(targetEdge) ? targetEdge.data?.segmentId : targetEdge.target;

    const edgeSourceNode = getNode(edgeSourceNodeId);
    const edgeTargetNode = getNode(edgeTargetNodeId || '');

    if (edgeSourceNode && edgeTargetNode) {
      return calculateMidpoint(edgeSourceNode.position, edgeTargetNode.position);
    }
  }

  return undefined;
};

// Returns horizontal and vertical direction from source to target
export const useCalculateTargetDirection = (sourceNodeId: UUID, targetElementId: UUID) => {
  const { getNode, getEdge } = useReactFlow();
  const sourceNode = getNode(sourceNodeId);
  const targetNode = getNode(targetElementId);
  const targetEdge = getEdge(targetElementId);

  if (sourceNode) {
    const targetPosition = getTargetElementPosition({ targetNode, targetEdge, getNode });

    if (targetPosition) {
      return getTargetDirection(sourceNode.position, targetPosition);
    }
  }

  return { horizontal: Position.Right, vertical: Position.Top };
};
