import { Box, HStack, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { defaultSchematicConfig } from '../../../config.ts';

interface Props extends StackProps {
  children?: ReactNode;
}

/**
 * A component to display conductor details in a consistent way. It displays the data, and fills the remaining space
 * with a transparent background so that the conductor line can be seen.
 * @param children
 * @param rest
 * @constructor
 */
export const ConductorDetail = ({ children, ...rest }: Props) => {
  return (
    <HStack alignItems="stretch" {...rest}>
      <Box bg={defaultSchematicConfig.style.backgroundColor} px={children ? 2 : 0}>
        {children}
      </Box>
      <Box bg="transparent" />
    </HStack>
  );
};
