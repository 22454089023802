import { ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { uniqBy } from 'lodash';

import { CableGroup } from '../hooks/useSchematicData.tsx';
import { ConductorData } from './mapConnectionToConductorData.ts';

/**
 * Maps a group of conductors, and creates 2 connection points using the first and last conductor in the group. If there
 * is only 1 conductor in the group, the tuple contains that conductor's ConnectionPoint.
 * @param group
 */
export const mapGroupedConductors = (group: ConductorData[]): CableGroup => {
  const uniqueWires = uniqBy(group, (conductor) => conductor.connectionPoint);

  const connectionPoints: [ResolvedConnectionPoint, ResolvedConnectionPoint] =
    uniqueWires.length === 1
      ? [uniqueWires[0].connectionPoint, uniqueWires[0].connectionPoint]
      : [uniqueWires[0].connectionPoint, uniqueWires[uniqueWires.length - 1].connectionPoint];

  return {
    cableName: uniqueWires[0].cableName,
    isTwisted: uniqueWires[0].twistingGroup !== null,
    isShielded: uniqueWires[0].isShielded,
    shieldType: uniqueWires[0].shieldType,
    connectionPoints,
  };
};
