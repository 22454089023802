import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const HintText = ({ children }: Props) => (
  <Text fontSize="xs" fontStyle="italic" mt={2}>
    {children}
  </Text>
);

export default HintText;
