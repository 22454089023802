import { Text } from '@chakra-ui/react';
import { Positioner, Tool, ToolType } from '@senrasystems/senra-ui';

import ToolsLibraryDashboard, { CustomColumnDef } from '../../components/ToolsLibraryDashboard.tsx';
import PositionerFields from './PositionerFields.tsx';

const columns: CustomColumnDef[] = [
  {
    header: 'Name',
    renderFn: (tool: Tool) => {
      const positioner = tool as Positioner;
      return <Text>{positioner.name}</Text>;
    },
  },
];

const PositionersDashboard = () => (
  <ToolsLibraryDashboard
    additionalFieldsComponent={PositionerFields}
    buttonText="Positioner"
    columnDefs={columns}
    routeName="positioners"
    title="Positioners"
    toolType={ToolType.POSITIONER}
  />
);

export default PositionersDashboard;
