import { BoxProps, useToken } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';

import ManagedWindow from '../../components/ManagedWindow.tsx';
import { AssemblyNavigator } from './AssemblyNavigator.tsx';
import { CollapsibleSidebar } from './CollapsibleSidebar.tsx';

export const AssemblyNavigatorSidebar = (props: BoxProps) => {
  const openWidth = useToken('sizes', 'md');

  return (
    <ManagedWindow
      title="Assembly Navigator"
      routeName={RouteNames.DESIGNS.DESIGN_PARTS}
      showTitle={false}
      position="relative"
      overflow="unset"
      bg="gray.50"
      {...props}
    >
      {({ hasFocus, windowIcon }) => (
        <CollapsibleSidebar openWidth={openWidth}>
          <AssemblyNavigator h="full" hasFocus={hasFocus} windowIcon={windowIcon} width={openWidth} />
        </CollapsibleSidebar>
      )}
    </ManagedWindow>
  );
};
