import { Button } from '@chakra-ui/react';

import { OrderStateEvent } from '../../common/lib/common-types/orders';
import { toTitleCase } from '../../common/lib/string-utils/string-utils';

interface EventActionButtonProps {
  event: OrderStateEvent;
  submitting?: boolean;
  onClick?: () => void;
}

const EventActionButton = ({ event, submitting, onClick }: EventActionButtonProps) => (
  <Button size="sm" variant="ghost" disabled={submitting} onClick={onClick}>
    {toTitleCase(event)}
  </Button>
);

export default EventActionButton;
