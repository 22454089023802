import { MenuGroup, MenuItem, Text } from '@chakra-ui/react';
import Confirm from '@web/components/Confirm.tsx';

import { useLayoutBuilder } from '../useLayoutBuilder.tsx';
import { MenuActions } from './actionsTypes.ts';
import { usePaneFlagNoteActions } from './flagNotes/usePaneFlagNoteActions.tsx';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for pane actions.
 */
export const usePaneActions = () => {
  const { showMenu, closeMenu, renderMenu, menuPosition } = useActionsBase();

  const { resetLayout } = useLayoutBuilder();
  const { flagNoteActions } = usePaneFlagNoteActions(closeMenu, menuPosition);

  const onReset = async () => {
    closeMenu();
    await resetLayout();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <Confirm
          title="Are you sure?"
          body=" This will reset the layout to its initial state. This action cannot be undone."
          onConfirm={onReset}
        >
          <MenuItem>
            <Text color="red">Reset layout</Text>
          </MenuItem>
        </Confirm>
      </MenuGroup>
      {flagNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
  } as MenuActions;
};
