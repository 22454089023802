import { Badge, Box, Flex, HStack, StackProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface Props extends StackProps {
  options: string[];
  selectedOptions: string[];
  isToggleable?: boolean;
  onFilter?: (selectedOptions: string[]) => void;
  optionTransform?: (option: string) => string;
}

const MultiSelectFilter = ({
  options,
  selectedOptions,
  isToggleable = true,
  onFilter,
  optionTransform,
  ...rest
}: Props) => {
  const [internalSelectedOptions, setInternalSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    // Update internalSelectedOptions when selectedOptions change
    setInternalSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  const handleToggleOption = (option: string) => {
    if (!isToggleable) {
      return;
    }

    const updatedSelectedOptions = internalSelectedOptions.includes(option)
      ? internalSelectedOptions.filter((item) => item !== option)
      : [...internalSelectedOptions, option];

    setInternalSelectedOptions(updatedSelectedOptions);
    if (onFilter) {
      onFilter(updatedSelectedOptions);
    }
  };

  return (
    <HStack spacing={2} wrap="wrap" {...rest} data-testid="multi-select-filter">
      {options.map((option) => (
        <Box
          key={option}
          data-selected={internalSelectedOptions.includes(option)}
          onClick={() => isToggleable && handleToggleOption(option)}
          cursor="pointer"
          role="checkbox"
          aria-checked={internalSelectedOptions.includes(option)}
          tabIndex={0}
        >
          <Badge
            color="gray.50"
            variant={internalSelectedOptions.includes(option) ? 'solid' : 'outline'}
            bg={internalSelectedOptions.includes(option) ? 'blue.700' : 'gray'}
          >
            <Flex alignItems="center">{optionTransform ? optionTransform(option) : option}</Flex>
          </Badge>
        </Box>
      ))}
    </HStack>
  );
};

export default MultiSelectFilter;
