import {
  DesignPart,
  isConnectionTarget,
  normalizeSourceTargetObjects,
  PartType,
  ResolvedConnection,
} from '@senrasystems/senra-ui';

import { Graph } from '../../../../../types/reactFlow.ts';
import { Bundle, isBreakoutPointNode, isControlPointNode, isSegmentEdge } from '../../types.ts';
import { updateBundleWithConnection } from '../../utils/bundles.ts';
import { findPath } from '../../utils/graph.ts';
import { createAndAddMeasurementEdge, createAndAddSegmentEdge } from '../EdgeFactory.ts';
import { createAndAddBreakoutPointNode, createAndAddDesignPartNode, findNodeById } from '../NodeFactory.ts';
import { Operations } from '../Operations.ts';
import PositionManager from '../PositionManager.ts';

// Operation to build a graph
export type BuildLayoutOperation = {
  type: 'BuildLayout';
  params: {
    connections: ResolvedConnection[];
    designParts: DesignPart[];
  };
};

/**
 * Builds the layout from the connections. This operation will work with a new graph or an existing graph and add any
 * missing nodes and edges based on the connections from the design.
 */
export class BuildLayout implements Operations<BuildLayoutOperation> {
  private positionManager = new PositionManager();

  // Execute the operation
  execute(graph: Graph, operation: BuildLayoutOperation): Graph {
    const { connections, designParts } = operation.params;

    // Add design parts to the graph
    this.addDesignParts(graph, designParts);

    // Process connections
    this.processConnections(graph, connections);

    return graph;
  }

  /**
   * Adds the design parts to the graph.
   * @param graph
   * @param designParts
   */
  private addDesignParts = (graph: Graph, designParts: DesignPart[]): void => {
    designParts.forEach((part) => {
      if (isConnectionTarget(part)) {
        const nextPosition = this.positionManager.getNextPosition(part.partData.type);
        createAndAddDesignPartNode(graph.nodes, part, nextPosition);
      }
    });
  };

  /**
   * Processes the connections and adds them to the graph. If a path exists between the source and target design parts,
   * the connection is added to all the segments in the path. If a path does not exist, a new segment is created between
   * the source and target design parts, and the connection is added to the segment.
   * @param graph
   * @param connections
   */
  private processConnections = (graph: Graph, connections: ResolvedConnection[]): void => {
    connections.forEach((conn) => {
      // Skip if the connection does not have a conductor
      if (!conn.conductor) return;

      // Get the source and target nodes
      const { source: sourceNode, target: targetNode } = this.getSourceAndTargetNodes(graph, conn);

      if (!sourceNode || !targetNode) {
        console.warn('Source or target node not found for connection:', conn);
        return;
      }

      // Normalize the source and target objects
      const { source, target } = normalizeSourceTargetObjects(sourceNode, targetNode, 'id');

      // Find the path between the source and target design parts
      const { pathExists, pathEdges } = findPath(
        graph.nodes,
        graph.edges,
        source.id,
        target.id,
        isControlPointNode,
        conn,
      );

      if (pathExists) {
        // If a path exists, add the connection to all the segments in the path
        pathEdges.forEach((edge) => {
          if (isSegmentEdge(edge)) {
            edge.data.bundles = updateBundleWithConnection(edge.data.bundles, conn.bundleId, conn.id);
          }
        });
      } else {
        // Create bundle
        const bundle: Bundle = {
          id: conn.bundleId,
          sourceDesignPartId: conn.source?.designPart.id || null,
          destinationDesignPartId: conn.destination?.designPart.id || null,
          connectionIds: [conn.id],
        };

        // Create a new segment between the source and target design parts, and add the connection to the segment
        createAndAddSegmentEdge(graph.edges, source.id, target.id, {
          bundles: [bundle],
          measurementSource: source.id,
          measurementTarget: target.id,
        });

        // Create a new measurement edge between the source and target design parts
        createAndAddMeasurementEdge(graph.edges, source.id, target.id);
      }
    });
  };

  /**
   * Gets the source and target nodes for the connection. If the connection has a source or target design part, the
   * corresponding node is found in the graph. If the connection does not have a source or target design part, a new
   * breakout point node is created as a placeholder.
   * @param graph
   * @param connection
   */
  private getSourceAndTargetNodes = (graph: Graph, connection: ResolvedConnection) => {
    const { conductor, source, destination, bundleId } = connection;

    if (!conductor) return { source: null, target: null };

    const getNode = (designPart?: { id: string }) => (designPart ? findNodeById(graph.nodes, designPart.id) : null);

    const sourceNode = getNode(source?.designPart);
    const targetNode = getNode(destination?.designPart);

    if (sourceNode && targetNode) return { source: sourceNode, target: targetNode };

    const terminalNode =
      this.findTerminatingNodeWithBundleId(graph, bundleId) ||
      createAndAddBreakoutPointNode(graph.nodes, this.positionManager.getNextPosition(PartType.CONNECTOR), {
        isTerminal: true,
      });

    return {
      source: sourceNode || terminalNode,
      target: targetNode || terminalNode,
    };
  };

  /**
   * Finds the terminating node with the bundle ID. This is used to find a breakout point node that is a terminal node
   * and has the bundle ID.
   * @param graph
   * @param bundleId
   */
  private findTerminatingNodeWithBundleId = (graph: Graph, bundleId: string) => {
    const terminalBreakoutPoints = graph.nodes.filter((node) => isBreakoutPointNode(node) && node.data.isTerminal);
    const terminalBreakoutPointIdsMap = new Map(terminalBreakoutPoints.map((point) => [point.id, point]));

    for (const edge of graph.edges) {
      if (isSegmentEdge(edge) && edge.data.bundles.some((bundle) => bundle.id === bundleId)) {
        const source = terminalBreakoutPointIdsMap.get(edge.source);
        const target = terminalBreakoutPointIdsMap.get(edge.target);

        if (source) return source;
        if (target) return target;
      }
    }
    return null;
  };
}
