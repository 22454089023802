import { QueryKey } from '@tanstack/query-core';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

enum DesignMessageType {
  InvalidateQueries = 'invalidate_queries',
  WindowClose = 'window_close',
}

type DesignMessage =
  | { type: DesignMessageType.InvalidateQueries; queryKey: QueryKey }
  | { type: DesignMessageType.WindowClose; url: string };
// | { add other message types here };

// Window object with the URL
type ManagedWindow = {
  window: Window;
  url: string;
};

// Context type for managing windows
export type WindowContextType = {
  openWindow: (url: string, windowName?: string, windowFeatures?: string) => Window | undefined;
  isWindowOpened: (url: string) => boolean;
  postInvalidateQueriesMessage: (queryKey: QueryKey) => void;
  postWindowCloseMessage: (url: string) => void;
};

// Create a context to manage opened windows
const WindowContext = createContext<WindowContextType | undefined>(undefined);

// Hook to use the window manager context
export const useWindowManager = () => {
  const context = useContext(WindowContext);
  if (context === undefined) {
    throw new Error('useWindowManager must be used within a WindowManagerProvider');
  }
  return context;
};

// Provider to manage opened windows
export const WindowManagerProvider = ({ children }: { children: React.ReactNode }) => {
  // Broadcast channel for sending messages
  const channel = useMemo(() => new BroadcastChannel('senra-design-channel'), []);

  // State to manage the opened windows
  const [managedWindows, setManagedWindows] = useState<ManagedWindow[]>([]);

  // Query client to invalidate queries
  const queryClient = useQueryClient();

  // Open a new window and manage it
  const openWindow = useCallback((url: string, windowName = '_blank', windowFeatures = 'width=600,height=400') => {
    const newWindow = window.open(url, windowName, windowFeatures);
    if (newWindow) {
      setManagedWindows((prev) => [...prev, { window: newWindow, url }]);
      // Check if the window is closed, and remove it from the list of managed windows. We're doing this because using
      // the 'unload' and 'beforeunload' event is not reliable
      const interval = setInterval(() => {
        if (newWindow.closed) {
          setManagedWindows((prev) => prev.filter((w) => w.window !== newWindow));
          clearInterval(interval);
        }
      }, 1000);
      return newWindow;
    }
    return;
  }, []);

  // Close a window and remove it from the managed windows
  const closeWindow = useCallback(
    (url: string) => {
      const windowToClose = managedWindows.find((w) => w.url === url);
      if (windowToClose) {
        windowToClose.window.close();
        setManagedWindows((prev) => prev.filter((w) => w !== windowToClose));
      }
    },
    [managedWindows],
  );

  useEffect(() => {
    // Event listener for receiving messages
    const handleMessage = (event: MessageEvent<DesignMessage>) => {
      const message = event.data;
      switch (message.type) {
        case DesignMessageType.InvalidateQueries:
          // TODO: This causes an infinite loop of network calls if the user has 2 tabs open, and modifies a
          //  measurement. When we re-enable pop-out windows we need to fix this.
          //void queryClient.invalidateQueries({ queryKey: message.queryKey });
          break;
        case DesignMessageType.WindowClose:
          closeWindow(message.url);
          break;
        default:
          console.log('Unknown message type:', event.data);
          break;
      }
    };

    // Adding the event listener
    channel.addEventListener('message', handleMessage);

    // Clean up the event listener on unmount
    return () => {
      channel.removeEventListener('message', handleMessage);
      // Don't close the channel to keep it consistent across components
    };
  }, [channel, closeWindow, queryClient]);

  // Check if the window is opened
  const isWindowOpened = useCallback(
    (url: string) => {
      return managedWindows.some((w) => w.url === url);
    },
    [managedWindows],
  );

  const postInvalidateQueriesMessage = useCallback(
    (queryKey: QueryKey) => {
      channel.postMessage({ type: DesignMessageType.InvalidateQueries, queryKey });
    },
    [channel],
  );

  const postWindowCloseMessage = useCallback(
    (url: string) => {
      channel.postMessage({ type: DesignMessageType.WindowClose, url });
    },
    [channel],
  );

  const value = { openWindow, isWindowOpened, closeWindow, postInvalidateQueriesMessage, postWindowCloseMessage };

  return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
};
