import { Box, BoxProps, Grid, HStack, Text } from '@chakra-ui/react';
import { getWireData, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { conductorSymbolOffset } from '@web/apps/Design/features/Schematic/types/positioning.ts';
import { centerAbsoluteVertical } from '@web/apps/styles.ts';
import { Position } from '@xyflow/system';

import { ColorBlock } from '../../../../../components/ColorBlock.tsx';
import { defaultSchematicConfig } from '../../../config.ts';
import { getConductorLeftHandle, getConductorRightHandle } from '../../../utils/handles.ts';
import { ConductorDetail } from './ConductorDetail.tsx';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  conductor: ResolvedConnectionPoint | null;
  signalName: string | null;
}

/**
 * Conductor component. This component is used to represent a conductor in the schematic. It has a connection handle on
 * the left and right side of the conductor.
 * @param conductor
 * @param signalName
 * @param rest
 * @constructor
 */
export const Conductor = ({ conductor, signalName, ...rest }: Props) => {
  if (!conductor) {
    return null;
  }

  const { designPart, displayName } = conductor;
  const wireData = getWireData(conductor);
  const partNumber = designPart.partData.partNumber;
  const isShield = conductor.name === 'Shield';

  return (
    <Box position="relative" h={defaultSchematicConfig.cavityHeight} w="full" {...rest}>
      {/* Line */}
      {!isShield && (
        <Box
          h={defaultSchematicConfig.conductorStrokeWidth}
          w="full"
          bg={defaultSchematicConfig.conductorColor}
          sx={{ ...centerAbsoluteVertical }}
        />
      )}

      {/* Conductor info, positioned on top of the line */}
      {!isShield && (
        <Box w="full" sx={{ ...centerAbsoluteVertical }}>
          <Grid templateColumns="1fr 2fr repeat(3, 1fr) 2fr 1fr" gap={2}>
            <ConductorDetail />
            <ConductorDetail>{partNumber}</ConductorDetail>
            <ConductorDetail color="gray.500">{wireData ? `${wireData.gauge} AWG` : null}</ConductorDetail>
            <ConductorDetail />
            <ConductorDetail color="gray.500">{signalName}</ConductorDetail>
            <ConductorDetail>
              <HStack bg={defaultSchematicConfig.style.backgroundColor} px={2}>
                <Box>
                  <ColorBlock text={displayName} />
                </Box>
                <Text color="gray.900">{displayName}</Text>
              </HStack>
            </ConductorDetail>
            <ConductorDetail />
          </Grid>
        </Box>
      )}

      {/* Connection handles */}
      <Box sx={isShield ? { position: 'absolute', top: '-10px', left: `${conductorSymbolOffset}px` } : {}}>
        <ConnectionHandle id={getConductorLeftHandle(conductor)} type="source" position={Position.Left} />
      </Box>
      <Box sx={isShield ? { position: 'absolute', top: '-10px', right: `${conductorSymbolOffset}px` } : {}}>
        <ConnectionHandle id={getConductorRightHandle(conductor)} type="source" position={Position.Right} />
      </Box>
    </Box>
  );
};
