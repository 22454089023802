import { Handle, HandleProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../../config.ts';

interface Props extends HandleProps {
  id: string;
}

/**
 * ConnectionHandle component. This component is used to represent where a conductor connects to. Override the default
 * handle style to make it blend in with the conductor line.
 * @param id
 * @param rest
 * @constructor
 */
export const ConnectionHandle = ({ id, ...rest }: Props) => {
  return (
    <Handle
      id={id}
      style={{
        height: '0px',
        minHeight: 0,
        width: '0px',
        minWidth: 0,
        backgroundColor: defaultSchematicConfig.conductorColor,
        border: 0,
      }}
      {...rest}
    />
  );
};
