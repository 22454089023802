import {
  BuildNote,
  Connection,
  Design,
  DesignPart,
  LayoutData,
  NoteType,
  PageData,
  Part,
  PartType,
  UUID,
} from '@senrasystems/senra-ui';
import { QueryKey } from '@tanstack/query-core';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { NON_UPDATABLE_FIELDS } from '@web/api/parts-api.ts';
import { BAD_REQUEST, downloadFile, getBaseOptions, getUrl } from '@web/common/api.ts';
import camelize from 'camelize';

import { useDesignToast } from '../hooks/useDesignToast.tsx';
import { useWindowManager } from '../hooks/useWindowManager.tsx';

class RequestError extends Error {
  constructor(
    message: string,
    public status: number,
  ) {
    super(message);
    this.name = 'RequestError';
  }
}

interface ErrorResponse {
  message?: string;
}

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export async function doRequest<T>(method: RequestMethod, url: string, body?: unknown): Promise<T | undefined> {
  const options = await getBaseOptions(method, body instanceof FormData ? null : 'application/json');
  if (body) {
    options.body = body as BodyInit;
  }

  const res = await fetch(url, options);

  let json: T | undefined;

  try {
    const text = await res.text();
    json = text ? camelize(JSON.parse(text)) : {};
  } catch (e) {
    if (e instanceof SyntaxError && res.status === 204) {
      // No content response (204) - empty response body is expected
    } else {
      throw new RequestError('Failed to parse response JSON', res.status);
    }
  }

  if (res.ok) {
    return json;
  } else {
    const errorJSON = json as ErrorResponse;
    if (res.status === 400) {
      const message = errorJSON.message || 'Validation Error';

      throw new RequestError(`${BAD_REQUEST} - ${message}`, res.status);
    }

    if ([403, 404].includes(res.status)) {
      window.location.assign('/404');
      throw new RequestError('Redirect to 404 page', res.status);
    }

    throw new RequestError(errorJSON.message || 'Unknown error', res.status);
  }
}

/**
 * Fetching (do not export, they are used by the hooks below)
 */

type EmptyResponse<T> = {
  data: T[];
  meta: PageData;
};

const createEmptyResponse = <T>(): EmptyResponse<T> => ({
  data: [],
  meta: { currentPage: 0, totalPages: 0, totalCount: 0 },
});

// Fetch all designs
interface DesignsResponse {
  data: Design[];
  meta: PageData;
}

const emptyDesignsResponse: DesignsResponse = createEmptyResponse<Design>();

const fetchDesigns = async (query?: string | null) => {
  const endpoint = getUrl(`/api/v1/designs`);
  const params = new URLSearchParams();
  query && params.set('q', query);

  return (await doRequest<DesignsResponse>('GET', `${endpoint}?${params.toString()}`)) || emptyDesignsResponse;
};

// Fetch a single design
const fetchDesign = async (designId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  return await doRequest<Design>('GET', url);
};

// Fetch parts by part number
interface PartsResponse {
  data: Part[];
  meta: PageData;
}

const emptyPartsResponse: PartsResponse = createEmptyResponse<Part>();

const fetchPartsByPartNumber = async (
  partNumber: string,
  partTypes?: PartType[],
  designToolSearch: boolean = false,
) => {
  const urlParams = new URLSearchParams();
  urlParams.append('part_number', partNumber);
  if (partTypes) {
    partTypes.forEach((type) => urlParams.append('type[]', type));
  }
  urlParams.append('design_tool_search', designToolSearch.toString());
  const url = getUrl(`/api/v1/parts?${urlParams.toString()}`);
  return (await doRequest<PartsResponse>('GET', url)) || emptyPartsResponse;
};

/**
 * Query Keys (do not export, they are used in the hooks below)
 *
 * We're using query key factories as recommended here:
 *   https://tkdodo.eu/blog/effective-react-query-keys
 */

// Query keys for designs
const designKeys = {
  all: ['designs'] as const,
  lists: () => [...designKeys.all, 'list'] as const,
  list: (filters: string[]) => [...designKeys.lists(), { filters }] as const,
  details: () => [...designKeys.all, 'detail'] as const,
  detail: (id: UUID) => [...designKeys.details(), id] as const,
};

// Query keys for parts
const partKeys = {
  all: ['parts'] as const,
  lists: () => [...partKeys.all, 'list'] as const,
  list: (filters: string[]) => [...partKeys.lists(), { filters }] as const,
  details: () => [...partKeys.all, 'detail'] as const,
  detail: (id: UUID) => [...partKeys.details(), id] as const,
};

/**
 * Query Hooks
 *
 * We're writing them with an optional select function to allow for data transformation as recommended here:
 *  https://tkdodo.eu/blog/react-query-data-transformations
 */

// Hook for fetching all designs
export const useDesignsQuery = <TData = DesignsResponse>(
  query?: string,
  select?: (data: DesignsResponse) => TData,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: query ? designKeys.list([query]) : designKeys.lists(),
    queryFn: () => fetchDesigns(query),
    select,
  });
};

// Hook for fetching a single design
export const useDesignQuery = <TData = Design>(
  designId: UUID,
  select?: (data: Design | undefined) => TData,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: designKeys.detail(designId),
    queryFn: () => fetchDesign(designId),
    select,
  });
};

// Hook for fetching parts by part number
export const usePartsByPartNumberQuery = <TData = PartsResponse>(
  partNumber: string,
  partTypes?: PartType[],
  designToolSearch?: boolean,
  select?: (data: PartsResponse) => TData,
): UseQueryResult<TData> => {
  const filters: string[] = [];
  if (partTypes) {
    partTypes.forEach((type) => filters.push(type));
  }
  filters.sort().push(partNumber);
  if (designToolSearch) {
    filters.sort().push('design_tool_search');
  }
  return useQuery({
    queryKey: partKeys.list(filters),
    queryFn: () => fetchPartsByPartNumber(partNumber, partTypes, designToolSearch),
    select,
    enabled: partNumber.length >= 0,
  });
};

/**
 * Updating (do not export, they are used by the hooks below)
 */

// Designs
interface CreateDesignData {
  name: string;
  partNumber: string;
  partRevision: string;
  description: string;
  lengthUnit: string;
  measurementMode: 'Face' | 'Rear';
}

interface UpdateDesignData extends CreateDesignData {
  layoutData: LayoutData;
  locked: boolean;
}

const createDesign = async (data: CreateDesignData) => {
  const url = getUrl(`/api/v1/designs`);
  return await doRequest<Design>('POST', url, JSON.stringify({ design: data }));
};

const updateDesign = async (designId: UUID, data: Partial<UpdateDesignData>) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  return await doRequest<Design>('PATCH', url, JSON.stringify({ design: data }));
};

const deleteDesign = async (designId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  await doRequest('DELETE', url);
};

const cloneDesign = async (designId: UUID, data: CreateDesignData) => {
  const url = getUrl(`/api/v1/designs/${designId}/clone`);
  return await doRequest<Design>('POST', url, JSON.stringify({ design: data }));
};

// Design Parts
const createDesignPart = async (
  designId: UUID,
  data: {
    name?: string;
    partId: UUID;
    designPartId?: UUID;
  },
) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts`);
  return await doRequest<DesignPart>('POST', url, JSON.stringify({ designPart: data }));
};

const renameDesignPart = async (designId: UUID, partId: UUID, data: { name: string }) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  return await doRequest<DesignPart>('PATCH', url, JSON.stringify({ designPart: data }));
};

const replaceDesignPart = async (designId: UUID, partId: UUID, newPartId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  return await doRequest<DesignPart>('PATCH', url, JSON.stringify({ designPart: { partId: newPartId } }));
};

const deleteDesignPart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  await doRequest('DELETE', url);
};

const updateDesignPart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}/update_part_data`);
  return await doRequest<DesignPart>('PUT', url);
};

const createAlternatePart = async (designId: UUID, partId: UUID, alternatePartId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/alternate_design_parts`);
  return await doRequest<DesignPart>(
    'POST',
    url,
    JSON.stringify({
      alternateDesignPart: {
        partId: partId,
        alternatePartId: alternatePartId,
      },
    }),
  );
};

const deleteAlternatePart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/alternate_design_parts/${partId}`);
  await doRequest('DELETE', url);
};

// Parts
const createPart = async (data: Part) => {
  const url = getUrl(`/api/v1/parts`);
  return await doRequest<Part>('POST', url, JSON.stringify({ part: data }));
};

const updatePart = async (partId: UUID, data: Part) => {
  const url = getUrl(`/api/v1/parts/${partId}`);
  const updatablePart = Object.fromEntries(Object.entries(data).filter(([key]) => !NON_UPDATABLE_FIELDS.includes(key)));
  return await doRequest<Part>('PUT', url, JSON.stringify({ part: updatablePart }));
};

const deletePart = async (partId: UUID) => {
  const url = getUrl(`/api/v1/parts/${partId}`);
  await doRequest('DELETE', url);
};

// Connections
const createConnection = async (designId: UUID, data: { sourceId: UUID; destinationId: UUID; conductorId: UUID }) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections`);
  return await doRequest<Connection>('POST', url, JSON.stringify({ connection: data }));
};

const updateConnection = async (
  designId: UUID,
  connectionId: UUID,
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
  },
) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections/${connectionId}`);
  return await doRequest<Connection>('PATCH', url, JSON.stringify({ connection: data }));
};

const deleteConnection = async (designId: UUID, connectionId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections/${connectionId}`);
  await doRequest('DELETE', url);
};

// Build Notes
const createBuildNote = async (designId: UUID, body: string, type: NoteType) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes`);
  return await doRequest<BuildNote>('POST', url, JSON.stringify({ buildNote: { body, type } }));
};

const updateBuildNote = async (designId: UUID, noteId: UUID, buildNote: Partial<BuildNote>) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes/${noteId}`);
  return await doRequest<BuildNote>('PUT', url, JSON.stringify({ buildNote }));
};

const deleteBuildNote = async (designId: UUID, noteId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes/${noteId}`);
  await doRequest('DELETE', url);
};

/**
 * Hooks for mutations
 */

// Helper hook for invalidating queries, and posting a message to other windows to invalidate their queries. Do not
// export, it is used by the mutation hooks below.
const useInvalidateQueries = () => {
  const queryClient = useQueryClient();
  const { postInvalidateQueriesMessage } = useWindowManager();
  return async (queryKey: QueryKey) => {
    await queryClient.invalidateQueries({ queryKey });
    postInvalidateQueriesMessage(queryKey);
  };
};

// Hook for creating a design
interface CreateDesignParams {
  data: CreateDesignData;
}

export const useCreateDesignMutation = (): UseMutationResult<Design | undefined, Error, CreateDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design'],
    mutationFn: async ({ data }: CreateDesignParams) => createDesign(data),
    onSuccess: async () => {
      await invalidateQueries(designKeys.lists());
    },
    onError: (createDesignError) => showErrorToast('Error Creating Design', createDesignError.message),
  });
};

// Hook for updating a design
interface UpdateDesignParams {
  designId: UUID;
  data: Partial<UpdateDesignData>;
}

export const useUpdateDesignMutation = (): UseMutationResult<Design | undefined, Error, UpdateDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-design'],
    mutationFn: async ({ designId, data }: UpdateDesignParams) => updateDesign(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.lists());
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (updateDesignError) => showErrorToast('Error Updating Design', updateDesignError.message),
  });
};

// Hook for deleting a design
interface DeleteDesignParams {
  designId: UUID;
}

export const useDeleteDesignMutation = (): UseMutationResult<void, Error, DeleteDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design'],
    mutationFn: async ({ designId }: DeleteDesignParams) => deleteDesign(designId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.lists());
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (deleteDesignError) => showErrorToast('Error Deleting Design', deleteDesignError.message),
  });
};

// Hook for cloning a design
interface CloneDesignParams {
  designId: UUID;
  data: CreateDesignData;
}

export const useCloneDesignMutation = (): UseMutationResult<Design | undefined, Error, CloneDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['clone-design'],
    mutationFn: async ({ designId, data }: CloneDesignParams) => cloneDesign(designId, data),
    onSuccess: async () => {
      await invalidateQueries(designKeys.lists());
    },
    onError: (cloneDesignError) => showErrorToast('Error Cloning Design', cloneDesignError.message),
  });
};

// Hook for creating a design part
export interface CreateDesignPartParams {
  designId: UUID;
  data: { name?: string; partId: UUID; designPartId?: UUID };
}

export const useCreateDesignPartMutation = (
  onSuccess?: () => void,
): UseMutationResult<DesignPart | undefined, Error, CreateDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design-part'],
    mutationFn: async ({ designId, data }: CreateDesignPartParams) => createDesignPart(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      onSuccess?.();
    },
    onError: (error) => showErrorToast('Error Adding Part', error.message),
  });
};

// Hook for renaming a design part
interface RenameDesignPartParams {
  designId: UUID;
  partId: UUID;
  data: { name: string };
}

export const useRenameDesignPartMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<DesignPart | undefined, Error, RenameDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['rename-design-part'],
    mutationFn: async ({ designId, partId, data }: RenameDesignPartParams) => renameDesignPart(designId, partId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      onSuccess?.();
    },
    onError: (error) => {
      showErrorToast('Error Renaming Part', error.message);
      onError?.();
    },
  });
};

// Hook for creating a build note
interface CreateBuildNoteParams {
  designId: UUID;
  body: string;
  type: NoteType;
}

export const useCreateBuildNoteMutation = (): UseMutationResult<
  BuildNote | undefined,
  Error,
  CreateBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-build-note'],
    mutationFn: async ({ designId, body, type }: CreateBuildNoteParams) => createBuildNote(designId, body, type),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Creating Build Note', error.message),
  });
};

// Hook for deleting a build note
interface UpdateBuildNoteParams {
  designId: UUID;
  noteId: UUID;
  buildNote: Partial<BuildNote>;
}

export const useUpdateBuildNoteMutation = (): UseMutationResult<
  BuildNote | undefined,
  Error,
  UpdateBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-build-note'],
    mutationFn: async ({ designId, noteId, buildNote }: UpdateBuildNoteParams) =>
      updateBuildNote(designId, noteId, buildNote),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Updating Build Note', error.message),
  });
};

// Hook for deleting a build note
interface DeleteBuildNoteParams {
  designId: UUID;
  noteId: UUID;
}

export const useDeleteBuildNoteMutation = (): UseMutationResult<void, Error, DeleteBuildNoteParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-build-note'],
    mutationFn: async ({ designId, noteId }: DeleteBuildNoteParams) => deleteBuildNote(designId, noteId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Deleting Build Note', error.message),
  });
};

// Hook for replacing a design part
interface ReplaceDesignPartParams {
  designId: UUID;
  partId: UUID;
  newPartId: UUID;
}

export const useReplaceDesignPartMutation = (
  onSuccess?: () => void,
): UseMutationResult<DesignPart | undefined, Error, ReplaceDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['replace-design-part'],
    mutationFn: async ({ designId, partId, newPartId }: ReplaceDesignPartParams) =>
      replaceDesignPart(designId, partId, newPartId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Part Replaced', 'The part was successfully replaced.');
      onSuccess?.();
    },
    onError: (error) => showErrorToast('Error Replacing Part', error.message),
  });
};

// Hook for deleting a design part
interface DeleteDesignPartParams {
  designId: UUID;
  partId: UUID;
}

export const useDeleteDesignPartMutation = (): UseMutationResult<void, Error, DeleteDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design-part'],
    mutationFn: async ({ designId, partId }: DeleteDesignPartParams) => deleteDesignPart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Removing Part', error.message),
  });
};

//Hook for updating a design part
interface UpdateDesignPartParams {
  designId: UUID;
  partId: UUID;
}

export const useUpdateDesignPartMutation = (): UseMutationResult<
  DesignPart | undefined,
  Error,
  UpdateDesignPartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-design-part'],
    mutationFn: async ({ designId, partId }: UpdateDesignPartParams) => updateDesignPart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Part updated successfully', 'The part has been updated in the design');
    },
    onError: (error) => showErrorToast('Error updating part', error.message),
  });
};

// Hook for creating an alternate part
interface CreateAlternatePartParams {
  designId: UUID;
  partId: UUID;
  alternatePartId: UUID;
}

export const useCreateAlternatePartMutation = (): UseMutationResult<
  DesignPart | undefined,
  Error,
  CreateAlternatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-alternate-part'],
    mutationFn: async ({ designId, partId, alternatePartId }: CreateAlternatePartParams) =>
      createAlternatePart(designId, partId, alternatePartId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Alternate Part Added', 'The part was successfully added as an alternate.');
    },
    onError: (error) => showErrorToast('Error Adding Alternate Part', error.message),
  });
};

// Hook for deleting a design part
interface DeleteAlternatePartParams {
  designId: UUID;
  partId: UUID;
}

export const useDeleteAlternatePartMutation = (): UseMutationResult<void, Error, DeleteAlternatePartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-alternate-part'],
    mutationFn: async ({ designId, partId }: DeleteAlternatePartParams) => deleteAlternatePart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Alternate Part Removed', 'The part was successfully removed as an alternate.');
    },
    onError: (error) => showErrorToast('Error Removing Alternate Part', error.message),
  });
};

// Hook for creating a part
interface CreatePartParams {
  data: Part & { manufacturerIds?: UUID[] };
}

export const useCreatePartMutation = ({ onError }: { onError?: () => void } = {}): UseMutationResult<
  Part | undefined,
  Error,
  CreatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-part'],
    mutationFn: async ({ data }: CreatePartParams) => createPart(data),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => {
      showErrorToast('Error Creating Part', error.message);
      onError?.();
    },
  });
};

// Hook for updating a part
interface UpdatePartParams {
  partId: UUID;
  data: Part;
}

export const useUpdatePartMutation = ({ onError }: { onError?: () => void } = {}): UseMutationResult<
  Part | undefined,
  Error,
  UpdatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-part'],
    mutationFn: async ({ partId, data }: UpdatePartParams) => updatePart(partId, data),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => {
      showErrorToast('Error Updating Part', error.message);
      onError?.();
    },
  });
};

// Hook for deleting a part
interface DeletePartParams {
  partId: UUID;
}

export const useDeletePartMutation = (): UseMutationResult<void, Error, DeletePartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-part'],
    mutationFn: async ({ partId }: DeletePartParams) => deletePart(partId),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => showErrorToast('Error Deleting Part', error.message),
  });
};

// Hook for creating a connection
export interface CreateConnectionParams {
  designId: UUID;
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
    signalName: string;
    sourceContactId: UUID;
    destinationContactId: UUID;
  };
}

export const useCreateConnectionMutation = (): UseMutationResult<
  Connection | undefined,
  Error,
  CreateConnectionParams
> => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationKey: ['create-connection'],
    mutationFn: async ({ designId, data }: CreateConnectionParams) => createConnection(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
  });
};

// Hook for updating a connection
export interface UpdateConnectionParams {
  designId: UUID;
  connectionId: UUID;
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
    signalName: string;
    sourceContactId: UUID;
    destinationContactId: UUID;
  };
}

export const useUpdateConnectionMutation = (): UseMutationResult<
  Connection | undefined,
  Error,
  UpdateConnectionParams
> => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationKey: ['update-connection'],
    mutationFn: async ({ designId, connectionId, data }: UpdateConnectionParams) =>
      updateConnection(designId, connectionId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
  });
};

// Hook for deleting a connection
export interface DeleteConnectionParams {
  designId: UUID;
  connectionId: UUID;
}

export const useDeleteConnectionMutation = (): UseMutationResult<void, Error, DeleteConnectionParams> => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationKey: ['delete-connection'],
    mutationFn: async ({ designId, connectionId }: DeleteConnectionParams) => deleteConnection(designId, connectionId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
  });
};

// Fetch cutlist
const fetchCutlist = async (designId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/cutlist`);
  const response = await fetch(url, {
    ...(await getBaseOptions('GET')),
  });

  if (!response.ok) {
    throw new Error('Failed to download cutlist');
  }

  return response.blob();
};

// Hook for downloading cutlist
export const useDownloadCutlist = (): UseMutationResult<void, Error, { designId: UUID }> => {
  const { showErrorToast, showSuccessToast } = useDesignToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['download-cutlist'],
    mutationFn: async ({ designId }) => {
      // Get the design data from the query cache
      const design = queryClient.getQueryData<Design>(designKeys.detail(designId));
      const filename = design ? `cutlist-${design.partNumber}_${design.partRevision}.xlsx` : `cutlist-${designId}.xlsx`;
      const blob = await fetchCutlist(designId);
      downloadFile(blob, filename);
    },
    onSuccess: () => {
      showSuccessToast('Download Complete', 'Cutlist file downloaded');
    },
    onError: (error) => {
      showErrorToast('Download Failed', error.message);
    },
  });
};
