import { useToken } from '@chakra-ui/react';
import { EdgeProps } from '@xyflow/react';

import { getShieldingPath } from '../../utils/getShieldingPath.ts';

/**
 * DoubleShieldingEdge component representing shielding between two conductors.
 * @constructor
 * @param props
 */
export const DoubleShieldingEdge = (props: EdgeProps) => {
  const strokeColor = useToken('colors', 'gray.600');

  const edgeStyle = {
    strokeDasharray: '10 3',
    strokeLinecap: 'round' as const,
    stroke: strokeColor,
    strokeWidth: 2,
    fill: 'none',
  };

  const [edgePath] = getShieldingPath(props, 4);

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
