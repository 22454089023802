import { BuildNote, DesignPart, ResolvedConnection } from '@senrasystems/senra-ui';
import { Edge, Node } from '@xyflow/react';

import { Graph } from '../../../../types/reactFlow.ts';
import { BuildLayout as BuildLayoutOperation } from '../graph/operations/BuildLayout';
import { UpdateBuildNotes as UpdateBuildNotesOperation } from '../graph/operations/UpdateBuildNotes';
import { ValidateLayout as ValidateLayoutOperation } from '../graph/operations/ValidateLayout';

interface UpdateGraphParams {
  nodes: Node[];
  edges: Edge[];
  connections: ResolvedConnection[];
  designParts: DesignPart[];
  flagNotes: BuildNote[];
}

/**
 * Updates the graph with the given nodes, edges, connections, and flag notes. This function will execute a series of
 * operations to update the graph.
 * @param nodes
 * @param edges
 * @param connections
 * @param designParts
 * @param flagNotes
 */
export const getUpdatedGraph = ({ nodes, edges, connections, designParts, flagNotes }: UpdateGraphParams): Graph => {
  const updateGraphOperations = [
    { name: 'ValidateLayout', operation: new ValidateLayoutOperation(), params: { connections, designParts } },
    {
      name: 'BuildLayout',
      operation: new BuildLayoutOperation(),
      params: { connections, designParts },
    },
    { name: 'UpdateBuildNotes', operation: new UpdateBuildNotesOperation(), params: { flagNotes } },
  ];

  const initialGraph = { nodes, edges };

  return updateGraphOperations.reduce((updatingGraph, { name, operation, params }) => {
    // @ts-expect-error params is not typed, but that's okay
    const graph = operation.execute(updatingGraph, { params: params });
    console.debug(`Graph after ${name}`, graph);
    return graph;
  }, initialGraph);
};
