import { Box, BoxProps } from '@chakra-ui/react';
import { createGradient, extractColors, milSpecColors } from '@web/common/colors.ts';

interface Props extends BoxProps {
  text?: string;
  height?: number;
  width?: number;
}

export const ColorBlock = ({ text = '', height = 3, width = 6, ...rest }: Props) => {
  const colors = extractColors(text);

  if (colors.length === 0) {
    return null;
  }

  return (
    <Box
      w={width}
      h={height}
      bgImage={createGradient(colors)}
      border={colors.includes(milSpecColors.WHITE) ? '1px solid' : `none`}
      borderColor="gray.500"
      borderRadius="3"
      {...rest}
    />
  );
};
