import { ResolvedConnectionPoint, UUID } from '@senrasystems/senra-ui';
import { Position } from '@xyflow/react';

export const getHandlePosition = (isStart: boolean): Position.Left | Position.Right => {
  return isStart ? Position.Right : Position.Left;
};

export const getHandleId = (
  connectionPoint: ResolvedConnectionPoint,
  conductor: ResolvedConnectionPoint,
  isStart: boolean,
  isHousing: boolean,
): string => {
  if (isStart) {
    return isHousing ? getStartPointHandle(connectionPoint, conductor.id) : getStartPointHandle(connectionPoint);
  }
  return isHousing ? getEndPointHandle(connectionPoint, conductor.id) : getEndPointHandle(connectionPoint);
};

export const getStartPointHandle = (connectionPoint: ResolvedConnectionPoint, conductorId?: UUID): string => {
  const designPartName = connectionPoint.designPart.name;
  const connectionPointName = connectionPoint.name;
  const handle = `startPoint:${designPartName}.${connectionPointName}`;
  return conductorId ? `${handle}.${conductorId}` : handle;
};

export const getEndPointHandle = (connectionPoint: ResolvedConnectionPoint, conductorId?: UUID): string => {
  const designPartName = connectionPoint.designPart.name;
  const connectionPointName = connectionPoint.name;
  const handle = `endPoint:${designPartName}.${connectionPointName}`;
  return conductorId ? `${handle}.${conductorId}` : handle;
};

export const getConductorLeftHandle = (connectionPoint: ResolvedConnectionPoint): string => {
  return `conductor-left:${connectionPoint.displayName}`;
};

export const getConductorRightHandle = (connectionPoint: ResolvedConnectionPoint): string => {
  return `conductor-right:${connectionPoint.displayName}`;
};
