import { Accordion, Box } from '@chakra-ui/react';
import { Edge, Node } from '@xyflow/react';
import { range } from 'lodash';

import { isSegmentEdge } from '../../../types.ts';
import { BundleSection } from './BundleSection';
import { OverwrapsSection } from './OverwrapsSection';

interface SegmentViewProps {
  node?: Node;
  edge?: Edge;
}

export const SegmentView = ({ edge }: SegmentViewProps) => {
  if (!edge || !isSegmentEdge(edge)) {
    return null;
  }

  const { bundles, overwraps } = edge.data;

  const allIndices = range((overwraps.length > 0 ? 1 : 0) + bundles.length);

  return (
    <Box w="full">
      <Accordion defaultIndex={allIndices} allowMultiple>
        <OverwrapsSection overwraps={overwraps} />
        {bundles.map((bundle) => (
          <BundleSection key={bundle.id} bundle={bundle} />
        ))}
      </Accordion>
    </Box>
  );
};
