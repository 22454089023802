import { Divider, Stack, StackProps } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import { AssemblyNavigatorPartDetails } from './components/AssemblyNavigatorPartDetails.tsx';
import { AssemblyNavigatorTitle } from './components/AssemblyNavigatorTitle.tsx';
import DrawingSectionTitle from './components/DrawingSectionTitle.tsx';
import OverwrapList from './components/OverwrapList.tsx';
import PartList from './components/PartList/PartList.tsx';
import { usePartSearchActions } from './hooks/usePartSearchActions.tsx';

interface Props extends StackProps {
  windowIcon: ReactNode;
  hasFocus: boolean;
}

export const AssemblyNavigator = ({ windowIcon, hasFocus, ...rest }: Props) => {
  const {
    isOpen: isPartSearchModalOpen,
    handleAddGeneralPart,
    handleAddSpecificPart,
    handleReplacePart,
  } = usePartSearchActions();
  const [expandAll, setExpandAll] = useState(true);

  return (
    <Stack spacing={0} borderColor="gray.300" divider={<Divider />} {...rest}>
      <AssemblyNavigatorPartDetails windowIcon={windowIcon} />
      <AssemblyNavigatorTitle
        onAddPart={() => {
          setExpandAll(true);
          handleAddGeneralPart();
        }}
      />
      <PartList
        flex={3}
        onAddPart={handleAddSpecificPart}
        onReplacePart={handleReplacePart}
        expandAllItems={expandAll}
        hasFocus={hasFocus && !isPartSearchModalOpen}
      />
      <DrawingSectionTitle />
      <OverwrapList flex={1} />
    </Stack>
  );
};
