import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { downloadFile } from '@web/common/api.ts';
import { LineItem, Order, S3Download } from '@web/common/lib';
import { pluralize } from '@web/common/lib/string-utils/string-utils.ts';
import { errorToast } from '@web/common/toasts.ts';
import { fetchDocument, getDocumentObjectURL } from '@web/common/util.ts';
import { useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';

import FilterBox from '../../../components/FilterBox';
import Title from '../../../components/Title';

const canPreview = (filename: string) => {
  const validExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp'];
  return validExtensions.some((extension) => filename.trimEnd().toLowerCase().endsWith(`.${extension}`));
};

const handlePreviewDocument = (document: S3Download) =>
  getDocumentObjectURL(document)
    .then((data) => window.open(data, '_blank'))
    .catch((err) => errorToast(`Error downloading file: ${err.message}`));

const handleDownloadDocument = (document: S3Download) =>
  fetchDocument(document)
    .then((data) => downloadFile(data, document.filename))
    .catch((err) => errorToast(`Error downloading file: ${err.message}`));

const DocumentsDisplay = ({ line, lineNumber }: { line: LineItem; lineNumber: number }) => {
  const [filter, setFilter] = useState('');
  line.documents.sort((a, b) => a.filename.localeCompare(b.filename));
  const documents = line.documents.filter((d) => d.filename.includes(filter));
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <HStack gap={4} fontSize={20}>
            <Badge>Line {lineNumber}</Badge>
            <Text fontWeight="bold">{line.partNumber}</Text>
            {!!line.partRevision && <Text opacity={0.7}>REV {line.partRevision}</Text>}
            <Text opacity={0.3}>
              ({documents.length} document{pluralize(documents.length)})
            </Text>
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Stack>
          <HStack>
            <FilterBox value={filter} onChange={(v) => setFilter(v)} shouldFocus={false} />
            <Text size="sm" opacity={0.3} fontStyle="italic">
              {documents.length} matching document{pluralize(documents.length)}.
            </Text>
          </HStack>
          {documents.map((document) => (
            <HStack key={document.id} gap={4}>
              <Button
                isDisabled={!canPreview(document.filename)}
                leftIcon={<MdOpenInNew />}
                onClick={() => handlePreviewDocument(document)}
              >
                Preview
              </Button>
              <Button leftIcon={<BiDownload />} onClick={() => handleDownloadDocument(document)}>
                Download
              </Button>
              <Text>{document.filename}</Text>
            </HStack>
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

const LineItemsDisplay = ({ lineItems }: { lineItems: LineItem[] }) => (
  <Accordion allowMultiple>
    {lineItems.map((line, index) => (
      <DocumentsDisplay key={line.id} line={line} lineNumber={index + 1} />
    ))}
  </Accordion>
);

const OrderDocumentsDownload = ({ order }: { order: Order }) => (
  <>
    <Title title="Documents by Part Number" />
    <LineItemsDisplay lineItems={order.lineItems} />
  </>
);

export default OrderDocumentsDownload;
