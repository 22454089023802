import { HStack, Text, VStack } from '@chakra-ui/react';
import { FcCableRelease } from 'react-icons/fc';

import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { Bundle } from '../../../types.ts';
import { BaseAccordionItem } from './BaseAccordionItem.tsx';
import { ConnectionRow } from './ConnectionRow';

interface BundleSectionProps {
  bundle: Bundle;
}

export const BundleSection = ({ bundle }: BundleSectionProps) => {
  const { getDesignPartById } = useDesignParts();

  const bundleSource = bundle.sourceDesignPartId ? getDesignPartById(bundle.sourceDesignPartId) : null;
  const bundleDestination = getDesignPartById(bundle.destinationDesignPartId);

  const title = (
    <HStack>
      <Text>{bundleSource?.name}</Text>
      <Text color="gray.500">{bundleSource?.partData.partNumber}</Text>
      <Text>&rarr;</Text>
      <Text>{bundleDestination?.name}</Text>
      <Text color="gray.500">{bundleDestination?.partData.partNumber}</Text>
    </HStack>
  );

  return (
    <BaseAccordionItem title={title} icon={FcCableRelease} borderColor="blue.200" bgColor="blue.50">
      <VStack align="stretch">
        {bundle.connectionIds.map((connectionId) => (
          <ConnectionRow key={connectionId} connectionId={connectionId} />
        ))}
      </VStack>
    </BaseAccordionItem>
  );
};
