import { Manufacturer, Part } from '@senrasystems/senra-ui';
import { apiGetManufacturers } from '@web/api/manufacturers-api.ts';
import CreatableTypeAheadSelectInput from '@web/components/form/CreatableTypeAheadSelectInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { useCreateManufacturer, useManufacturers } from '@web/queries/manufacturers.ts';
import { MultiValue } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

const ManufacturerDropdownMultiValue = ({ editing }: Props) => {
  const { control } = useFormContext<Part>();
  const { field } = useController({
    name: 'manufacturers',
    control,
    defaultValue: [],
  });
  const createManufacturer = useCreateManufacturer();
  const { data: manufacturers = [] } = useManufacturers();

  const toOption = (manufacturer: Manufacturer) => ({ label: manufacturer.name, value: manufacturer.id });

  const defaultOptions = manufacturers.map(toOption);

  const selectedOptions = Array.isArray(field.value) ? field.value.map(toOption) : [];

  const handleSearchManufacturers = async (inputValue: string) => {
    const res = await apiGetManufacturers(inputValue);
    if (res) {
      return res.data.map(toOption);
    }
    return [];
  };

  const handleCreateManufacturer = async (
    manufacturerName: string,
  ): Promise<MultiValue<{ label: string; value: string }>> => {
    const res = await createManufacturer.mutateAsync({ name: manufacturerName });
    if (res) {
      const newManufacturer = { id: res.id, name: res.name };
      const currentValue = Array.isArray(field.value) ? field.value : [];
      field.onChange([...currentValue, newManufacturer]);
      return [{ label: res.name, value: res.id }];
    }
    return [];
  };

  const handleOnChange = (newOptions: MultiValue<{ label: string; value: string } | undefined>) => {
    const optionsArray = newOptions || [];
    const manufacturers = optionsArray
      .filter((option): option is { label: string; value: string } => option !== undefined)
      .map((option) => ({
        id: option.value,
        name: option.label,
      }));

    const currentValue = Array.isArray(field.value) ? field.value : [];
    const newManufacturer = manufacturers.find((m) => !currentValue.some((cm) => cm.id === m.id));
    if (newManufacturer) {
      field.onChange([...currentValue, newManufacturer]);
    } else {
      field.onChange(manufacturers);
    }
  };

  return (
    <EditableFormText editing={editing} label="Manufacturers">
      <CreatableTypeAheadSelectInput
        label="Manufacturers"
        editing={editing}
        value={selectedOptions}
        defaultOptions={defaultOptions}
        onChange={handleOnChange}
        onCreateOption={handleCreateManufacturer}
        loadOptions={handleSearchManufacturers}
        isMulti
      />
    </EditableFormText>
  );
};

export default ManufacturerDropdownMultiValue;
