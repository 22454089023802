import { Box, HStack, Stack } from '@chakra-ui/react';
import { FieldValues, useForm } from 'react-hook-form';

import { apiCreateOrderComment } from '../../api/order-comments-api';
import { OrderCommentDto } from '../../common/lib';
import CancelButton from '../buttons/CancelButton';
import SubmitButton from '../buttons/SubmitButton';
import TextareaWithAttachment from '../form/TextareaWithAttachment';

export function OrderCommentReply({
  orderId,
  data,
  setIsReplying,
  reloadComments,
}: {
  orderId: string;
  data: OrderCommentDto;
  setIsReplying: (v: boolean) => void;
  reloadComments: () => void;
}) {
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({ defaultValues: { body: '', attachments: [] } });

  async function onSubmit(formData: FieldValues) {
    const comment = await apiCreateOrderComment(orderId, {
      body: formData.body,
      attachments: formData.attachments,
      commentId: data.id,
      internal: data.internal, // maintain same internal status as parent comment
    });
    if (comment) {
      setIsReplying(false);
      reloadComments();
    }
  }

  function onCancel() {
    setIsReplying(false);
  }

  return (
    <Box pb={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextareaWithAttachment<{
            body: string;
            attachments: never[];
          }>
            required
            control={control}
            register={register}
            textareaName="body"
            textareaPlaceholder="Comment…"
          />
          <HStack>
            <SubmitButton text="Comment" size="sm" disabled={!isValid} loading={isSubmitting} />
            <CancelButton size="sm" onClick={onCancel} />
          </HStack>
        </Stack>
      </form>
    </Box>
  );
}
