import { FormControl, FormControlProps, FormLabel, Textarea, TextareaProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import ErrorText from './ErrorText';

interface Props extends TextareaProps {
  label?: string;
  error?: string | null;
  autoFocus?: boolean;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  formControlProps?: FormControlProps;
}

const TextAreaInput = forwardRef((props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
  const { label, error, placeholder, maxLength, rows, autoFocus = false, formControlProps, ...rest } = props;
  return (
    <FormControl {...formControlProps}>
      {label && <FormLabel>{label}</FormLabel>}

      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <Textarea autoFocus={autoFocus} placeholder={placeholder} maxLength={maxLength} rows={rows} ref={ref} {...rest} />
      {error && <ErrorText>{error}</ErrorText>}
    </FormControl>
  );
});

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
