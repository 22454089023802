import { PigtailNodeType } from '../../components/nodes/PigtailNode/PigtailNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { usePartFlagNoteActions } from './flagNotes/usePartFlagNoteActions.tsx';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for pigtail actions.
 */
export const usePigtailActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as PigtailNodeType;

  const { flagNoteActions } = usePartFlagNoteActions(closeMenu, node);

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Pigtail,
  } as MenuActions;
};
