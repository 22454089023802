import { ButtonProps } from '@chakra-ui/react';
import { isOverwrap, Part } from '@senrasystems/senra-ui';
import { MouseEventHandler } from 'react';

import AddIconButton from '../../../components/AddIconButton.tsx';

interface Props extends ButtonProps {
  part: Part;
  onPartAdded?: () => void;
}

/**
 * AddOverwrapButton component displays a button to add an overwrap.
 * @param part
 * @param onPartAdded
 * @param rest
 * @constructor
 */
const AddOverwrapButton = ({ part, onPartAdded, ...rest }: Props) => {
  if (!isOverwrap(part)) {
    return null;
  }

  const handleAddOverwrap: MouseEventHandler<HTMLButtonElement> = () => {
    if (onPartAdded) {
      onPartAdded();
    }
  };

  return (
    <AddIconButton
      aria-label="Add overwrap"
      onClick={handleAddOverwrap}
      bg="gray.300"
      _hover={{ color: 'white', bg: 'blue.500' }}
      {...rest}
    />
  );
};

export default AddOverwrapButton;
