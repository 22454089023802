import {
  HStack,
  IconButton,
  Link as ChakraLink,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { apiAdminUpdateOrder } from '@web/api/admin-api.ts';
import { DashboardOrder } from '@web/common/lib';
import { toTitleCase } from '@web/common/lib/string-utils/string-utils.ts';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useEmployeesUsers } from '@web/queries/admin.ts';
import { SingleValue } from 'chakra-react-select';
import { formatDistanceToNow } from 'date-fns';
import React, { SetStateAction, useRef, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';

import TypeAheadSelectInput from '../../../components/form/TypeAheadSelectInput';
import OrderStateBadge from '../../../components/OrderStateBadge';
import { Column } from '../../../components/table/Table';

interface Props {
  orders: DashboardOrder[];
  employee: boolean;
  reloadOrders: () => Promise<void>;
  handleFilterState: (state: string) => void;
  handleFilterQuery: (query: string) => void;
}

const FilterButton = ({ tooltip, onClick }: { tooltip: string; onClick: () => void }) => (
  <Tooltip label={tooltip} aria-label={tooltip}>
    <IconButton size="xs" icon={<BiFilter />} aria-label="Filter" onClick={onClick} />
  </Tooltip>
);

const OrdersList = ({ orders, reloadOrders, employee, handleFilterState, handleFilterQuery }: Props) => {
  const [query, setQuery] = useState('');
  const { isLoading, data: owners = [] } = useEmployeesUsers(query);
  const options = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : owners.map((owner) => ({ value: owner.id, label: owner.name }));

  const hidden = !employee;
  const columns: Column[] = [
    {
      title: 'Order status',
      key: 'status',
    },
    {
      title: 'Order number',
      key: 'orderNumber',
    },
    {
      title: 'Need by',
      key: 'needBy',
    },
    {
      title: 'Sales win probability',
      key: 'salesWinProbability',
      hidden,
    },
    {
      title: 'Order amount', // # dollars
      key: 'orderAmount',
      hidden,
    },
    {
      title: 'Order name',
      key: 'orderName',
    },
    {
      title: 'Material Acquisition',
      key: 'materialAcquisition',
      hidden,
    },
    {
      title: 'Quote',
      key: 'estimateNumber',
    },
    {
      title: 'Purchase Order',
      key: 'poNumber',
    },
    {
      title: 'Customer',
      key: 'tenantName',
      hidden,
    },
    {
      title: 'Placed by',
      key: 'placedBy',
    },
    {
      title: 'Senra Owner',
      key: 'owner',
      hidden,
    },
    {
      title: 'Order placed', // # hours ago
      key: 'orderPlaced',
    },
  ];

  const data = orders.map((order) => {
    const { tenant } = order;

    return {
      id: order.id,
      values: [
        {
          value: (
            <HStack>
              <OrderStateBadge orderState={order.state} />
              <FilterButton
                tooltip={`Filter to ${toTitleCase(order.state)}`}
                onClick={() => handleFilterState(order.state)}
              />
            </HStack>
          ),
        },
        {
          value: (
            <ChakraLink
              as={Link}
              color="primary"
              fontWeight={500}
              title={order.orderNumber}
              to={RouteNames.ORDERS.SHOW.replace(':orderId', order.id)}
            >
              {order.orderNumber}
            </ChakraLink>
          ),
        },
        { value: order.neededBy },
        { value: `${(order.salesWinProbability || 0) * 100}%` },
        {
          value: order.purchaseOrder?.purchaseOrderAmount ? `$${order.purchaseOrder?.purchaseOrderAmount}` : '-',
        },
        {
          value: (
            <ChakraLink
              as={Link}
              color="primary"
              fontWeight={500}
              title={order.name}
              to={RouteNames.ORDERS.SHOW.replace(':orderId', order.id)}
            >
              {order.name}
            </ChakraLink>
          ),
        },
        { value: toTitleCase(order.materialAcquisition) },
        { value: order.quote?.estimateNumber ?? '-' },
        { value: order.purchaseOrder?.purchaseOrderNumber ?? '-' },
        {
          value: (
            <HStack>
              <Text>{tenant.name}</Text>
              <FilterButton tooltip={`Filter to ${tenant.name}`} onClick={() => handleFilterQuery(tenant.name)} />
            </HStack>
          ),
        },
        {
          value: order.user.name,
        },
        {
          value: <ChangeOwner order={order} owners={options} reloadOrders={reloadOrders} querySetter={setQuery} />,
        },
        { value: `${formatDistanceToNow(new Date(order.createdAt))} ago` },
      ],
    };
  });

  // Filter out hidden columns and data rows
  const visibleColumns = columns.filter((col) => !col.hidden);
  const visibleData = data.map((row) => ({
    id: row.id,
    values: row.values.filter((_cell, i) => !columns[i].hidden),
  }));

  return (
    <Table variant="senraTable">
      <Thead>
        <Tr>
          {visibleColumns.map((col) => (
            <Th key={col.key}>{col.title}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {visibleData.map((row) => (
          <Tr key={row.id}>
            {row.values.map((cell, i) => (
              <Td key={`${row.id}-${i}`}>{cell.value}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const ChangeOwner = ({
  order,
  owners,
  reloadOrders,
  querySetter,
}: {
  order: DashboardOrder;
  owners: { value: string; label: string }[];
  reloadOrders: () => Promise<void>;
  querySetter: React.Dispatch<SetStateAction<string>>;
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<SingleValue<{ label: string; value: string }>>(
    order.owner ? { label: order.owner.name, value: order.owner.id } : { label: '', value: '' },
  );
  const ref = useRef(null);
  useOnClickOutside(ref, () => setEditing(false));

  const onChange = async (selectedOption?: SingleValue<{ label: string; value: string }>) => {
    const ownerId = selectedOption?.value ?? '';
    setValue(selectedOption ?? { label: '', value: '' });
    await apiAdminUpdateOrder(order.id, { ownerId: ownerId });
    await reloadOrders();
    const assignerName = owners.find((o) => o.value === ownerId)?.label;
    successToast(assignerName ? `Order assigned to ${assignerName}` : 'Order unassigned');
    setEditing(false);
  };

  if (editing) {
    return <TypeAheadSelectInput options={owners} onChange={onChange} querySetter={querySetter} value={value} />;
  }
  return (
    <Text
      color="primary"
      cursor="pointer"
      fontWeight={500}
      _hover={{ textDecoration: 'underline' }}
      onClick={() => setEditing(!editing)}
    >
      {order.owner?.name ?? 'Unassigned'}
    </Text>
  );
};

export default OrdersList;
