import { Box, Button, FormLabel, Stack } from '@chakra-ui/react';
import { DocumentCategory, Tool } from '@senrasystems/senra-ui';
import DocumentDisplay from '@web/components/DocumentDisplay';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface Props {
  editing?: boolean;
}

const ToolSpecReferences = ({ editing = true }: Props) => {
  const { control } = useFormContext<Tool>();
  const {
    fields: documents,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'documents',
  });

  const handleAddDocument = () => {
    append({ id: '', type: DocumentCategory.Datasheet, url: '', filename: '', s3Key: '' });
  };

  return (
    <Box>
      <FormLabel id="referencesLabel" color={editing ? undefined : 'gray.500'}>
        References{editing ? '' : ':'}
      </FormLabel>
      <Stack spacing={1}>
        {documents.map((document, index) => {
          return (
            <DocumentDisplay
              key={document.id}
              editing={editing}
              remove={remove}
              index={index}
              aria-labelledby="referencesLabel"
            />
          );
        })}
      </Stack>
      {editing && (
        <Button mt={2} onClick={handleAddDocument}>
          Add Document
        </Button>
      )}
    </Box>
  );
};

export default ToolSpecReferences;
