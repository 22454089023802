import { DownloadIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

interface DownloadButtonProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onClick: () => void;
  text?: string;
}

const DownloadButton = ({ size = 'sm', onClick, text = 'Download' }: DownloadButtonProps) => (
  <Button colorScheme="green" variant="outline" size={size} onClick={onClick} py={1} px={2}>
    {text} <DownloadIcon ml={2} />
  </Button>
);

export default DownloadButton;
