import { Box } from '@chakra-ui/react';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';

import { isBreakoutPointNode } from '../../../types.ts';
import { HandleTypes } from '../../../types/handles.ts';
import { getNodeColor } from '../../../utils/common.ts';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { BreakoutPointShape } from './BreakoutPointShape.tsx';

export type BreakoutPointNodeData = {
  isTerminal: boolean;
};

export const defaultBreakoutPointNodeData: BreakoutPointNodeData = {
  isTerminal: false,
};

export type BreakoutPointNodeType = Node<BreakoutPointNodeData>;

/**
 * Breakout point node component.
 * @param props
 * @constructor
 */
export const BreakoutPointNode = (props: NodeProps<BreakoutPointNodeType>) => {
  const { selected } = props;
  const { getIntersectingNodes } = useReactFlow();

  // Check if two breakout points are intersecting
  const intersections = getIntersectingNodes(props, true).filter(isBreakoutPointNode);

  // If they are on top of each other, color them red
  const color = intersections.length > 0 ? 'red' : getNodeColor(selected);

  return (
    <Box position="relative">
      <BreakoutPointShape color={color} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
