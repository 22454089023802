import * as EmailValidator from 'email-validator';
import React, { useState } from 'react';
import { FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form';

import TextInput from './TextInput';

interface Props<FormRegisterType extends FieldValues> {
  label: string;
  registerFieldName: Path<FormRegisterType>;
  register: UseFormRegister<FormRegisterType>;
}

const EmailInput = <FormRegisterType extends FieldValues>(props: Props<FormRegisterType>) => {
  const { label, register, registerFieldName } = props;

  const [error, setError] = useState<string>('');

  const validateEmail = (value: PathValue<FormRegisterType, Path<FormRegisterType>>) => {
    return EmailValidator.validate(value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!EmailValidator.validate(e.target.value)) {
      setError('Invalid email');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
  };

  return (
    <TextInput
      label={label}
      error={error}
      type="email"
      {...register(registerFieldName, { validate: validateEmail, onBlur: handleBlur, onChange: handleChange })}
    />
  );
};

export default EmailInput;
