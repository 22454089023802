import { Text } from '@chakra-ui/react';
import { InsertionRemovalTool, Tool, ToolType } from '@senrasystems/senra-ui';

import ToolsLibraryDashboard, { CustomColumnDef } from '../../components/ToolsLibraryDashboard.tsx';
import InsertionRemovalFields from './InsertionRemovalFields.tsx';

const columns: CustomColumnDef[] = [
  {
    header: 'Usage',
    renderFn: (tool: Tool) => {
      const insertionRemovalTool = tool as InsertionRemovalTool;
      return <Text>{insertionRemovalTool.subtype}</Text>;
    },
  },
];

const InsertionRemovalsDashboard = () => {
  return (
    <ToolsLibraryDashboard
      toolType={ToolType.INSERTIONREMOVAL}
      columnDefs={columns}
      defaultValues={{ type: ToolType.INSERTIONREMOVAL }}
      additionalFieldsComponent={InsertionRemovalFields}
      buttonText="Insertion + Removal"
      title="Insertions + Removals"
      routeName="insertions-removals"
    />
  );
};

export default InsertionRemovalsDashboard;
