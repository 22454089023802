import { NoteType } from '@senrasystems/senra-ui';
import { IoTriangle } from 'react-icons/io5';

const NoteTypeToIconMap = {
  [NoteType.FLAG]: <IoTriangle size={10} />,
} as const;

interface Props {
  type: NoteType;
}

const NoteTableTypeDisplay = ({ type }: Props) => {
  return NoteTypeToIconMap[type as keyof typeof NoteTypeToIconMap] || null;
};

export default NoteTableTypeDisplay;
