import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ReactNode } from 'react';

import ContextPanel from './ContextPanel';

interface TabData {
  value: string;
  headerLabel: string;
  content: ReactNode;
}

interface Props {
  tabs: TabData[];
  isOpen: boolean;
  togglePanel: () => void;
  openPanel: () => void;
}

const ContextPanelWithTabs = ({ tabs, isOpen, togglePanel, openPanel }: Props) => (
  <Tabs variant="unstyled">
    <ContextPanel
      isOpen={isOpen}
      onTogglePanel={togglePanel}
      headerContent={
        <TabList gap={6}>
          {tabs.map(({ value, headerLabel }) => (
            <Tab
              key={value}
              onClick={openPanel}
              fontWeight="600"
              color="gray.400"
              _selected={{ color: tabs.length === 1 ? 'gray.500' : 'gray.900' }}
            >
              {headerLabel}
            </Tab>
          ))}
        </TabList>
      }
    >
      <TabPanels>
        {tabs.map(({ value, content }) => (
          <TabPanel p={0} key={value}>
            {content}
          </TabPanel>
        ))}
      </TabPanels>
    </ContextPanel>
  </Tabs>
);

export default ContextPanelWithTabs;
