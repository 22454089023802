import { doesUserHaveRole, Role } from '@senrasystems/senra-ui';
import { useQueryClient } from '@tanstack/react-query';
import { RouteNames } from '@web/consts/routeNames.ts';
import { E164Number } from 'libphonenumber-js/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { apiPutUser } from '../../../api/admin-api';
import { successToast } from '../../../common/toasts';
import { adminUserCacheKey, useAdminUser } from '../../../queries/admin';
import AdminPage from '../AdminPage';
import UserForm from './UserForm';

const AdminEditUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [phone, setPhone] = useState<E164Number>('');
  const { userId = '' } = useParams();

  const { data: user } = useAdminUser(userId);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm();

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        tenantId: user.tenant.id,
        admin: doesUserHaveRole(user, Role.ADMIN),
        manufacturingEngineer: doesUserHaveRole(user, Role.MANUFACTURING_ENGINEER),
        technician: doesUserHaveRole(user, Role.TECHNICIAN),
        authorizedOperator: doesUserHaveRole(user, Role.AUTHORIZED_OPERATOR),
      });
      setPhone(user.phone ?? '');
    }
  }, [user, reset]);

  const onSubmit = async (data: object) => {
    const roles = [];

    if (data.admin) roles.push(Role.ADMIN);
    if (data.manufacturingEngineer) roles.push(Role.MANUFACTURING_ENGINEER);
    if (data.technician) roles.push(Role.TECHNICIAN);
    if (data.authorizedOperator) roles.push(Role.AUTHORIZED_OPERATOR);

    if (
      data.admin &&
      !window.confirm(
        'WARNING! Are you sure that you want to set this user to admin? It grants them access to ALL customer data in the system.',
      )
    ) {
      return;
    }
    const results = await apiPutUser(userId, { ...data, phone, roles });
    if (results) {
      navigate(RouteNames.USERS.INDEX);
      successToast('User successfully updated.');
      await queryClient.invalidateQueries({ queryKey: adminUserCacheKey(userId) });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <AdminPage title="Edit User">
      <UserForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        selectedTenant={{ label: user.tenant.name, value: user.tenant.id }}
        isSubmitting={isSubmitting}
        isValid={isValid}
        phone={phone}
        setPhone={setPhone}
      />
    </AdminPage>
  );
};

export default AdminEditUser;
