import { Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import { Node, NodeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../../config.ts';
import { SchematicData } from '../../../hooks/useSchematicData.tsx';
import { Cavity } from './Cavity.tsx';
import { Conductor } from './Conductor.tsx';
import { ConnectorLabel } from './ConnectorLabel.tsx';

export type SchematicNodeData = {
  schematicData: SchematicData;
  width: number;
};

export type SchematicNodeType = Node<SchematicNodeData>;

/**
 * Schematic node component. This component has 3 columns: source cavities, conductors, and destination cavities. Each
 * column has a vertical stack of cavities or conductors. Each row is the same height so that the cavities and conductors
 * line up. The cavities on the left, can only appear once. The cavities on the right can only appear once per group.
 * @param props
 * @constructor
 */
export const SchematicNode = (props: NodeProps<SchematicNodeType>) => {
  const { schematicData, width } = props.data;

  return (
    <VStack w={width} spacing={0} p={8}>
      {Object.entries(schematicData).map(([designPartId, { connectionGroup }], index) => {
        const isLoopback = connectionGroup.startPoint?.designPart.id === connectionGroup.endPoint?.designPart.id;

        return (
          <VStack key={designPartId} w="full" spacing={0}>
            {/* For each group of connections, label connectors, as needed */}
            <HStack w="full" align="stretch">
              {index === 0 ? (
                <ConnectorLabel designPart={connectionGroup.startPoint?.designPart} isSource={true} />
              ) : (
                <Box bg={defaultSchematicConfig.connectorColor} display="flex" alignItems="center" px={4} py={2}>
                  <Cavity connectionPoint={null} conductor={null} visibility="hidden" />
                </Box>
              )}
              <Spacer />
              {!isLoopback && <ConnectorLabel designPart={connectionGroup.endPoint?.designPart} isSource={false} />}
            </HStack>
            <HStack w="full" align="stretch">
              {/* Left vertical container for source cavities */}
              <VStack
                bg={defaultSchematicConfig.connectorColor}
                spacing={0}
                borderTopRadius={index === 0 ? 'md' : 'none'}
                borderBottomRadius={index === Object.keys(schematicData).length - 1 ? 'md' : 'none'}
                py={2}
              >
                {connectionGroup.rows.map((row, index) => (
                  <Box key={index} display="flex" alignItems="center" px={4} py={2}>
                    <Cavity
                      connectionPoint={row.connection.source}
                      conductor={row.connection.conductor}
                      isSource={true}
                      visibility={row.showStartPoint ? 'visible' : 'hidden'}
                    />
                  </Box>
                ))}
              </VStack>
              <Spacer />
              {/* Middle vertical container for conductors */}
              <VStack w="75%" spacing={0} py={2}>
                {connectionGroup.rows.map((row, index) => (
                  <Box key={index} w="full" display="flex" alignItems="center" py={2}>
                    {row.showConductor && (
                      <Conductor conductor={row.connection.conductor} signalName={row.connection.signalName} />
                    )}
                  </Box>
                ))}
              </VStack>
              <Spacer />
              {/* Right vertical container for destination cavities */}
              <VStack h="full" spacing={0} bg={defaultSchematicConfig.connectorColor} borderRadius="md" py={2}>
                {connectionGroup.rows.map(
                  (row, index) =>
                    !isLoopback && (
                      <Box
                        key={index}
                        visibility={row.showEndPoint ? 'visible' : 'hidden'}
                        alignItems="center"
                        px={4}
                        py={2}
                      >
                        <Cavity
                          connectionPoint={row.connection.destination}
                          conductor={row.connection.conductor}
                          isSource={false}
                        />
                      </Box>
                    ),
                )}
              </VStack>
            </HStack>
          </VStack>
        );
      })}
    </VStack>
  );
};
