import { UUID } from '@senrasystems/senra-ui';
import { Edge } from '@xyflow/react';

import { SegmentEdgeData } from '../components/edges/SegmentEdge/SegmentEdge.tsx';
import { Bundle, Bundles, isSegmentEdge } from '../types.ts';

// ===============================
// Functions to work with bundles
// ===============================

/**
 * Deletes a bundle from an array of bundles.
 * @param bundles
 * @param bundleId
 * @returns A new array of bundles without the specified bundle.
 */
export const removeBundle = (bundles: Bundles, bundleId: string): Bundles => {
  return bundles.filter((bundle) => bundle.id !== bundleId);
};

/**
 * Removes a bundle from a segment edge directly by modifying the edges array.
 * If no bundles are left after removal, the edge is removed from the array.
 * @param edges - The array of edges in the graph.
 * @param edgeId - The ID of the edge from which the bundle will be removed.
 * @param bundleId - The ID of the bundle to remove.
 * @returns void
 */
export const removeBundleFromSegment = (edges: Edge[], edgeId: string, bundleId: string): void => {
  const edgeIndex = edges.findIndex((edge) => edge.id === edgeId && isSegmentEdge(edge));
  if (edgeIndex !== -1) {
    const edge = edges[edgeIndex];
    const data = edge.data as SegmentEdgeData;
    data.bundles = removeBundle(data.bundles, bundleId);
    if (data.bundles.length === 0) {
      // Remove the edge if there are no bundles left
      edges.splice(edgeIndex, 1);
    }
  }
};

/**
 * Finds a bundle in an array of bundles.
 * @param bundles
 * @param bundleId
 * @returns The found bundle or undefined if not found.
 */
export const findBundle = (bundles: Bundles, bundleId: string): Bundle | undefined => {
  return bundles.find((bundle) => bundle.id === bundleId);
};

/**
 * Checks if a bundle exists in an array of bundles.
 * @param bundles
 * @param bundleId
 * @returns True if the bundle exists, otherwise false.
 */
export const hasBundle = (bundles: Bundles, bundleId: string): boolean => {
  return bundles.some((bundle) => bundle.id === bundleId);
};

/**
 * Merges two sets of bundles, ensuring that only unique bundles are retained.
 * @param bundles1
 * @param bundles2
 * @returns A new array of merged bundles.
 */
export const mergeBundles = (bundles1: Bundle[], bundles2: Bundle[]): Bundle[] => {
  const bundleMap = new Map<string, Bundle>();

  // Add all existing bundles to the map
  bundles1.forEach((bundle) => bundleMap.set(bundle.id, bundle));

  // Add and merge bundles from the second set
  bundles2.forEach((bundle) => {
    const existingBundle = bundleMap.get(bundle.id);
    if (existingBundle) {
      const uniqueConnections = new Set([...existingBundle.connectionIds, ...bundle.connectionIds]);
      existingBundle.connectionIds = Array.from(uniqueConnections);
    } else {
      bundleMap.set(bundle.id, bundle);
    }
  });

  return Array.from(bundleMap.values());
};

// ==============================================
// Functions to work with connections in bundles
// ==============================================

/**
 * Adds a connection to a bundle. If the connection already exists in the bundle, the original bundle is returned.
 * @param bundle
 * @param connectionId
 * @returns A new bundle with the added connection.
 */
export const addConnectionToBundle = (bundle: Bundle, connectionId: UUID): Bundle => {
  if (hasDuplicateConnection(bundle.connectionIds, connectionId)) {
    return bundle;
  }

  return {
    ...bundle,
    connectionIds: [...bundle.connectionIds, connectionId],
  };
};

/**
 * Updates a bundle with a new connection. If the bundle does not exist, the original array is returned.
 * @param bundles - An array of Bundle objects.
 * @param bundleId - The ID of the bundle to find and update.
 * @param connectionId
 * @returns A new array of bundles with the updated bundle if found, otherwise the original array.
 */
export const updateBundleWithConnection = (bundles: Bundle[], bundleId: string, connectionId: UUID): Bundle[] => {
  // Find the index of the bundle
  const bundleIndex = bundles.findIndex((b) => b.id === bundleId);

  // If the bundle exists, update it with the new connection
  if (bundleIndex !== -1) {
    // Update the bundle at the found index
    const updatedBundles = [...bundles];
    updatedBundles[bundleIndex] = addConnectionToBundle(bundles[bundleIndex], connectionId);
    return updatedBundles;
  }

  // Return the original bundles if no update was made
  return bundles;
};

/**
 * Checks if a connection already exists in a bundle.
 * @param connections - An array of connections.
 * @param connectionId
 * @returns True if the connection already exists in the bundle, otherwise false.
 */
export const hasDuplicateConnection = (connections: UUID[], connectionId: UUID): boolean => {
  return connections.some((id) => id === connectionId);
};
