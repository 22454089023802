import { groupBy } from 'lodash';

import { CableGroup } from '../hooks/useSchematicData.tsx';
import { ConductorData } from './mapConnectionToConductorData.ts';
import { mapGroupedConductors } from './mapGroupedConductors.ts';

/**
 * Get the shielding groups from the conductors. A shielding group is a group of conductors that are shielded and have
 * the same cable name.
 * @param conductors
 */
export const getShieldingGroups = (conductors: ConductorData[]): CableGroup[] => {
  return Object.values(
    groupBy(
      conductors.filter((conductor) => conductor.isShielded),
      (conductor) => conductor.cableName,
    ),
  )
    .filter((group) => group.length > 0)
    .map(mapGroupedConductors);
};
