import { DesignPart, ResolvedConnection } from '@senrasystems/senra-ui';
import { Edge } from '@xyflow/react';

import { Graph } from '../../../../../types/reactFlow.ts';
import { Bundle, isControlPointNode, isDesignPartNode, isMeasurementEdge, isSegmentEdge } from '../../types.ts';
import { findPath, getConnectedSegments } from '../../utils/graph.ts';
import { Operations } from '../Operations.ts';

// Operation to validate the layout
export type ValidateLayoutOperation = {
  type: 'ValidateLayout';
  params: {
    connections: ResolvedConnection[];
    designParts: DesignPart[];
  };
};

/**
 * Validates the layout by removing any edges with no connections.
 */
export class ValidateLayout implements Operations<ValidateLayoutOperation> {
  // Execute the operation
  execute(graph: Graph, operation: ValidateLayoutOperation): Graph {
    const { nodes, edges } = graph;
    const { connections, designParts } = operation.params;

    const connectionsById = new Map();
    const validBundleIds = new Set();

    connections.forEach((connection) => {
      connectionsById.set(connection.id, connection);
      validBundleIds.add(connection.bundleId);
    });

    // Remove connections from segments that are no longer in the design, possibly removing the segments if there are
    // no connections left in the segment
    const updatedEdges = edges.reduce((prevEdges, edge) => {
      if (isSegmentEdge(edge)) {
        // Update bundles by filtering out those with no connections and invalid bundle IDs
        const updatedBundles = edge.data.bundles.reduce((bundles, bundle) => {
          // Only keep connections with valid IDs, and connections that belong to the current bundle
          const filteredConnectionIds = bundle.connectionIds.filter((connectionId) => {
            const connection = connectionsById.get(connectionId);
            return connection && connection?.bundleId === bundle.id;
          });

          // Only keep the bundle if it has connections and a valid bundle ID
          if (filteredConnectionIds.length > 0 && validBundleIds.has(bundle.id)) {
            bundles.push({ ...bundle, connectionIds: filteredConnectionIds });
          }

          return bundles;
        }, [] as Bundle[]);

        // Keep the edge only if it has bundles
        if (updatedBundles.length > 0) {
          const updatedData = { ...edge.data, bundles: updatedBundles };
          prevEdges.push({ ...edge, data: updatedData });
        }
      } else {
        prevEdges.push(edge);
      }

      return prevEdges;
    }, [] as Edge[]);

    // Remove:
    // - Control point nodes with no edges (caused by removing segments with no connections)
    // - Design part nodes that are no longer in the design
    const updatedNodes = nodes.filter((node) => {
      if (isControlPointNode(node)) {
        return getConnectedSegments(updatedEdges, node.id).length > 0;
      }

      if (isDesignPartNode(node)) {
        return designParts.some((part) => part.id === node.id);
      }

      return true;
    });

    // Remove:
    // Measurements that are no longer applicable (caused by removing segments with no connections)
    const finalEdges = updatedEdges.filter((edge) => {
      if (isMeasurementEdge(edge)) {
        const { pathExists } = findPath(updatedNodes, updatedEdges, edge.source, edge.target, isControlPointNode);
        return pathExists;
      }

      return true;
    });

    return {
      nodes: updatedNodes,
      edges: finalEdges,
    };
  }
}
