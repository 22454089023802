import { ResolvedConnection, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { Edge, Node, XYPosition } from '@xyflow/react';

import { defaultPosition } from '../../../../types/reactFlow.ts';
import { defaultSchematicConfig } from '../config.ts';
import { SchematicData } from '../hooks/useSchematicData.tsx';
import { EdgeType } from '../types/edges.ts';
import { NodeType } from '../types/nodes.ts';
import {
  getConductorLeftHandle,
  getConductorRightHandle,
  getEndPointHandle,
  getStartPointHandle,
} from '../utils/handles.ts';

const schematicNodeId = 'schematic-node';

/**
 * Create a schematic node. All connectors, cavities, and conductors are rendered in this node.
 * @param schematicData
 * @param width
 * @param position
 */
export const createSchematicNode = (
  schematicData: SchematicData,
  width: number = defaultSchematicConfig.width,
  position: XYPosition = defaultPosition,
): Node => {
  return {
    id: schematicNodeId,
    type: NodeType.Schematic,
    data: {
      schematicData,
      width,
    },
    position,
  };
};

/**
 * Create a generic edge.
 * @param type
 * @param sourceHandle
 * @param targetHandle
 */
export const createEdge = (type: EdgeType, sourceHandle: string, targetHandle: string): Edge => {
  return {
    id: window.crypto.randomUUID(),
    type,
    source: schematicNodeId,
    sourceHandle: sourceHandle,
    target: schematicNodeId,
    targetHandle: targetHandle,
    zIndex: 1,
  };
};

/**
 * Create conductor edges. These edges connect cavities to conductors and conductors to cavities.
 * @param connection
 */
export const createConductorEdges = (connection: ResolvedConnection): Edge[] => {
  const edges: Edge[] = [];

  if (connection.source && connection.conductor) {
    edges.push(
      createEdge(
        EdgeType.Conductor,
        getStartPointHandle(connection.source),
        getConductorLeftHandle(connection.conductor),
      ),
    );
  }

  if (connection.destination && connection.conductor) {
    edges.push(
      createEdge(
        EdgeType.Conductor,
        getConductorRightHandle(connection.conductor),
        getEndPointHandle(connection.destination),
      ),
    );
  }

  return edges;
};

/**
 * Create vertical edges that indicate twisting or shielding.
 * @param type
 * @param cp1
 * @param cp2
 */
export const createTwistOrShieldEdges = (
  type: EdgeType,
  cp1: ResolvedConnectionPoint,
  cp2: ResolvedConnectionPoint,
): Edge[] => {
  return [
    createEdge(type, getConductorLeftHandle(cp1), getConductorLeftHandle(cp2)),
    createEdge(type, getConductorRightHandle(cp1), getConductorRightHandle(cp2)),
  ];
};

export const createHousingEdge = (connection: ResolvedConnection, isSource: boolean): Edge | null => {
  if (!connection.conductor) {
    return null;
  }

  if (isSource && connection.source) {
    return createEdge(
      EdgeType.Housing,
      getStartPointHandle(connection.source, connection.conductor.id),
      getConductorLeftHandle(connection.conductor),
    );
  }

  if (!isSource && connection.destination) {
    return createEdge(
      EdgeType.Housing,
      getEndPointHandle(connection.destination, connection.conductor.id),
      getConductorRightHandle(connection.conductor),
    );
  }

  return null;
};
