import { useReactFlow } from '@xyflow/react';
import { useMemo } from 'react';

import { isConnectorNode, isControlPointNode, isPigtailNode } from '../../../types.ts';
import { calculateAngleBetweenPoints } from '../../../utils/geometry.ts';
import { getConnectedLayoutNodes } from '../../../utils/graph.ts';

interface Props {
  nodeId: string;
}

export const useShapeRotation = ({ nodeId }: Props) => {
  // React Flow hooks
  const { getNode, getNodes, getEdges } = useReactFlow();

  // Get connected nodes, and find a target node to determine the angle to rotate this shape
  const connectedNodes = getConnectedLayoutNodes(getNodes(), getEdges(), nodeId);

  // Prioritize the nodes based on priority (control point > connector with rotate lock > any other node)
  const targetNode =
    connectedNodes.find((node) => isControlPointNode(node)) ||
    connectedNodes.find(
      (node) =>
        (isConnectorNode(node) && node.data.rotateLock) ||
        (isPigtailNode(node) && node.data.rotateLock) ||
        connectedNodes[0],
    );

  // Calculate angle to make the node perpendicular to the edge
  return useMemo(() => {
    if (targetNode) {
      const thisNode = getNode(nodeId);
      if (thisNode) {
        return calculateAngleBetweenPoints(thisNode.position, targetNode.position);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [getNode, nodeId, targetNode]);
};
