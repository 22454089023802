import { Box, Center, FormControl, FormLabel, HStack, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { apiGetSignedS3Url } from '../../api/order-api';
import Xmark from '../svg/Xmark';

interface S3FileInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  button?: ReactNode;
  label?: string;
  hint?: string;
  multiple?: boolean;
  direction?: 'row' | 'column';
  showDeleteButton?: boolean;
  singleFile?: boolean;
}

export interface S3File {
  filename: string;
  key: string;
}

const S3FileInput = <T extends FieldValues>({
  label,
  hint = 'Click here or drag to upload your files',
  control,
  name,
  button,
  multiple = true,
  direction = 'column',
  showDeleteButton = true,
  singleFile = false,
}: S3FileInputProps<T>) => {
  const { field } = useController({
    name,
    control,
  });

  const onUploadFinish = (file: S3File) => {
    const newFile = { filename: file.filename, s3Key: file.key };

    if (singleFile) field.onChange({ ...field.value, ...newFile });
    else if (multiple) field.onChange([...field.value, newFile]);
    else field.onChange([newFile]);
  };

  const onGetSignedUrl = async (file: File, callback: () => void) => {
    const response = await apiGetSignedS3Url(file);
    callback({ signedUrl: response.signedUrl, key: response.key, filename: file.name });
  };

  const FileInfo = ({ file }: { file: { filename: string; s3Key: string } }) => (
    <HStack>
      <Text>{file.filename}</Text>
      {showDeleteButton && (
        <Box
          cursor="pointer"
          onClick={() => {
            if (singleFile) {
              field.onChange(null);
            } else {
              field.onChange(field.value.filter((f) => f.s3Key !== file.s3Key));
            }
          }}
        >
          <Xmark size={3} />
        </Box>
      )}
    </HStack>
  );

  const handleUploadFinish = (info) => {
    if (info.file.size === 0) {
      alert('File cannot be size 0');
      return;
    }
    onUploadFinish(info);
  };

  const bgColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack direction={direction} spacing={3}>
        <DropzoneS3Uploader
          multiple={multiple}
          onFinish={handleUploadFinish}
          s3Url=""
          upload={{
            getSignedUrl: onGetSignedUrl,
            uploadRequestHeaders: { 'x-amz-acl': 'private' },
          }}
          style={
            button
              ? { border: 'none', boxShadow: 'none', background: 'transparent' }
              : { width: '100%', height: '100%', border: '1px dashed white' }
          }
        >
          {button ? (
            <Box cursor="pointer">{button}</Box>
          ) : (
            <Center h="100%" bgColor={bgColor} p={8}>
              <Text textAlign="center">{hint}</Text>
            </Center>
          )}
        </DropzoneS3Uploader>
        {singleFile && field.value && <FileInfo file={field.value} />}
        {!singleFile && field.value?.map?.((file, index) => <FileInfo key={index} file={file} />)}
      </Stack>
    </FormControl>
  );
};

export default S3FileInput;
