import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactElement, useRef } from 'react';

interface Props {
  children: ReactElement;
  title?: string;
  body?: string;
  isCentered?: boolean;
  onConfirm: () => void;
}

const Confirm = ({ children, title, body, isCentered, onConfirm }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <children.type {...children.props} onClick={onOpen} />
      <AlertDialog isCentered={isCentered} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent bg="background">
            {title && <AlertDialogHeader>{title}</AlertDialogHeader>}
            {body && <AlertDialogBody py={4}>{body}</AlertDialogBody>}
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="ghost">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Confirm;
