import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
  isOpen: boolean;
}

export const CollapseButton = ({ onClick, isOpen }: Props) => (
  <IconButton
    aria-label={isOpen ? 'collapse' : 'expand'}
    position="absolute"
    top={5}
    right={0}
    transform="translateX(50%)"
    icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    colorScheme="blue"
    color="white"
    size="sm"
    fontSize="2xl"
    onClick={onClick}
    isRound
  />
);
