import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';

import { authService } from '../../services/auth-service';

export default function AuthServiceWrapper({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  // .. set auth0 client in service singleton
  useEffect(() => {
    authService.setClient({ getAccessToken: getAccessTokenSilently });
  }, [getAccessTokenSilently]);

  return children;
}
