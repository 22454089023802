import { isConnectorDesignPart } from '@senrasystems/senra-ui';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { defaultViewport, setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import { defaultSchematicConfig } from '../config.ts';
import { SchematicData, useSchematicData } from '../hooks/useSchematicData.tsx';
import { buildGraph } from '../utils/buildGraph.ts';

export interface UseLoadSchematic {
  schematicLoaded: boolean;
  updateSchematicWidth: (width: number) => void;
}

export const useLoadSchematic = (setNodes: setNodesType, setEdges: setEdgesType): UseLoadSchematic => {
  const [schematicLoaded, setSchematicLoaded] = useState(false);
  const [width, setWidth] = useState(defaultSchematicConfig.width);
  const { setViewport } = useReactFlow();

  const selectedDesignPart = useSelectedDesignPart();
  const { schematicData, isSuccess: isSchematicDataLoaded } = useSchematicData();

  // Provide a function to update the schematic width, which will trigger a reload
  const updateSchematicWidth = useCallback((width: number) => {
    setSchematicLoaded(false);
    setWidth(width);
  }, []);

  // Connection groups for the selected design part
  const selectedDesignPartSchematicData: SchematicData = useMemo(() => {
    if (isSchematicDataLoaded && selectedDesignPart && isConnectorDesignPart(selectedDesignPart)) {
      return Object.entries(schematicData).reduce((map, [key, value]) => {
        if (value.connectionGroup.startPoint?.designPart?.id === selectedDesignPart.id) {
          map[key] = value;
        }
        return map;
      }, {} as SchematicData);
    }
    return {} as SchematicData;
  }, [isSchematicDataLoaded, schematicData, selectedDesignPart]);

  // Reset schematic loaded state when the selected design part changes
  useEffect(() => {
    setSchematicLoaded(false);
  }, [selectedDesignPartSchematicData]);

  // Initialize schematic
  useEffect(() => {
    const loadSchematic = async () => {
      if (!selectedDesignPart || schematicLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isSchematicDataLoaded && schematicData) {
        const graph = buildGraph(selectedDesignPartSchematicData, width);
        console.debug('Graph:', graph);

        setNodes(graph.nodes);
        setEdges(graph.edges);
        await setViewport(defaultViewport);

        setSchematicLoaded(true);
      }
    };

    void loadSchematic();
  }, [
    setNodes,
    setEdges,
    setViewport,
    isSchematicDataLoaded,
    schematicLoaded,
    schematicData,
    selectedDesignPartSchematicData,
    width,
    selectedDesignPart,
  ]);

  return { schematicLoaded, updateSchematicWidth };
};
