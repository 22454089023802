import '@xyflow/react/dist/style.css';

import { Box } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { ConnectionMode, Controls, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { LegacyRef } from 'react';
import { useDebounce, useMeasure } from 'react-use';

import { defaultViewport, emptyEdges, emptyNodes } from '../../../types/reactFlow.ts';
import ManagedWindow from '../../components/ManagedWindow.tsx';
import { useDesign } from '../../hooks/useDesign.tsx';
import { defaultSchematicConfig } from './config.ts';
import { useLoadSchematic } from './hooks/useLoadSchematic.tsx';
import { edgeTypes } from './types/edges.ts';
import { nodeTypes } from './types/nodes.ts';

/**
 * Entry point for the Schematic feature.
 * @constructor
 */
const Schematic = () => {
  const { isViewOnly } = useDesign();
  const [ref, bounds] = useMeasure();

  // Initialize the nodes and edges state
  const [nodes, setNodes, onNodesChange] = useNodesState(emptyNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(emptyEdges);

  // Initialize Schematic
  const { updateSchematicWidth } = useLoadSchematic(setNodes, setEdges);

  useDebounce(() => updateSchematicWidth(bounds.width), 300, [bounds.width]);

  return (
    <ManagedWindow title="Schematic" routeName={RouteNames.DESIGNS.SCHEMATIC}>
      {() => (
        <Box ref={ref as LegacyRef<HTMLDivElement>} w="full" h="full">
          {/* Render the ReactFlow component */}
          <ReactFlow
            /* Config nodes */
            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={onNodesChange}
            nodesConnectable={false}
            nodesDraggable={!isViewOnly}
            /* Config edges */
            edgeTypes={edgeTypes}
            edges={edges}
            onEdgesChange={onEdgesChange}
            /* ReactFlow settings (fixed) */
            connectionMode={ConnectionMode.Loose}
            proOptions={{ hideAttribution: true }}
            zoomOnDoubleClick={false}
            panOnScroll={true}
            deleteKeyCode={null}
            /* ReactFlow settings (from config.ts) */
            defaultViewport={defaultViewport}
            minZoom={defaultSchematicConfig.minZoom}
            maxZoom={defaultSchematicConfig.maxZoom}
            nodeOrigin={defaultSchematicConfig.nodeOrigin}
            style={defaultSchematicConfig.style}
          >
            <Controls showInteractive={false} position="bottom-left" />
          </ReactFlow>
        </Box>
      )}
    </ManagedWindow>
  );
};

export default Schematic;
