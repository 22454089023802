import { Box, FormControl, FormLabel, HStack, Stack, Switch } from '@chakra-ui/react';
import { Wire } from '@senrasystems/senra-ui';
import { milSpecColors } from '@web/common/colors.ts';
import { TSelectOption } from '@web/common/lib/common-types/dropdown.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import { MultiValue, Select } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';

import TextInput from '../../../../components/form/TextInput.tsx';
import ConductorDiameterFields from '../../components/ConductorDiameterFields.tsx';

interface Props {
  editing: boolean;
}

const WireFields = ({ editing }: Props) => {
  const { control, getValues, register, setValue } = useFormContext<Wire>();
  const { field: colors } = useController({ name: 'colors', control });
  const { field: cableOnlyWire } = useController({ name: 'cableOnlyWire', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const colorOptions: TSelectOption<string>[] = Array.from(Object.keys(milSpecColors)).map((v) => ({
    label: v,
    value: v,
    id: v,
  }));

  const selectedColorOptions = colors?.value
    ? colors.value.map((current) => {
        return colorOptions.find((color) => color.value === current) as TSelectOption<string>;
      })
    : [];

  const handleOnChange = (newOption: MultiValue<TSelectOption<string>>) => {
    if (newOption) {
      colors.onChange(newOption);
      setValue('colors', [...newOption.map((opt) => opt.value)]);
    }
  };

  return (
    <Stack spacing={6}>
      <HStack>
        <Box flex={1}>
          <EditableFormText editing={editing} label="AWG" value={getValues('gauge')}>
            <FormControl>
              <FormLabel id="awgLabel">AWG</FormLabel>
              <TextInput
                aria-labelledby="awgLabel"
                formControlProps={{ isDisabled: !editing }}
                placeholder="Gauge"
                type="number"
                {...register('gauge')}
              />
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <EditableFormText
            editing={editing}
            label="Colors"
            value={selectedColorOptions.map((color) => color.value).join(', ')}
          >
            <FormControl>
              <FormLabel>Colors</FormLabel>
              <Select
                isMulti
                value={selectedColorOptions}
                options={colorOptions}
                onChange={handleOnChange}
                isDisabled={!editing}
                menuPortalTarget={document.getElementById('portal')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Is a cable only wire" value={cableOnlyWire.value ? 'Yes' : 'No'}>
            <FormControl>
              <FormLabel>Is a cable only wire</FormLabel>
              <Switch
                isDisabled={!editing}
                isChecked={cableOnlyWire.value}
                onChange={(e) => cableOnlyWire.onChange(e.target.checked)}
              />
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      <ConductorDiameterFields config={config?.parts.wire} editing={editing} />
    </Stack>
  );
};

export default WireFields;
