import { Actor } from '@senrasystems/senra-ui';
import { User } from '@senrasystems/senra-ui';

import { S3Download, S3Upload } from './documents';

export interface CreateOrderDto {
  tenantId?: string;
  name: string;
  phoneNumber: string;
  qualityRequirements: string;
  notes?: string;
  materialAcquisition: string;
  salesWinProbability: number;
  serializationRequirements: string | null;
  lineItems: CreateLineItemDto[] | string[];
}

export interface CreateLineItemDto {
  documents: File[];
  partNumber: string;
  partRevision?: string;
  quantity: string;
  description?: string;
  neededBy: string;
}

export interface LineItem {
  id: number;
  partNumber: string;
  partRevision: string | null;
  quantity: number;
  description: string | null;
  neededBy: Date;
  documents: S3Download[];
  documentsProcessed: boolean;
}

export interface Order {
  createdAt: Date;
  id: string;
  orderNumber: string;
  name: string;
  updatedAt: Date;
  state: OrderState;
  materialAcquisition: string;
  qualityRequirements: string | null;
  salesWinProbability: number;
  serializationRequirements: string | null;
  notes: string | null;
  quote: { id: string; estimateNumber: string | null; expiresAt: Date | null; createdAt: Date } | null;
  lineItems: LineItem[];
  purchaseOrder: { id: string; purchaseOrderNumber: string; purchaseOrderAmount: number } | null;
  owner: Actor | null;
  user: Actor;
  stateEvents: OrderStateEvent[];
  tenant: {
    id: string;
    name: string;
    usBased: boolean;
  };
  declineType: OrderDeclineTypesKey | null;
  declineNotes: string | null;
}

// DTO for orders displayed on the dashboard
export interface DashboardOrder {
  id: string;
  createdAt: Date;
  orderNumber: string;
  name: string;
  state: OrderState;
  materialAcquisition: string;
  salesWinProbability: number;
  quote: { id: string; estimateNumber: string | null; expiresAt: Date | null; createdAt: Date } | null;
  neededBy: string;
  purchaseOrder: { id: string; purchaseOrderNumber: string; purchaseOrderAmount: number } | null;
  owner: Actor | null;
  user: Actor;
  tenant: {
    name: string;
  };
}

export enum OrderDocumentTypes {
  LineItem = 'LineItemDocument',
  PurchaseOrder = 'PurchaseOrder',
  Quote = 'QuoteDocument',
}

export enum OrderStateEvent {
  Requote = 'requote',
  UploadQuote = 'upload_quote',
  UploadPurchaseOrder = 'upload_purchase_order',
  Procure = 'procure',
  FinishProcurement = 'finish_procurement',
  Deliver = 'deliver',
  Decline = 'decline',
}

export type OrderState =
  | 'pending_quote'
  | 'quoted'
  | 'quote_expired'
  | 'in_procurement'
  | 'procured'
  | 'accepted'
  | 'delivered'
  | 'declined';

export enum OrderDeclineTypes {
  Default = '',
  NotEnoughCapacity = 'Not enough capacity',
  PartShortage = 'Part shortage',
  UnableToManufacture = 'Unable to manufacture',
  CustomerCanceled = 'Customer canceled',
  Other = 'Other',
}

export type OrderDeclineTypesKey = keyof typeof OrderDeclineTypes;

export interface IOrderTransition {
  orderId: string;
  event: OrderStateEvent;
}

export interface IOrderDeclineTransition extends IOrderTransition {
  declineType: OrderDeclineTypes;
  declineNotes?: string;
}

export interface OrderCommentDto {
  id: string;
  tenantId: string;
  orderId: string;
  userId: string;
  user?: User;
  internal: boolean;
  commentId: string;
  body: string;
  attachments: { id: string; filename: string; url: string }[];
  createdAt: Date;
  updatedAt: Date;
  replies?: OrderCommentDto[];
}

export interface OrderCommentCreateDto {
  body: string;
  attachments: S3Upload[];
  commentId?: number;
  internal: boolean;
}

export interface OrderCommentEditDto {
  body: string;
  attachments: S3Upload[];
  internal: boolean;
}
