import { MutationCache, QueryCache, QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enableMapSet, setAutoFreeze } from 'immer';
import { Toaster } from 'react-hot-toast';

import { AppRoutes } from './AppRoutes';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate';
import SVGDefinitions from './styles/SVGDefinitions';

/**
 * Enable Map.set() in Immer and disable auto-freezing.
 */
enableMapSet();
setAutoFreeze(false);

/**
 * The main application component.
 * @constructor
 */
export default function App() {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => console.log(`useQuery error: ${error.message}`),
    }),
    mutationCache: new MutationCache({
      onError: (error) => console.log(`useMutation error: ${error.message}`),
    }),
  });

  return (
    <Auth0ProviderWithNavigate>
      <QueryClientProvider client={queryClient}>
        {/* Render the SVG definitions */}
        <SVGDefinitions />
        <AppRoutes />
        <Toaster
          position="top-center"
          toastOptions={{
            style: { borderRadius: 0 },
            success: { iconTheme: { primary: '#36D399', secondary: '#FFFFFF' } },
            error: { iconTheme: { primary: '#F87272', secondary: '#FFFFFF' } },
          }}
        />
        <div id="portal" />
        <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-left" />
      </QueryClientProvider>
    </Auth0ProviderWithNavigate>
  );
}
