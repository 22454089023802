import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  partNumber: string;
  partUrl: string;
  onAccept: () => void;
}

const AcceptChangesModal = ({ isOpen, onClose, partNumber, partUrl, onAccept }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Part Data Available</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text>There are changes to the following part in the Part Library:</Text>
            <Text>• {partNumber}</Text>
            <Text>
              See details of these changes in the{' '}
              <Link href={partUrl} color="blue.500" isExternal>
                Parts Library
              </Link>
              .
            </Text>
            <Text>Would you like to accept changes to these parts in your design?</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Ignore
          </Button>
          <Button colorScheme="blue" onClick={onAccept}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AcceptChangesModal;
