import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import theme from '../../apps/themes';
import TopbarNav from '../navigation/TopbarNav';

export const DefaultLayout = () => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <TopbarNav />
        <Outlet />
      </ColorModeProvider>
    </ChakraProvider>
  );
};
