import { Input } from '@chakra-ui/react';
import { useRef } from 'react';

const FilterBox = ({
  placeholder = 'Type to filter',
  value,
  onChange,
  shouldFocus = true,
}: {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  shouldFocus?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setRef = (node: HTMLInputElement | null) => {
    inputRef.current = node;
    if (shouldFocus && node) {
      node.focus();
    }
  };

  return (
    <Input
      ref={setRef}
      type="text"
      onChange={(event) => onChange(event.target.value)}
      value={value}
      placeholder={placeholder}
      maxW="20rem"
    />
  );
};

export default FilterBox;
