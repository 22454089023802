import { Box, FormControl, FormLabel, HStack, Input, Select } from '@chakra-ui/react';
import { Contact } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import Loading from '@web/components/Loading.tsx';
import { useFormContext } from 'react-hook-form';

import { useConfiguration } from '../../../../queries/admin.ts';
import MinMaxAwgFields from '../../components/MinMaxAwgFields.tsx';
import ToolRelationRows from '../../components/ToolRelationRows.tsx';

interface Props {
  editing: boolean;
}

const ContactFields = ({ editing }: Props) => {
  const { register, getValues } = useFormContext<Contact>();
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  return (
    <>
      <HStack alignItems="flex-start" spacing={10}>
        <Box flex={1}>
          <HStack spacing={10} alignItems="flex-start">
            <EditableFormText editing={editing} label="Gender" value={getValues('gender')}>
              <FormControl>
                <FormLabel>Gender</FormLabel>
                <Select isDisabled={!editing} defaultValue={config.parts.contact.genders[0]} {...register('gender')}>
                  {config.parts.contact.genders.map((val) => {
                    return (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </EditableFormText>
            <MinMaxAwgFields editing={editing} />
          </HStack>
        </Box>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Termination" value={getValues('termination')}>
            <FormControl>
              <FormLabel>Termination</FormLabel>
              <Select
                isDisabled={!editing}
                defaultValue={config.parts.contact.terminations[0]}
                {...register('termination')}
              >
                {config.parts.contact.terminations.map((val) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      <FormControl>
        <FormLabel>Strip Length</FormLabel>
        <Input isDisabled={!editing} {...register('stripLength')} placeholder="Inches" w="50%" />
      </FormControl>
      <ToolRelationRows editing={editing} />
    </>
  );
};

export default ContactFields;
