import { Tool, ToolType } from '@senrasystems/senra-ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiDeleteTool, apiGetTool, apiGetTools, apiPostTool, apiPutTool } from '@web/api/tools-api.ts';

const toolCacheKey = (toolId?: string) => ['tool', toolId].filter(Boolean);
const toolsCacheKey = (toolType?: ToolType, page?: string, query?: string | null, pageSize?: number) =>
  ['tools', toolType, page, query, pageSize].filter(Boolean);

export const useTool = (toolId?: string) => {
  return useQuery({
    queryKey: toolCacheKey(toolId),
    queryFn: () => {
      if (toolId) return apiGetTool(toolId);
    },
  });
};

export const useTools = (toolType?: ToolType, partNumber?: string | null, page?: string, pageSize?: number) => {
  return useQuery({
    queryKey: toolsCacheKey(toolType, page, partNumber, pageSize),
    queryFn: () => apiGetTools({ toolType, partNumber, page, pageSize }),
  });
};

export const useCreateTool = (toolType?: ToolType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['post-tool'],
    mutationFn: apiPostTool,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: toolsCacheKey(toolType) });
    },
  });
};

export const useUpdateTool = (toolType?: ToolType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['put-tool'],
    mutationFn: apiPutTool,
    onSuccess: async (tool: Tool | undefined) => {
      await Promise.all([
        client.invalidateQueries({ queryKey: toolsCacheKey(toolType) }),
        client.invalidateQueries({ queryKey: toolCacheKey(tool?.id) }),
      ]);
    },
  });
};

export const useDeleteTool = (toolType?: ToolType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['delete-tool'],
    mutationFn: apiDeleteTool,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: toolsCacheKey(toolType) });
    },
  });
};
