import { Box, HStack, IconButton, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { Part, PartTool, ToolType } from '@senrasystems/senra-ui';
import { apiGetTools } from '@web/api/tools-api.ts';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextAreaInput from '@web/components/form/TextAreaInput.tsx';
import { AsyncSelect, Options, SingleValue } from 'chakra-react-select';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

import { useTool } from '../../Tools-Library/api/queries.ts';
import { Mode } from '../../Tools-Library/components/ToolsLibraryDashboard.tsx';
import ToolsLibraryModal from '../../Tools-Library/components/ToolsLibraryModal.tsx';
import { toolTypeToAdditionalFields, toolTypeToLabel, toolTypeToTitle, toOption } from './ToolRelationRows';

interface Props {
  editing: boolean;
  index: number;
  remove: (index: number) => void;
  update: (index: number, partTool: PartTool) => void;
  defaultOptions: Options<{ label: string; value: string; type: string } | undefined>;
}

const ToolRelationRow = ({ editing, index, remove, update, defaultOptions }: Props) => {
  const { setValue, watch, getValues } = useFormContext<Part>();
  const partTool = getValues(`partTools.${index}`);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: tool } = useTool(partTool?.toolId);
  const [mode, setMode] = useState(Mode.READ);

  // If part tool is marked as _destroy do not render row
  if (!partTool || partTool._destroy) {
    return null;
  }

  const loadOptions = async (partNumber: string) => {
    const res = await apiGetTools({ partNumber });
    return res ? res.data.map(toOption) : [];
  };

  const onChange = (option: SingleValue<{ label: string; value: string; type: string } | undefined>) => {
    if (option) {
      setValue(`partTools.${index}.toolId`, option.value);
      setValue(`partTools.${index}.partNumber`, option.label);
      setValue(`partTools.${index}.type`, option.type);
    }
  };

  const onPartToolRemove = () => {
    if (partTool.id) {
      update(index, { ...partTool, _destroy: true });
    } else {
      remove(index);
    }
  };

  const toolType = watch(`partTools.${index}.type`) as ToolType;

  const onToolOpen = () => {
    onOpen();
    setMode(Mode.READ);
  };

  return (
    <>
      <ToolsLibraryModal
        title={toolTypeToTitle[toolType]}
        defaultValues={tool}
        toolType={toolType}
        mode={mode}
        isOpen={!!tool && isOpen}
        onClose={onClose}
        additionalFieldsComponent={toolTypeToAdditionalFields[toolType as keyof typeof toolTypeToAdditionalFields]}
        enableEditMode={() => setMode(Mode.UPDATE)}
      />
      <Tr>
        <Td>
          <HStack>
            <EditableFormText label={null} value={partTool.partNumber} editing={editing}>
              <Box width="12rem">
                <AsyncSelect
                  isMulti={false}
                  loadOptions={loadOptions}
                  placeholder={partTool.partNumber}
                  onChange={onChange}
                  defaultOptions={defaultOptions}
                />
              </Box>
            </EditableFormText>
            {partTool?.toolId && <FaExternalLinkAlt cursor="pointer" onClick={onToolOpen} />}
          </HStack>
        </Td>
        <Td>
          <Text>{toolTypeToLabel[toolType] || toolType}</Text>
        </Td>
        <Td>
          <TextAreaInput
            rows={1}
            value={watch(`partTools.${index}.notes`) || ''}
            onChange={(e) => setValue(`partTools.${index}.notes`, e.target.value)}
            formControlProps={{ isDisabled: !editing }}
          />
        </Td>
        <Td textAlign="right">
          {editing && (
            <IconButton
              isRound
              variant="ghost"
              icon={<MdCancel size="1em" />}
              onClick={onPartToolRemove}
              aria-label={`Remove ${partTool.partNumber}`}
            />
          )}
        </Td>
      </Tr>
    </>
  );
};

export default ToolRelationRow;
