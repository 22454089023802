import { OrderState } from '@web/common/lib';

import { toTitleCase } from '../common/lib/string-utils/string-utils';
import Badge from './Badge';

const colorMap: Partial<Record<OrderState, string>> = {
  pending_quote: 'yellow',
  quoted: 'blue',
  accepted: 'purple',
  in_procurement: 'orange',
  procured: 'teal',
  delivered: 'green',
  declined: 'red',
};

interface Props {
  orderState: OrderState;
}

const OrderStateBadge = ({ orderState }: Props) => {
  return <Badge color={colorMap[orderState] ?? 'gray'}>{toTitleCase(orderState)}</Badge>;
};

export default OrderStateBadge;
