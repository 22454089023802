import { LoopbackEdge } from '@web/apps/Design/features/Schematic/components/edges/LoopbackEdge.tsx';

import { ConductorEdge } from '../components/edges/ConductorEdge.tsx';
import { DoubleShieldingEdge } from '../components/edges/DoubleShieldingEdge.tsx';
import { HousingEdge } from '../components/edges/HousingEdge.tsx';
import { ShieldingEdge } from '../components/edges/ShieldingEdge.tsx';
import { TwistingEdge } from '../components/edges/TwistingEdge.tsx';

export enum EdgeType {
  Conductor = 'Conductor',
  DoubleShielding = 'DoubleShielding',
  Housing = 'Housing',
  Loopback = 'Loopback',
  Shielding = 'Shielding',
  Twisting = 'Twisting',
}

export const edgeTypes = {
  Conductor: ConductorEdge,
  DoubleShielding: DoubleShieldingEdge,
  Housing: HousingEdge,
  Loopback: LoopbackEdge,
  Shielding: ShieldingEdge,
  Twisting: TwistingEdge,
};
