import { normalizeSourceTargetIds, UUID } from '@senrasystems/senra-ui';
import { Edge, Node } from '@xyflow/react';

import { Graph } from '../../../../../types/reactFlow.ts';
import {
  Bundle,
  Bundles,
  isBreakoutPointNode,
  isControlPointNode,
  isDesignPartNode,
  isLayoutPointNode,
  isSegmentEdge,
} from '../../types.ts';
import {
  findMeasurementEdge,
  getConnectedSegments,
  removeConnectedMeasurements,
  removeConnectedSegments,
} from '../../utils/graph.ts';
import { createAndAddMeasurementEdge, createAndAddSegmentEdge } from '../EdgeFactory.ts';
import { findNodeById, removeNodeIfExists } from '../NodeFactory.ts';
import { Operations } from '../Operations.ts';

// Operation to remove a control point
export type RemoveControlPointOperation = {
  type: 'RemoveControlPoint';
  params: {
    nodeId: UUID;
  };
};

/**
 * Removes a control point node from the graph. This operation will remove the control point node and reconnect the
 * nodes that were connected to the control point.
 */
export class RemoveControlPoint implements Operations<RemoveControlPointOperation> {
  // Rebuild the graph after the operation
  requiresRebuild = true;

  // Execute the operation
  execute(graph: Graph, operation: RemoveControlPointOperation): Graph {
    const { nodes, edges } = graph;
    const { nodeId } = operation.params;

    // Find the control point node
    const controlPointToRemove = findNodeById(nodes, nodeId);
    if (!controlPointToRemove || !isControlPointNode(controlPointToRemove)) {
      console.warn(`Control point node not found: ${nodeId}`);
      return { nodes, edges };
    }

    // Build a map of nodes connected to the control point and their bundles
    const nodeConnectionMap = this.buildNodeConnectionMap(nodes, edges, controlPointToRemove);

    // Reconnect nodes based on matching bundles, and also create measurement edges if necessary
    this.reconnectNodes(edges, controlPointToRemove, nodeConnectionMap);

    // Remove the control point node, segments, and measurements
    removeNodeIfExists(nodes, controlPointToRemove.id);
    removeConnectedSegments(edges, controlPointToRemove.id);
    removeConnectedMeasurements(edges, controlPointToRemove.id);

    return { nodes: nodes, edges: edges };
  }

  /**
   * Build a map of nodes connected to the control point and their bundles.
   * @param nodes
   * @param edges
   * @param controlPointToRemove
   * @private
   */
  private buildNodeConnectionMap(
    nodes: Node[],
    edges: Edge[],
    controlPointToRemove: Node,
  ): Map<string, { node: Node; bundles: Bundle[] }> {
    const nodeConnectionMap = new Map<string, { node: Node; bundles: Bundle[] }>();
    const connectedEdges = getConnectedSegments(edges, controlPointToRemove.id);

    connectedEdges.forEach((edge) => {
      if (isSegmentEdge(edge)) {
        const otherNodeId = edge.source === controlPointToRemove.id ? edge.target : edge.source;
        const otherNode = findNodeById(nodes, otherNodeId);

        if (otherNode && (isDesignPartNode(otherNode) || isControlPointNode(otherNode))) {
          if (!nodeConnectionMap.has(otherNodeId)) {
            nodeConnectionMap.set(otherNodeId, { node: otherNode, bundles: [...edge.data.bundles] });
          } else {
            const existingEntry = nodeConnectionMap.get(otherNodeId);
            existingEntry?.bundles.push(...edge.data.bundles);
          }
        }
      }
    });

    return nodeConnectionMap;
  }

  /**
   * Reconnect nodes based on matching bundles.
   * @param edges
   * @param controlPointToRemove
   * @param nodeConnectionMap
   * @private
   */
  private reconnectNodes(
    edges: Edge[],
    controlPointToRemove: Node,
    nodeConnectionMap: Map<
      string,
      {
        node: Node;
        bundles: Bundle[];
      }
    >,
  ): void {
    const nodeEntries = Array.from(nodeConnectionMap.entries());

    for (let i = 0; i < nodeEntries.length; i++) {
      const [sourceNodeId, sourceEntry] = nodeEntries[i];

      for (let j = i + 1; j < nodeEntries.length; j++) {
        const [targetNodeId, targetEntry] = nodeEntries[j];

        const matchingBundles = this.getMatchingBundles(sourceEntry.bundles, targetEntry.bundles);

        if (matchingBundles.length > 0) {
          this.combineSegments(edges, sourceNodeId, targetNodeId, controlPointToRemove, matchingBundles);
        }
      }
    }
  }

  /**
   * Get bundles that match between two sets of bundles.
   * @param sourceBundles
   * @param targetBundles
   * @private
   */
  private getMatchingBundles(sourceBundles: Bundle[], targetBundles: Bundle[]): Bundle[] {
    const targetBundleIds = new Set(targetBundles.map((bundle) => bundle.id));
    return sourceBundles.filter((sourceBundle) => targetBundleIds.has(sourceBundle.id));
  }

  /**
   * Combine segments between two nodes.
   * @param edges
   * @param sourceId
   * @param targetId
   * @param controlPointToRemove
   * @param bundles
   * @private
   */
  private combineSegments(
    edges: Edge[],
    sourceId: UUID,
    targetId: UUID,
    controlPointToRemove: Node,
    bundles: Bundles,
  ): void {
    const { source, target } = normalizeSourceTargetIds(sourceId, targetId);

    if (isBreakoutPointNode(controlPointToRemove)) {
      // Find the measurement edge for the source and control point
      const m1 = findMeasurementEdge(edges, sourceId, controlPointToRemove.id);
      // Find the measurement edge for the target and control point
      const m2 = findMeasurementEdge(edges, targetId, controlPointToRemove.id);
      // Add the measurement data
      if (m1 && m2) {
        createAndAddMeasurementEdge(edges, source, target, {
          ...m1.data,
          measurement: (m1.data?.measurement || 0) + (m2.data?.measurement || 0),
        });
        createAndAddSegmentEdge(edges, source, target, {
          measurementSource: source,
          measurementTarget: target,
          bundles,
        });
      }
    }

    if (isLayoutPointNode(controlPointToRemove)) {
      createAndAddSegmentEdge(edges, source, target, {
        bundles,
        measurementSource: source,
        measurementTarget: target,
      });
    }
  }
}
