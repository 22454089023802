import { Box, BoxProps, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { Design } from '@senrasystems/senra-ui';
import Confirm from '@web/components/Confirm.tsx';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props extends BoxProps {
  design: Design;
  onOpen: () => void;
  onEdit: () => void;
  onClone: () => void;
  onDelete: (itemId: string) => void;
}

export const DesignActions = ({ design, onOpen, onEdit, onClone, onDelete, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={IconButton} icon={<SlOptionsVertical />} aria-label="Design Actions" />
        <Portal>
          <MenuList>
            <MenuItem onClick={onOpen}>Open Design</MenuItem>
            <MenuItem onClick={onEdit}>Edit Details</MenuItem>
            <MenuItem onClick={onClone}>Clone</MenuItem>
            <Confirm
              title="Are you sure?"
              body=" This will delete the design. This action cannot be undone."
              onConfirm={() => onDelete(design.id)}
            >
              <MenuItem color="red.300">Delete</MenuItem>
            </Confirm>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};
