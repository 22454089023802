import { SpliceNodeType } from '../../components/nodes/SpliceNode/SpliceNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { usePartFlagNoteActions } from './flagNotes/usePartFlagNoteActions.tsx';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for splice actions.
 */
export const useSpliceActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const splice = element as SpliceNodeType;

  const { flagNoteActions } = usePartFlagNoteActions(closeMenu, splice);

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Splice,
  } as MenuActions;
};
