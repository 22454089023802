import { Edge, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { createSegmentNoteEdge } from '../../../graph/EdgeFactory';
import { createNoteGroupNode } from '../../../graph/NodeFactory';
import { getMidpoint } from '../../../utils/graph';
import { getNoteGroupNodeForElement, getNotePosition } from '../../../utils/notes';
import { useFlagNoteActions } from './useFlagNoteActions';

// Returns flag note actions for a segment edge.
export const useSegmentFlagNoteActions = (closeMenu: VoidFunction, targetEdge?: Edge) => {
  const { getNodes } = useReactFlow();

  const noteGroupNodeForElement = getNoteGroupNodeForElement(getNodes(), targetEdge?.id);

  const createNoteGroupNodeAndEdge = useCallback(() => {
    if (!targetEdge) {
      return {};
    }

    const midpoint = getMidpoint(getNodes(), targetEdge);

    const newNode = createNoteGroupNode(getNotePosition(midpoint), { targetElementId: targetEdge.id });
    const newEdge = createSegmentNoteEdge(newNode.id, newNode.id, { segmentId: targetEdge.id });

    return { newNode, newEdge };
  }, [getNodes, targetEdge]);

  return useFlagNoteActions(closeMenu, { noteGroupNodeId: noteGroupNodeForElement?.id, createNoteGroupNodeAndEdge });
};
