import { UUID } from '@senrasystems/senra-ui';
import { isValidUUID } from '@web/common/lib/string-utils/validation.ts';
import { useParams } from 'react-router-dom';

/**
 * Hook to get the Design ID from the URL params. Validates, and ensures it's a UUID type.
 * @returns {UUID}
 */
export const useDesignId = (): UUID => {
  const { designId } = useParams<{ designId: string }>();

  if (!designId) {
    throw new Error('Design ID is missing');
  }

  if (!isValidUUID(designId)) {
    throw new Error('Invalid Design ID');
  }

  return designId as UUID;
};
