import { ButtonProps } from '@chakra-ui/react';
import { DesignPart, Part } from '@senrasystems/senra-ui';
import { MouseEventHandler } from 'react';

import { useCreateAlternatePartMutation } from '../../../api/queries.ts';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignId } from '../../../hooks/useDesignId.tsx';

interface Props extends ButtonProps {
  part: Part;
  preferredPart: DesignPart;
}

/**
 * AddAlternateButton component displays a button to add a part as an alternate.
 * @param part
 * @param preferredPart
 * @param rest
 * @constructor
 */
const AddAlternateButton = ({ part, preferredPart, ...rest }: Props) => {
  const designId = useDesignId();

  // Mutation to add an alternate part
  const { mutate: createAlternatePart, isPending } = useCreateAlternatePartMutation();

  // Handle adding an alternate part
  const handleAddAlternatePart: MouseEventHandler<HTMLButtonElement> = () => {
    createAlternatePart({ designId, partId: preferredPart.partData.id, alternatePartId: part.id });
  };

  // Don't show button if the part is already an alternate
  return part.partNumber === preferredPart.partData.partNumber ? null : (
    <AddIconButton
      aria-label="Add alternate part"
      onClick={handleAddAlternatePart}
      bg="gray.300"
      _hover={{ color: 'white', bg: 'blue.500' }}
      isLoading={isPending}
      {...rest}
    />
  );
};

export default AddAlternateButton;
