import { Icon, IconProps, useToken } from '@chakra-ui/react';
import { ComponentType, useId } from 'react';

import { PatternProps } from '../../types/PatternProps.ts';
import { PatternType } from '../../types/PatternType.ts';
import { HeatShrink } from './Patterns/HeatShrink.tsx';
import { Kevlar } from './Patterns/Kevlar.tsx';
import { Nylon } from './Patterns/Nylon.tsx';
import { ShieldBraid } from './Patterns/ShieldBraid.tsx';
import { Tape } from './Patterns/Tape.tsx';

export const patternComponentMap: Record<PatternType, ComponentType<PatternProps>> = {
  [PatternType.HEAT_SHRINK]: HeatShrink,
  [PatternType.KEVLAR]: Kevlar,
  [PatternType.NYLON]: Nylon,
  [PatternType.SHIELD_BRAID]: ShieldBraid,
  [PatternType.TAPE]: Tape,
};

interface Props extends IconProps {
  width?: number;
  height?: number;
  colorScheme?: string;
  patternType: PatternType;
}

/**
 * DynamicPatternIcon component defines a pattern based on the patternType prop.
 * @param width
 * @param height
 * @param colorScheme
 * @param patternType
 * @param rest
 * @constructor
 */
export const DynamicPatternIcon = ({ width = 20, height = 12, colorScheme = 'gray', patternType, ...rest }: Props) => {
  // Generate a unique ID for the pattern
  const id = useId();

  // Use Chakra's color system
  const [color, lightColor] = useToken('colors', [colorScheme?.toLowerCase(), `${colorScheme?.toLowerCase()}.200`]);
  const PatternComponent = patternComponentMap[patternType];

  return (
    <Icon viewBox={`0 0 ${width} ${height}`} width={`${width}px`} height={`${height}px`} {...rest}>
      {/* Define the pattern in <defs> */}
      <defs>
        <PatternComponent id={id} color={color} lightColor={lightColor} />
      </defs>

      {/* Use the pattern as fill */}
      <rect width={width} height={height} fill={`url(#${id}-pattern)`} />
    </Icon>
  );
};
