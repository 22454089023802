import { Text } from '@chakra-ui/react';
import { Tool, ToolType, TurretHead } from '@senrasystems/senra-ui';

import ToolsLibraryDashboard, { CustomColumnDef } from '../../components/ToolsLibraryDashboard.tsx';
import TurretHeadFields from './TurretHeadFields.tsx';

const columns: CustomColumnDef[] = [
  {
    header: 'Name',
    renderFn: (tool: Tool) => {
      const turretHead = tool as TurretHead;
      return <Text>{turretHead.name}</Text>;
    },
  },
];

const TurretHeadsDashboard = () => (
  <ToolsLibraryDashboard
    additionalFieldsComponent={TurretHeadFields}
    buttonText="Turret Head"
    columnDefs={columns}
    routeName="turret-heads"
    title="Turret Heads"
    toolType={ToolType.TURRETHEAD}
  />
);

export default TurretHeadsDashboard;
