import {
  getWireData,
  isCableDesignPart,
  ResolvedConnection,
  ResolvedConnectionPoint,
  WireData,
} from '@senrasystems/senra-ui';

export interface ConductorData {
  connectionPoint: ResolvedConnectionPoint;
  cableName: string;
  isShielded: boolean;
  shieldType: string;
  wireData: WireData;
  twistingGroup: string | null;
}

/**
 * If a conductor is a wire within a cable, map information about the cable and the wire so that it can be used to
 * create twisting, and shielding groups.
 * @param connection
 */
export const mapConnectionToConductorData = (connection: ResolvedConnection): ConductorData | null => {
  const { conductor } = connection;

  if (!conductor || !isCableDesignPart(conductor.designPart)) return null;

  const wireData = getWireData(conductor);
  if (!wireData) return null;

  const { designPart, original } = conductor;
  const { partData } = designPart;

  return {
    connectionPoint: conductor,
    cableName: designPart.name,
    isShielded: partData.shielded,
    shieldType: partData.shieldType,
    twistingGroup: partData.partWires[original.position].twistingGroup || null,
    wireData,
  };
};
