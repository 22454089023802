import { useAuth0 } from '@auth0/auth0-react';
import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import Hero from '../components/Hero';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    async function doLogoutAsync() {
      await logout({ logoutParams: { returnTo: window.location.origin } });
    }

    doLogoutAsync().catch(console.error);
  }, [logout]);

  return (
    <Hero>
      <Text py={6}>Please wait while we sign you out…</Text>
    </Hero>
  );
};

export default Logout;
