import { Box, Center, Flex } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { BsCircle } from 'react-icons/bs';

interface Props {
  number: number;
}

export const NumberCircle = forwardRef(({ number, ...props }: Props, ref: React.Ref<HTMLDivElement>) => (
  <Flex ref={ref} position="relative" justify="center" align="flex-end" {...props}>
    <Box as={BsCircle} size={32} color="gray.300" />
    <Center position="absolute" top={0} right={0} bottom={0} left={0} color="gray.500">
      {number}
    </Center>
  </Flex>
));

NumberCircle.displayName = 'NumberCircle';
