import { Box, Button, FormLabel } from '@chakra-ui/react';
import { Part, PartConfidence } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { useController, useFormContext } from 'react-hook-form';

export interface Props {
  editing?: boolean;
}

const PartConfidenceDisplay = ({ editing = true }: Props) => {
  const { control } = useFormContext<Part>();
  const { field } = useController({ name: 'confidence', control });

  return (
    <EditableFormText editing={editing} label="Data Confidence" value={field.value}>
      <Box>
        <FormLabel>Data Confidence</FormLabel>
        <Box>
          <Button
            as="option"
            cursor="pointer"
            width={175}
            borderRadius={0}
            value={PartConfidence.LOW}
            colorScheme={field.value === PartConfidence.LOW ? 'red' : undefined}
            onClick={() => field.onChange(PartConfidence.LOW)}
            isDisabled={!editing}
          >
            {PartConfidence.LOW}
          </Button>
          <Button
            as="option"
            cursor="pointer"
            width={175}
            borderRadius={0}
            value={PartConfidence.HIGH}
            colorScheme={field.value === PartConfidence.HIGH ? 'blue' : undefined}
            onClick={() => field.onChange(PartConfidence.HIGH)}
            isDisabled={!editing}
          >
            {PartConfidence.HIGH}
          </Button>
        </Box>
      </Box>
    </EditableFormText>
  );
};

export default PartConfidenceDisplay;
