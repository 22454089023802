import { FormControl, FormLabel, Stack } from '@chakra-ui/react';

import PartWireTable from '../../components/PartWireTable.tsx';

interface Props {
  editing: boolean;
}

const PigtailFields = ({ editing }: Props) => {
  return (
    <Stack spacing={6}>
      <FormControl>
        <FormLabel>Conductors</FormLabel>
        <PartWireTable isEditing={editing} isPigtailParent />
      </FormControl>
    </Stack>
  );
};

export default PigtailFields;
