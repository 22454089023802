import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { AlternateDesignPart } from '@senrasystems/senra-ui';
import { MouseEvent } from 'react';
import { BiSolidXCircle } from 'react-icons/bi';

import { useDeleteAlternatePartMutation } from '../../../api/queries.ts';
import { useDesignId } from '../../../hooks/useDesignId.tsx';

interface Props extends IconButtonProps {
  part: AlternateDesignPart;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

/**
 * RemoveAlternateButton component displays a button to add a part as an alternate.
 * @param part
 * @param onClick
 * @param rest
 * @constructor
 */
const RemoveAlternateButton = ({ part, onClick, ...rest }: Props) => {
  const designId = useDesignId();

  // Mutation to delete a part
  const { mutate: deleteAlternatePart, isPending } = useDeleteAlternatePartMutation();

  // Handle removing an alternate part
  const handleDeleteAlternatePart = (event: MouseEvent<HTMLButtonElement>) => {
    deleteAlternatePart({ designId, partId: part.id });
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <IconButton
      icon={<BiSolidXCircle />}
      variant="unstyled"
      type="submit"
      onClick={handleDeleteAlternatePart}
      fontSize="1.5em"
      boxSize="auto"
      color="gray.500"
      _hover={{ color: 'red.400' }}
      isLoading={isPending}
      {...rest}
    />
  );
};

export default RemoveAlternateButton;
