import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

const WarningBox = ({ text, children }: { text: string; children: ReactNode }) => {
  return (
    <Box borderWidth={1} p={4} mb={4} borderColor="yellow.500" maxW="36rem">
      <Text color="yellow.500" fontSize="sm" mb={4}>
        <Text as="span" fontWeight="bold">
          WARNING:{' '}
        </Text>
        {text}
      </Text>
      {children}
    </Box>
  );
};

export default WarningBox;
