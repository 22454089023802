import { Flex, Heading } from '@chakra-ui/react';

const DrawingSectionTitle = () => (
  <Flex
    boxShadow="0 -2px 4px rgba(0, 0, 0, 0.2)"
    borderBottom="1px solid"
    borderBottomColor="gray.300"
    alignItems="center"
    px={4}
    py={5}
  >
    <Heading as="h2" size="md">
      Drawing
    </Heading>
  </Flex>
);

export default DrawingSectionTitle;
