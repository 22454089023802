import { Box, Flex, HStack, IconButton, Tooltip, UnorderedList, useColorMode } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { FaMoon, FaSun } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';

import { AuthenticatedTemplate, EmployeeTemplate } from '../auth/RenderTemplates';
import Logo from '../svg/Logo';

const LogoWrapper = () => (
  <NavLink to={RouteNames.ROOT}>
    <Box mx={4}>
      <Logo />
    </Box>
  </NavLink>
);

export default function TopbarNav() {
  const { colorMode, toggleColorMode } = useColorMode();
  const location = useLocation();
  const isStaging = import.meta.env.VITE_ENVIRONMENT === 'staging';
  const isLightMode = colorMode === 'light';

  return (
    <Flex
      padding="7px"
      alignItems="center"
      width="full"
      borderBottom="1px"
      borderColor={isLightMode ? 'gray.300' : 'gray.700'}
      backgroundColor={isStaging ? 'red.200' : isLightMode ? 'gray.200' : 'gray.800'}
    >
      <LogoWrapper />
      <Flex flex={1} fontSize="sm">
        <UnorderedList listStyleType="none">
          <HStack spacing={8} mt={4} mb={4}>
            <AuthenticatedTemplate>
              <li>
                <NavbarLink text="Orders" to={RouteNames.ORDERS.INDEX} />
              </li>
            </AuthenticatedTemplate>
            <EmployeeTemplate>
              <li>
                <NavbarLink text="Designs" to={RouteNames.DESIGNS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Tools Library" to={RouteNames.TOOLS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Parts Library" to={RouteNames.PARTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Tenants" to={RouteNames.TENANTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Users" to={RouteNames.USERS.INDEX} />
              </li>
            </EmployeeTemplate>
          </HStack>
        </UnorderedList>
      </Flex>
      <Flex fontSize="sm">
        <AuthenticatedTemplate>
          <HStack>
            {!location.pathname.startsWith(RouteNames.DESIGNS.INDEX) && (
              <Tooltip label={`Toggle ${colorMode === 'light' ? 'dark' : 'light'} mode`}>
                <IconButton aria-label="Color mode toggle" variant="ghost" onClick={toggleColorMode}>
                  {colorMode === 'light' ? <FaMoon size={16} /> : <FaSun size={16} />}
                </IconButton>
              </Tooltip>
            )}
            <NavLink to={RouteNames.SIGN_OUT}>
              <Tooltip label="Sign Out">
                <IconButton
                  aria-label="Sign Out"
                  icon={<MdLogout size={18} />}
                  variant="ghost"
                  data-cy="signout-button"
                />
              </Tooltip>
            </NavLink>
          </HStack>
        </AuthenticatedTemplate>
      </Flex>
    </Flex>
  );
}

const NavbarLink = ({ text, to }: { text: string; to: string; showActive?: boolean }) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => {
        return {
          opacity: isActive ? 1 : 0.5,
          fontSize: '10pt',
        };
      }}
    >
      {text}
    </NavLink>
  );
};
