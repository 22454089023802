import { Stack } from '@chakra-ui/react';

import Title from '../../../components/Title';
import CreateOrderForm from './CreateOrderForm';

const CreateOrder = () => {
  return (
    <Stack p={8} spacing={8}>
      <Title title="Create New Order" />
      <CreateOrderForm />
    </Stack>
  );
};

export default CreateOrder;
