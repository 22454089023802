import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const ErrorText = ({ children }: Props) => (
  <Text fontSize="xs" color="red.300" mt={2}>
    {children}
  </Text>
);

export default ErrorText;
