import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { createNoteGroupNode } from '../../../graph/NodeFactory';
import { MenuPosition } from '../useActionsBase';
import { useFlagNoteActions } from './useFlagNoteActions';

// Returns flag note actions for the pane.
export const usePaneFlagNoteActions = (closeMenu: VoidFunction, menuPosition: MenuPosition) => {
  const { screenToFlowPosition } = useReactFlow();

  const createNoteGroupNodeAndEdge = useCallback(() => {
    const newPosition = screenToFlowPosition(menuPosition);
    const newNode = createNoteGroupNode(newPosition);

    return { newNode };
  }, [screenToFlowPosition, menuPosition]);

  return useFlagNoteActions(closeMenu, { createNoteGroupNodeAndEdge, shouldCloseOnAdd: true });
};
