import { Text } from '@chakra-ui/react';
import { Part, PartType, Passive } from '@senrasystems/senra-ui';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';

const PassivesDashboard = () => {
  const methods = useForm<Passive>({ defaultValues: { type: PartType.PASSIVE } });
  const columns: CustomColumnDef[] = [
    {
      header: 'Passive Type',
      renderFn: (part: Part) => {
        const passive = part as Passive;
        return <Text>{passive.type}</Text>;
      },
    },
    {
      header: 'Connections',
      renderFn: (part: Part) => {
        const passive = part as Passive;
        return <Text>{passive.connections.length}</Text>;
      },
    },
    {
      header: 'Passive Value',
      renderFn: (part: Part) => {
        const passive = part as Passive;
        return <Text>{passive.passiveValue}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.PASSIVE} columnDefs={columns} />
    </FormProvider>
  );
};

export default PassivesDashboard;
