import { ToolType } from '@senrasystems/senra-ui';

import ToolsLibraryDashboard from '../../components/ToolsLibraryDashboard.tsx';

const ToolBodiesDashboard = () => (
  <ToolsLibraryDashboard
    toolType={ToolType.TOOLBODY}
    buttonText="Tool Body"
    routeName="tool-bodies"
    title="Tool Bodies"
  />
);

export default ToolBodiesDashboard;
