import { Button, Container, Flex, VStack } from '@chakra-ui/react';
import { OrderDeclineTypes, OrderDeclineTypesKey, OrderStateEvent } from '@web/common/lib';
import { sentenceCaseToSnakeCase } from '@web/common/lib/string-utils/string-utils';
import { RouteNames } from '@web/consts/routeNames.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { apiTransitionOrder } from '../../api/admin-api';
import { successToast } from '../../common/toasts';
import SelectInput from '../../components/form/SelectInput';
import TextAreaInput from '../../components/form/TextAreaInput';
import Loading from '../../components/Loading';
import { useOrder } from '../../queries/orders';
import AdminPage from './AdminPage';

enum UrlParamKeys {
  OrderId = 'o',
}

interface FormValues {
  order: {
    declineType: OrderDeclineTypes;
    declineNotes: string;
  };
}

interface Props {
  orderId: string;
}

const DeclineOrderForm = ({ orderId }: Props) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      order: {
        declineType: OrderDeclineTypes.Default,
        declineNotes: '',
      },
    },
  });

  const onConfirm = async () => {
    await handleSubmit(onSubmit)();
    navigate(RouteNames.ORDERS.INDEX);
  };

  const validateReason = (reason: string) => {
    return reason && !(reason === 'Default');
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const transition = {
      orderId,
      event: OrderStateEvent.Decline,
      declineType: data.order.declineType,
      declineNotes: data.order.declineNotes,
    };
    const response = await apiTransitionOrder(transition);
    if (response) {
      navigate(RouteNames.ORDERS.INDEX);
      successToast('Order state updated');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <SelectInput
            label="Type *"
            options={Object.keys(OrderDeclineTypes).map((key) => (
              <option key={key} value={sentenceCaseToSnakeCase(OrderDeclineTypes[key as OrderDeclineTypesKey])}>
                {OrderDeclineTypes[key as OrderDeclineTypesKey]}
              </option>
            ))}
            {...register('order.declineType', { validate: validateReason })}
          />
          <TextAreaInput
            label="Notes *"
            placeholder="Add some notes..."
            maxLength={1000}
            rows={2}
            {...register('order.declineNotes', { required: true })}
          />
        </VStack>
      </form>
      <Button isLoading={isSubmitting} isDisabled={!isValid} onClick={onConfirm}>
        Save
      </Button>
    </div>
  );
};

export default function AdminDeclineOrder() {
  const [urlParams] = useSearchParams();
  const orderId = urlParams.get(UrlParamKeys.OrderId) ?? '';
  const { data: order } = useOrder(orderId);

  if (!order) {
    return <Loading message="Please wait while we load your order…" />;
  }

  return (
    <AdminPage title={`Decline Order ${order.orderNumber}`}>
      <Flex direction="column" width="full" overflow="auto">
        <Container marginLeft="32px" marginTop="40px">
          <DeclineOrderForm orderId={orderId} />
        </Container>
      </Flex>
    </AdminPage>
  );
}
