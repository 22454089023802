import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';

interface Props {
  value: string;
  onChange: (updatedValue: string) => void;
}

export const PartSearchInput = ({ value, onChange }: Props) => (
  <InputGroup>
    <InputLeftElement pointerEvents="none" height="100%" alignItems="center">
      <Icon as={BiSearch} boxSize="1.75rem" />
    </InputLeftElement>
    <Input
      id="find-part-input"
      type="text"
      placeholder="Type to find a part..."
      height="60px"
      fontSize="1.2em"
      variant="unstyled"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="off"
    />
  </InputGroup>
);
