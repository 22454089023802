import { BaseEdge, EdgeProps, getStraightPath } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';

/**
 * ConductorEdge component representing a conductor between two nodes.
 * @constructor
 * @param props
 */
export const ConductorEdge = (props: EdgeProps) => {
  const edgeStyle = {
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 2,
  };

  const [edgePath] = getStraightPath(props);

  return <BaseEdge path={edgePath} style={edgeStyle} />;
};
