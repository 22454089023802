import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';

import {
  defaultMeasurementEdgeData,
  MeasurementEdgeType,
} from '../../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { GraphOperation } from '../../graph/Operations.ts';
import { EdgeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for measurement actions.
 */
export const useMeasurementActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const edge = element as MeasurementEdgeType;

  // Data properties
  const flipped = edge?.data?.flipped ?? false;
  const distanceFromEdge =
    edge?.data?.distanceFromEdge.toString() ?? defaultMeasurementEdgeData.distanceFromEdge.toString();

  const onFlip = () => {
    const params: GraphOperation = { type: 'UpdateEdgeData', params: { edgeId: edge.id, data: { flipped: !flipped } } };
    executeOperation(params);
    closeMenu();
  };

  const onExtend = (distance: number) => {
    const params: GraphOperation = {
      type: 'UpdateEdgeData',
      params: { edgeId: edge.id, data: { distanceFromEdge: distance } },
    };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onFlip}>Flip</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuOptionGroup defaultValue={distanceFromEdge} title="Step out:" type="radio">
        <MenuItemOption onClick={() => onExtend(60)} value="60">
          1x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(120)} value="120">
          2x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(180)} value="180">
          3x
        </MenuItemOption>
      </MenuOptionGroup>
      <MenuDivider />
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: EdgeType.Measurement,
  } as MenuActions;
};
