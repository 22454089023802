import { Alert, AlertIcon, Button, HStack, Select, Stack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { toTitleCase } from '@web/common/lib/string-utils/string-utils.ts';
import Loading from '@web/components/Loading';
import { RouteNames } from '@web/consts/routeNames.ts';
import { ordersCacheKey, useOrders } from '@web/queries/orders.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import FilterBox from '../../../components/FilterBox';
import Paginate from '../../../components/Paginate';
import Title from '../../../components/Title';
import OrdersList from './OrdersList';

const EmptyOrdersList = () => (
  <Alert status="info" mb={8}>
    <AlertIcon />
    Looks like your order list is empty at the moment. Want to place an order? Click the New Order button to the right!
  </Alert>
);

const NewOrderButton = () => (
  <NavLink to={RouteNames.ORDERS.NEW}>
    <Button>New Order</Button>
  </NavLink>
);

export default function OrderDashboard() {
  const { data: user } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const [query, setQuery] = useState(searchParams.get('q') ?? '');
  const [stateFilter, setStateFilter] = useState(searchParams.get('t') ?? '');
  const { isLoading, data: { orders, meta } = { orders: [], meta: {} } } = useOrders(
    page,
    searchParams.get('q'),
    searchParams.get('t'),
  );
  const queryClient = useQueryClient();
  const stateOptions = [
    'all',
    'pending_quote',
    'quoted',
    'accepted',
    'in_procurement',
    'procured',
    'delivered',
    'declined',
    'quote_expired',
  ];

  const debouncedSearchOrders = useMemo(
    () =>
      debounce((value: string) => {
        if (value) {
          searchParams.set('q', value);
        } else {
          searchParams.delete('q');
        }
        searchParams.delete('page');
        setSearchParams(searchParams);
      }, 500),
    [searchParams, setSearchParams],
  );

  const handleFilterQuery = (text: string) => {
    setQuery(text);
    debouncedSearchOrders(text);
  };

  const handleFilterState = (state: string) => {
    setStateFilter(state);
    if (state === 'all') {
      searchParams.delete('t');
    } else {
      searchParams.set('t', state);
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  if (!user) {
    return <Loading message="Loading user…" />;
  }

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  return (
    <Stack padding={8} spacing={8}>
      <Title title="Orders">
        <NewOrderButton />
      </Title>
      <HStack gap={6}>
        <Select w={160} value={stateFilter} onChange={(e) => handleFilterState(e.target.value)}>
          {stateOptions.map((state) => (
            <option key={state} value={state}>
              {toTitleCase(state)}
            </option>
          ))}
        </Select>
        <FilterBox placeholder="Type to filter orders" value={query} onChange={handleFilterQuery} />
        <Button
          onClick={() => {
            handleFilterQuery('');
            handleFilterState('all');
          }}
        >
          Clear filters
        </Button>
      </HStack>
      <div>
        {isLoading && <Loading message="Loading orders…" />}
        {!isLoading && (
          <>
            {orders.length === 0 && query.length === 0 && <EmptyOrdersList />}

            <Stack spacing={6}>
              <OrdersList
                orders={orders}
                employee={user.employee}
                reloadOrders={() => queryClient.invalidateQueries({ queryKey: ordersCacheKey(page) })}
                handleFilterState={handleFilterState}
                handleFilterQuery={handleFilterQuery}
              />
              <Paginate onPageChange={handlePageClick} selectedPage={parseInt(page) - 1} pageCount={meta.totalPages} />
            </Stack>
          </>
        )}
      </div>
    </Stack>
  );
}
