import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack, Icon, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

interface BaseAccordionItemProps {
  title: string | ReactNode;
  children: ReactNode;
  icon: IconType;
  borderColor?: string;
  bgColor?: string;
}

export const BaseAccordionItem = ({
  title,
  children,
  icon,
  borderColor = 'gray.100',
  bgColor = 'white',
}: BaseAccordionItemProps) => (
  <AccordionItem borderColor={borderColor} mb={2}>
    <AccordionButton bg={bgColor} _hover={{ bg: `${bgColor}.100` }} borderRadius="md" p={4}>
      <HStack flex="1" spacing={4}>
        <Icon as={icon} />
        {typeof title === 'string' ? <Text fontWeight="medium">{title}</Text> : title}
      </HStack>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel pb={4}>{children}</AccordionPanel>
  </AccordionItem>
);
