import { Badge, IconButton, Td, Tr, useDisclosure } from '@chakra-ui/react';
import { Part, PartType, PartWire, Wire } from '@senrasystems/senra-ui';
import { apiGetParts } from '@web/api/parts-api.ts';
import CreatableTypeAheadSelectInput from '@web/components/form/CreatableTypeAheadSelectInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { SingleValue } from 'chakra-react-select';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { MdCancel } from 'react-icons/md';

import { useParts } from '../api/queries.ts';
import PartsLibraryModal, { Mode } from './PartsLibraryModal.tsx';

interface Props {
  partWireIndex: number;
  isEditing: boolean;
  remove: (index: number) => void;
  update: (index: number, value: PartWire) => void;
  afterRemoveCallback: () => void;
  isPigtailParent?: boolean;
}

const PartWireRow = ({ partWireIndex, isEditing, remove, update, afterRemoveCallback, isPigtailParent }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getValues, register } = useFormContext<Part>();
  const { data } = useParts(PartType.WIRE);

  const wires = (data?.data ?? []) as Wire[];

  const formMethods = useForm<Wire>();

  const toOption = (wire: Wire) => ({
    label: wire.partNumber,
    value: wire,
  });

  const defaultOptions = wires.map(toOption);

  const partWire = getValues(`partWires.${partWireIndex}`);

  if (!partWire || partWire._destroy) return null;

  const optionValue = partWire.wire ? toOption(partWire.wire) : null;

  const handleSearchWires = async (inputValue: string) => {
    const res = await apiGetParts(PartType.WIRE, inputValue);
    if (res) {
      return res.data.map((wire) => ({ label: wire.partNumber, value: wire }));
    }
    return [];
  };

  const handleCreateWire = (inputValue: string) => {
    let partNumber = inputValue;
    let cableOnlyWire = false;

    if (!inputValue) {
      partNumber = `${getValues('partNumber')}-WIRE-${partWireIndex + 1}`;
      cableOnlyWire = true;
    }

    formMethods.reset({ partNumber, cableOnlyWire });
    onOpen();
    return Promise.resolve(null);
  };

  const handleOnChange = (newOption: SingleValue<{ label: string; value: Part }>) => {
    if (newOption) {
      update(partWireIndex, { ...partWire, wire: newOption.value as Wire, wireId: newOption.value.id });
    }
  };

  const handleRemovePartWire = () => {
    if (partWire.wireId) {
      update(partWireIndex, { ...partWire, _destroy: true });
    } else {
      remove(partWireIndex);
    }
    afterRemoveCallback();
  };

  return (
    <>
      {isOpen && (
        <Tr>
          <Td>
            <FormProvider {...formMethods}>
              <PartsLibraryModal
                partType={PartType.WIRE}
                mode={Mode.CREATE}
                isOpen={isOpen}
                onClose={onClose}
                onPartCreated={(wire) => wire && handleOnChange(toOption(wire as Wire))}
              />
            </FormProvider>
          </Td>
        </Tr>
      )}
      <Tr>
        <Td>
          <Badge>{partWire.position}</Badge>
        </Td>
        <Td w="250px">
          <EditableFormText
            editing={isEditing}
            label={null}
            value={optionValue?.label ?? 'error: wire not in database'}
          >
            <CreatableTypeAheadSelectInput
              label=""
              placeholder="Search for wire"
              editing={isEditing}
              value={optionValue}
              defaultOptions={defaultOptions}
              isMulti={false}
              onChange={handleOnChange}
              onCreateOption={handleCreateWire}
              loadOptions={handleSearchWires}
              isValidNewOption={() => true}
              createOptionPosition="first"
              formatCreateLabel={(inputValue) => `Create ${inputValue || 'cable only wire'}`}
            />
          </EditableFormText>
        </Td>
        <Td>
          <EditableFormText editing={isEditing} label={null} value={getValues(`partWires.${partWireIndex}.wire.gauge`)}>
            <TextInput formControlProps={{ isDisabled: true }} {...register(`partWires.${partWireIndex}.wire.gauge`)} />
          </EditableFormText>
        </Td>
        <Td>
          <EditableFormText
            editing={isEditing}
            label={null}
            value={(getValues(`partWires.${partWireIndex}.wire.colors`) || []).map((color) => color).join(', ') || ''}
          >
            <TextInput
              formControlProps={{ isDisabled: true }}
              {...register(`partWires.${partWireIndex}.wire.colors`)}
            />
          </EditableFormText>
        </Td>
        {isPigtailParent && (
          <Td>
            <EditableFormText
              editing={isEditing}
              label={null}
              value={getValues(`partWires.${partWireIndex}.signalName`) ?? ''}
            >
              <TextInput
                formControlProps={{ isDisabled: !isEditing }}
                {...register(`partWires.${partWireIndex}.signalName`)}
              />
            </EditableFormText>
          </Td>
        )}
        <Td>
          <EditableFormText
            editing={isEditing}
            label={null}
            value={getValues(`partWires.${partWireIndex}.twistingGroup`) ?? ''}
          >
            <TextInput
              type="number"
              formControlProps={{ isDisabled: !isEditing }}
              {...register(`partWires.${partWireIndex}.twistingGroup`)}
            />
          </EditableFormText>
        </Td>
        <Td>
          <IconButton
            isRound
            variant="ghost"
            aria-label="Remove Wire"
            icon={<MdCancel size="1em" />}
            visibility={isEditing ? 'initial' : 'hidden'}
            onClick={handleRemovePartWire}
          />
        </Td>
      </Tr>
    </>
  );
};

export default PartWireRow;
