import { Box, HStack, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { apiEditOrderComment } from '../../api/order-comments-api';
import { OrderCommentDto } from '../../common/lib';
import CancelButton from '../buttons/CancelButton';
import SubmitButton from '../buttons/SubmitButton';
import TextareaWithAttachment from '../form/TextareaWithAttachment';

export function OrderCommentBody({ data }: { data: OrderCommentDto }) {
  return (
    <Box py={1} whiteSpace="pre-wrap">
      {data.body}
    </Box>
  );
}

export function OrderCommentEditingBody({
  orderId,
  data,
  setIsEditing,
  reloadComments,
}: {
  orderId: string;
  data: OrderCommentDto;
  setIsEditing: (v: boolean) => void;
  reloadComments: () => void;
}) {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm({ defaultValues: { body: '', attachments: [] } });

  useEffect(() => {
    reset({
      body: data.body,
    });
  }, [data.body, reset]);

  async function onSubmit(formData: FieldValues) {
    const comment = await apiEditOrderComment(orderId, data.id, {
      body: formData.body,
      attachments: formData.attachments,
      internal: data.internal,
    });
    if (comment) {
      setIsEditing(false);
      reloadComments();
    }
  }

  function onCancel() {
    setIsEditing(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={3}>
        <TextareaWithAttachment<{
          body: string;
          attachments: never[];
        }>
          required
          control={control}
          register={register}
          textareaName="body"
          textareaPlaceholder="Comment…"
        />
        <HStack>
          <SubmitButton text="Comment" size="sm" disabled={!isValid} loading={isSubmitting} />
          <CancelButton size="sm" onClick={onCancel} />
        </HStack>
      </Stack>
    </form>
  );
}
