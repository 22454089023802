import { Badge, BadgeProps, HStack, Text } from '@chakra-ui/layout';

interface Props {
  partNumber: string;
  partRevision: string;
  badgeProps?: BadgeProps;
}

export const PartRevisionLabel = ({ partNumber, partRevision, badgeProps }: Props) => (
  <HStack>
    <Text>{partNumber}</Text>
    <Badge bg="orange.500" color="white" {...badgeProps}>
      REV {partRevision}
    </Badge>
  </HStack>
);
