import {
  Cable,
  Connector,
  Contact,
  GenericPart,
  Part,
  PartType,
  Passive,
  Pigtail,
  Splice,
  Wire,
} from '@senrasystems/senra-ui';

import { minMaxAwgFormatter } from '../../../../Parts-Library/components/MinMaxAwgFields.tsx';

const PartSummary = ({ part }: { part: Part }) => {
  // Always display the manufacturer
  const values = [];
  if (part.manufacturers?.length) {
    values.push(part.manufacturers[0].name);
  }

  // Display additional values based on the part type
  switch (part.type) {
    case PartType.CABLE: {
      const cable = part as Cable;
      if (cable.jacket) {
        values.push(cable.jacket);
      }
      if (cable.shielded) {
        values.push(cable.shielded);
      }
      if (cable.shieldType) {
        values.push(cable.shieldType);
      }
      if (cable.partWires.length) {
        values.push(`${cable.partWires.length} Wires`);
      }
      break;
    }
    case PartType.CONNECTOR: {
      const connector = part as Connector;
      if (connector.insertArrangement?.cavities.length) {
        if (connector.gender) {
          values.push(connector.gender);
        }
        if (connector.insertArrangement.cavities.length) {
          values.push(`${connector.insertArrangement.cavities.length} Cavities`);
        }
      }
      break;
    }
    case PartType.CONTACT: {
      const contact = part as Contact;
      if (contact.gender) {
        values.push(contact.gender);
      }
      if (contact.gaugeMinAwg && contact.gaugeMaxAwg) {
        values.push(`${minMaxAwgFormatter(contact)} AWG`);
      }
      if (contact.termination) {
        values.push(contact.termination);
      }
      break;
    }
    case PartType.GENERIC: {
      const generic = part as GenericPart;
      if (generic.subtype) {
        values.push(generic.subtype);
      }
      if (generic.genericPartUnit) {
        values.push(generic.genericPartUnit);
      }
      break;
    }
    case PartType.PASSIVE: {
      const passive = part as Passive;
      if (passive.subtype) {
        values.push(passive.subtype);
      }
      if (passive.passiveValue) {
        values.push(passive.passiveValue);
      }
      if (passive.connections.length) {
        values.push(`${passive.connections.length} Connections`);
      }
      break;
    }
    case PartType.PIGTAIL: {
      const pigtail = part as Pigtail;
      if (pigtail.partWires) {
        values.push(`${pigtail.partWires.length} Wires`);
      }
      break;
    }
    case PartType.SPLICE: {
      const splice = part as Splice;
      if (splice.subtype) {
        values.push(splice.subtype);
      }
      if (splice.gaugeMinAwg && splice.gaugeMaxAwg) {
        values.push(`${minMaxAwgFormatter(splice)} AWG`);
      }
      break;
    }
    case PartType.WIRE: {
      const wire = part as Wire;
      if (wire.gauge) {
        values.push(wire.gauge);
      }
      break;
    }
    default:
      break;
  }
  return values.join(', ');
};

export default PartSummary;
