import { StackProps, Text, VStack } from '@chakra-ui/react';
import { DesignPart } from '@senrasystems/senra-ui';

interface Props extends StackProps {
  designPart?: DesignPart;
  isSource?: boolean;
}

/**
 * ConnectorLabel component. This component is used to display the name and part number of a design part.
 * @param designPart
 * @param isSource
 * @param rest
 * @constructor
 */
export const ConnectorLabel = ({ designPart, isSource = true, ...rest }: Props) => {
  if (!designPart) {
    return null;
  }

  return (
    <VStack
      width="full"
      alignItems={isSource ? 'flex-start' : 'flex-end'}
      color="gray.900"
      fontFamily="mono"
      p={2}
      {...rest}
    >
      <Text fontWeight="bold">{designPart.name}</Text>
      <Text>{designPart.partData.partNumber}</Text>
    </VStack>
  );
};
