import { FormControl, FormLabel } from '@chakra-ui/react';
import {
  AsyncCreatableProps,
  AsyncCreatableSelect,
  GroupBase,
  OnChangeValue,
  SelectInstance,
} from 'chakra-react-select';
import { useEffect, useRef, useState } from 'react';

interface ICustomAsyncProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends AsyncCreatableProps<Option, IsMulti, Group> {
  label: string;
  editing: boolean;
  inputValue?: string;
  onInputChange?: (inputValue: string) => void;
  onCreateOption: (
    inputValue: string,
  ) => Promise<OnChangeValue<Option, IsMulti>> | OnChangeValue<Option, IsMulti> | null;
  onChange: (value: OnChangeValue<Option, IsMulti>) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

const CreatableTypeAheadSelectInput = <Option, IsMulti extends boolean>({
  label,
  editing,
  value,
  defaultOptions,
  isMulti,
  onChange,
  onCreateOption,
  loadOptions,
  ...props
}: ICustomAsyncProps<Option, IsMulti, GroupBase<Option>>) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectRef = useRef<SelectInstance<Option, IsMulti> | null>(null);

  useEffect(() => {
    if (editing) {
      // Focus the select component when needed
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }, 0);
    }
  }, [editing]);

  const handleCreate = async (inputValue: string) => {
    setIsLoading(true);
    if (onCreateOption && onChange) {
      const newOption = await onCreateOption(inputValue);
      if (newOption) {
        onChange(newOption);
      }
    }
    setIsLoading(false);
  };

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <AsyncCreatableSelect
        ref={selectRef}
        placeholder={props.placeholder}
        isMulti={isMulti}
        cacheOptions
        isDisabled={isLoading || !editing}
        isLoading={isLoading}
        defaultOptions={defaultOptions}
        value={value}
        onChange={(newValue: OnChangeValue<Option, IsMulti>) => onChange && onChange(newValue)}
        onCreateOption={handleCreate}
        loadOptions={loadOptions} // TODO: Add debounce. Had weird behavior so removed for now.
        {...props}
      />
    </FormControl>
  );
};

export default CreatableTypeAheadSelectInput;
