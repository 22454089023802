import { Box, Checkbox, FormControl, FormHelperText, FormLabel, HStack, Select, Stack, Text } from '@chakra-ui/react';
import { Overwrap } from '@senrasystems/senra-ui';
import { chakraColors } from '@web/common/colors';
import EditableFormText from '@web/components/form/EditableFormText';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import OverwrapBrick from '../../components/OverwrapBrick';

interface Props {
  editing: boolean;
}

interface OverwrapType {
  name: string;
  svg: string;
}

const OverwrapFields = ({ editing }: Props) => {
  const { control, register } = useFormContext<Overwrap>();
  const { field: consumable } = useController({ name: 'consumable', control });
  const { data: config } = useConfiguration();

  const subtype = useWatch({ control, name: 'subtype' });
  const color = useWatch({ control, name: 'color' });

  if (!config) return <Loading />;
  const pattern = config.parts.overwrap.types.find((type: OverwrapType) => type.name === subtype)?.svg || '';
  const patternId =
    config.parts.overwrap.types
      .find((type: OverwrapType) => type.name === subtype)
      ?.name.toLowerCase()
      .replace(/\s+/g, '-') + '-pattern' || '';

  return (
    <Stack spacing={6}>
      <FormControl>
        <Checkbox
          isDisabled={!editing}
          isChecked={consumable.value}
          onChange={(e) => consumable.onChange(e.target.checked)}
          aria-label="consumable"
        >
          Consumable
        </Checkbox>
        <FormHelperText>
          <Text>
            A consumable part&#39;s BOM quantity will be &#34;As Required&#34; rather than the sum of assigned segment
            lengths.
          </Text>
        </FormHelperText>
      </FormControl>
      <HStack spacing={10}>
        <Box flex="1">
          <EditableFormText editing={editing} label="Subtype" value={subtype}>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select isDisabled={!editing} {...register('subtype')}>
                {config.parts.overwrap.types.map((type: OverwrapType) => {
                  return (
                    <option key={type.name} value={type.name}>
                      {type.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex="1">
          <EditableFormText editing={editing} label="Color" value={color}>
            <FormControl>
              <FormLabel>Color</FormLabel>
              <Select isDisabled={!editing} {...register('color')}>
                {Object(chakraColors).map((val: string) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      {color && subtype && <OverwrapBrick color={color} svgPattern={pattern} patternId={patternId} />}
    </Stack>
  );
};

export default OverwrapFields;
