import { conductorSymbolOffset } from '@web/apps/Design/features/Schematic/types/positioning.ts';
import { EdgeProps } from '@xyflow/react';

type Size = 1 | 2 | 3 | 4 | 5;

const sizeMap: Record<Size, number> = {
  1: 8,
  2: 10,
  3: 11,
  4: 14,
  5: 18,
};

/**
 * Creates a vertical pill shaped path.
 * @param sourceX
 * @param sourceY
 * @param sourcePosition
 * @param targetX
 * @param targetY
 * @param targetPosition
 * @param size
 * @param offset
 */
export function getShieldingPath(
  { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition }: EdgeProps,
  size: Size = 3,
  offset: number = conductorSymbolOffset,
): [string, number, number, number, number] {
  const positionOffsetX = sourcePosition === 'left' && targetPosition === 'left' ? offset : -offset;

  // Determine direction and midpoints
  const minY = Math.min(sourceY, targetY);
  const maxY = Math.max(sourceY, targetY);

  // Make the pill width relative to height but fairly thin
  const width = sizeMap[size];

  // Calculate vertical padding
  const verticalPadding = sizeMap[size];
  const topY = minY - verticalPadding;
  const bottomY = maxY + verticalPadding;

  // Ensure the shape is centered between the points
  const centerX = (sourceX + targetX) / 2 + positionOffsetX;
  const leftX = centerX - width;
  const rightX = centerX + width;

  // Create a pill shape using arcs for top/bottom and straight lines for sides
  const path = [
    // Start at top center
    `M ${centerX} ${topY}`,
    // Arc to right side
    `A ${width} ${width} 0 0 1 ${rightX} ${topY + width}`,
    // Straight line down right side
    `L ${rightX} ${bottomY - width}`,
    // Arc to bottom center
    `A ${width} ${width} 0 0 1 ${centerX} ${bottomY}`,
    // Arc to left side
    `A ${width} ${width} 0 0 1 ${leftX} ${bottomY - width}`,
    // Straight line up left side
    `L ${leftX} ${topY + width}`,
    // Arc back to top center
    `A ${width} ${width} 0 0 1 ${centerX} ${topY}`,
  ].join(' ');

  const labelX = centerX;
  const labelY = (topY + bottomY) / 2;
  const offsetX = width * 0.25;
  const offsetY = 0;

  return [path, labelX, labelY, offsetX, offsetY];
}
