import { ReactNode } from 'react';
import { FieldValues, FormProvider, useForm, UseFormProps } from 'react-hook-form';

import { errorToast, successToast } from '../../common/toasts';

interface Props<T extends FieldValues> {
  defaultValues: UseFormProps<T>['defaultValues'];
  onSubmit: (data: T) => Promise<T | undefined>;
  onClose: () => void;
  children: ReactNode;
  successToastMsg: string;
}

const ModalContentForm = <T extends FieldValues>({
  defaultValues,
  onSubmit,
  onClose,
  successToastMsg,
  children,
}: Props<T>) => {
  const methods = useForm<T>({ defaultValues: defaultValues });

  const submitHandler = (data: T) => {
    onSubmit(data)
      .then((response) => {
        if (response) {
          methods.reset(response);
          successToast(successToastMsg);
          onClose();
        }
      })
      .catch((e) => {
        errorToast(e.message);
        methods.reset({} as T, { keepValues: true });
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          await methods.handleSubmit(submitHandler)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default ModalContentForm;
