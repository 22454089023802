import { EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';
import { getTwistingPath } from '../../utils/getTwistingPath.ts';

/**
 * TwistingEdge component representing twisting between two conductors.
 * @constructor
 * @param props
 */
export const TwistingEdge = (props: EdgeProps) => {
  const edgeStyle = {
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1.5,
    fill: defaultSchematicConfig.style.backgroundColor,
  };

  const [edgePath] = getTwistingPath(props);

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
