import { Text } from '@chakra-ui/react';
import { GenericPart, Part, PartType } from '@senrasystems/senra-ui';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';

const GenericDashboard = () => {
  const methods = useForm<GenericPart>({ defaultValues: { type: PartType.GENERIC } });

  const columns: CustomColumnDef[] = [
    {
      header: 'Generic Part Type',
      renderFn: (part: Part) => {
        const generic = part as GenericPart;
        return <Text>{generic.subtype}</Text>;
      },
    },
    {
      header: 'Generic Part Unit',
      renderFn: (part: Part) => {
        const generic = part as GenericPart;
        return <Text textTransform="capitalize">{generic.genericPartUnit}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.GENERIC} columnDefs={columns} />
    </FormProvider>
  );
};

export default GenericDashboard;
