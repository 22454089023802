import { Tool, ToolType } from '@senrasystems/senra-ui';
import React from 'react';
import { UseFormProps } from 'react-hook-form';

import SkinnyModalForm from '../../../components/form/SkinnyModalForm.tsx';
import { useCreateTool, useUpdateTool } from '../api/queries.ts';
import ToolFields from './ToolFields.tsx';
import { Mode } from './ToolsLibraryDashboard.tsx';
import ToolsLibraryModalHeader from './ToolsLibraryModalHeader.tsx';

interface Props {
  defaultValues: UseFormProps<Tool>['defaultValues'];
  title: string;
  toolType: ToolType;
  mode: Mode;
  isOpen: boolean;
  onClose: () => void;
  additionalFieldsComponent?: React.JSX.ElementType;
  enableEditMode: () => void;
  onDelete?: () => void;
}

const ToolsLibraryModal = ({
  defaultValues,
  title,
  toolType,
  mode,
  isOpen,
  onClose,
  additionalFieldsComponent,
  enableEditMode,
  onDelete,
}: Props) => {
  const createTool = useCreateTool(toolType);
  const updateTool = useUpdateTool(toolType);
  const isEditing = mode !== Mode.READ;

  const handleSubmitTool = async (tool: Tool): Promise<Tool | undefined> => {
    const api = mode === Mode.UPDATE ? updateTool : createTool;

    const newTool = await api.mutateAsync({
      id: tool.id,
      type: toolType,
      part_number: tool.partNumber || '',
      notes: tool.notes,
      manufacturer_id: tool.manufacturer?.id || '',
      subtype: tool.subtype,
      documents_attributes: tool.documents,
      name: tool.name,
    });

    return newTool as Tool;
  };

  return (
    <SkinnyModalForm
      defaultValues={defaultValues}
      isOpen={isOpen}
      onSubmit={handleSubmitTool}
      onClose={onClose}
      header={<ToolsLibraryModalHeader title={title} />}
      successToastMsg="Tool saved successfully"
      minWidth={['90%', '90%', '90%', '65%', '50%']}
      closeButton
    >
      <ToolFields
        isEditing={isEditing}
        mode={mode}
        additionalFieldsComponent={additionalFieldsComponent}
        enableEditMode={enableEditMode}
        onDelete={onDelete}
      />
    </SkinnyModalForm>
  );
};

export default ToolsLibraryModal;
