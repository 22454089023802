import { getLoopbackPath } from '@web/apps/Design/features/Schematic/utils/getLoopbackPath.ts';
import { BaseEdge, EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';

/**
 * LoopbackEdge component representing a conductor between two nodes.
 * @constructor
 * @param props
 */
export const LoopbackEdge = (props: EdgeProps) => {
  const edgeStyle = {
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 2,
  };

  const [edgePath] = getLoopbackPath(props);

  return <BaseEdge path={edgePath} style={edgeStyle} />;
};
