import { isCableDesignPart, ResolvedConnection, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { naturalCompare } from '@web/common/comparison.ts';

/**
 * Compare two connections for sorting SchematicData.
 * @param a
 * @param b
 */
export const compareConnections = (a: ResolvedConnection, b: ResolvedConnection): number => {
  // Connections without conductors
  if (!a.conductor && !b.conductor) return 0; // Both have no conductor, keep order
  if (!a.conductor) return 1; // Move `a` (no conductor) to the bottom
  if (!b.conductor) return -1; // Move `b` (no conductor) to the bottom

  // Sort by type (cables above wires)
  const aIsCable = isCableDesignPart(a.conductor.designPart);
  const bIsCable = isCableDesignPart(b.conductor.designPart);
  if (aIsCable && !bIsCable) return -1; // Cables come first
  if (!aIsCable && bIsCable) return 1; // Wires come after cables

  // Sort by conductor design part name using naturalCompare
  const conductorNameComparison = compareDesignPartNames(a.conductor.designPart.name, b.conductor.designPart.name);
  if (conductorNameComparison !== 0) return conductorNameComparison;

  // Sort by twisting group and position
  const twistingGroupComparison = compareTwistingGroups(a.conductor, b.conductor);
  if (twistingGroupComparison !== 0) return twistingGroupComparison;

  // Default to no change in order if all criteria are equal
  return 0;
};

/**
 * Compare two design part names using naturalCompare.
 * @param aDesignPartName
 * @param bDesignPartName
 */
const compareDesignPartNames = (aDesignPartName: string, bDesignPartName: string) => {
  // Use naturalCompare to compare design part names
  const nameComparison = naturalCompare(aDesignPartName, bDesignPartName);
  if (nameComparison !== 0) return nameComparison;

  // Return 0 if names are equal
  return 0;
};

/**
 * Compare two conductors by twisting group and position.
 * @param aConductor
 * @param bConductor
 */
const compareTwistingGroups = (aConductor: ResolvedConnectionPoint, bConductor: ResolvedConnectionPoint): number => {
  let aTwistingGroup = 0;
  let bTwistingGroup = 0;

  if (isCableDesignPart(aConductor.designPart)) {
    aTwistingGroup = Number(
      aConductor.designPart.partData.partWires?.[aConductor.original.position]?.twistingGroup || 0,
    );
  }
  if (isCableDesignPart(bConductor.designPart)) {
    bTwistingGroup = Number(
      bConductor.designPart.partData.partWires?.[bConductor.original.position]?.twistingGroup || 0,
    );
  }

  // Push 0 to the bottom
  if (aTwistingGroup === 0 && bTwistingGroup !== 0) return 1;
  if (bTwistingGroup === 0 && aTwistingGroup !== 0) return -1;

  // Regular ascending order
  if (aTwistingGroup < bTwistingGroup) return -1;
  if (aTwistingGroup > bTwistingGroup) return 1;

  // Sort by conductor position within the same twisting group
  const aPosition = aConductor.original.position;
  const bPosition = bConductor.original.position;
  if (aPosition < bPosition) return -1;
  if (aPosition > bPosition) return 1;

  // If both are equal
  return 0;
};
