import { Box, Stack } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from '../../components/Title';
import { useCurrentUser } from '../../queries/users';

interface Props {
  title: string;
  actions?: ReactElement[];
  children: ReactNode;
}

const AdminPage = ({ title, children, actions = [] }: Props) => {
  const { isLoading, data: user } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.employee) {
      navigate(RouteNames.ORDERS.INDEX);
    }
  }, [user, navigate]);

  if (isLoading) {
    return null;
  }

  return (
    <Stack p={8} spacing={8}>
      <Title title={title}>
        {actions.map((action, index) => (
          <div key={index}>{action}</div>
        ))}
      </Title>
      <Box>{children}</Box>
    </Stack>
  );
};

export default AdminPage;
