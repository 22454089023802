import { acceptsContacts, PartType, uniqueDesignPartsByPartNumber, UUID } from '@senrasystems/senra-ui';
import { useMemo } from 'react';

import { Option } from '../../../components/SearchableDropdown.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';

/**
 * useDesignPartContactOptions hook returns a list of destination contact options for a given connection point. Valid
 * destination contact options are contacts on the destination design part. If the destination is not set, the list is
 * ungrouped contacts.
 */
export const useDesignPartContactOptions = (designPartId: UUID) => {
  const { designParts } = useDesignParts();
  const { connectionPointsById } = useConnections();

  const options = useMemo(() => {
    if (designPartId) {
      // If the design part id is passed in, show unique contacts on the destination part
      const destinationDesignPart = connectionPointsById[designPartId].designPart;
      const contacts = acceptsContacts(destinationDesignPart) ? (destinationDesignPart?.contacts ?? []) : [];
      const ungroupedContacts = designParts.filter((part) => part.partData.type === PartType.CONTACT);
      return uniqueDesignPartsByPartNumber([...contacts, ...ungroupedContacts]).map((contact) => ({
        label: contact.partData.partNumber,
        value: contact.id,
      }));
    } else {
      // If the there is no part id provided, show ungrouped contacts
      return designParts
        .filter((part) => part.partData.type === PartType.CONTACT)
        .map((contact): Option => ({ label: contact.partData.partNumber, value: contact.id }));
    }
  }, [designPartId, connectionPointsById, designParts]);

  return { options };
};
