import { Text } from '@chakra-ui/react';

import SearchableDropdown, { Option } from '../../../components/SearchableDropdown.tsx';

interface Props {
  isEditing: boolean;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
}

export const ConnectionItemDropdown = ({ isEditing, value, options, onChange }: Props) => {
  const selectedOption = options.find((opt) => opt.value === value) || null;

  return isEditing ? (
    <SearchableDropdown
      options={options || []}
      value={options?.find((opt) => opt.value === value) || null}
      onChange={(option) => onChange(option ? option.value : '')}
    />
  ) : (
    <Text>{selectedOption?.label}</Text>
  );
};
