import { BaseEdge, Edge, EdgeProps, getStraightPath } from '@xyflow/react';

import { defaultLayoutConfig } from '../../../config.ts';
import { useOverwrapPatterns } from '../../../hooks/useOverwrapPatterns.tsx';
import { Bundles, Overwraps } from '../../../types.ts';

export type SegmentEdgeData = {
  bundles: Bundles;
  overwraps: Overwraps;
  measurementSource?: string;
  measurementTarget?: string;
};

export const defaultSegmentEdgeData: SegmentEdgeData = {
  bundles: [],
  overwraps: [],
  measurementSource: undefined,
  measurementTarget: undefined,
};

export type SegmentEdgeType = Edge<SegmentEdgeData>;

/**
 * Segment edge component representing a cable, wire, or bundle between two nodes.
 * @constructor
 * @param props
 */
const SegmentEdge = (props: EdgeProps<SegmentEdgeType>) => {
  const { selected } = props;
  const { data = defaultSegmentEdgeData } = props;
  const { getPatternsForOutermostOverwrap } = useOverwrapPatterns();
  const { pattern, selectedPattern } = getPatternsForOutermostOverwrap(data.overwraps);

  // Define the color based on the selected state
  const color = selected ? defaultLayoutConfig.selectedEdgeColor : defaultLayoutConfig.edgeColor;

  // Define the stroke based on the selected state and overwrap
  const stroke =
    data.overwraps.length > 0 && pattern && selectedPattern
      ? selected
        ? `url('#${pattern.patternId}-pattern')`
        : `url('#${selectedPattern.patternId}-pattern')`
      : color;

  // Define the stroke width based on the overwrap and number of bundles
  const strokeWidth = data.overwraps.length > 0 ? 8 : data.bundles.length > 1 ? 4 : 2;

  // Define the style for the segment
  const segmentStyle = {
    stroke: stroke,
    strokeWidth: strokeWidth,
  };

  // Bundles > 1 have a border
  const borderStyle = {
    stroke: 'black',
    strokeWidth: 6,
    strokeOpacity: 0.3,
  };

  // Get the edge path for the segment
  const [edgePath] = getStraightPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY + 3, // Manually adjust the source Y to align with the centered node handle
    targetX: props.targetX,
    targetY: props.targetY + 3, // Manually adjust the source Y to align with the centered node handle
  });

  return (
    <>
      {/* SVG pattern defs for overwraps */}
      {pattern && selectedPattern && (
        <svg>
          <defs>
            {pattern.patternComponent}
            {selectedPattern.patternComponent}
          </defs>
        </svg>
      )}
      {/* Main edge */}
      <BaseEdge path={edgePath} interactionWidth={20} style={segmentStyle} />
      {/* Border edge for bundles */}
      {data.bundles.length > 1 && <BaseEdge path={edgePath} interactionWidth={20} style={borderStyle} />}
    </>
  );
};

export default SegmentEdge;
