import { Box, BoxProps, Center, HStack, Text } from '@chakra-ui/react';
import { ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { centerAbsoluteVertical } from '@web/apps/styles.ts';

import { defaultSchematicConfig } from '../../../config.ts';
import { getHandleId, getHandlePosition } from '../../../utils/handles.ts';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  connectionPoint: ResolvedConnectionPoint | null;
  conductor: ResolvedConnectionPoint | null;
  isSource?: boolean;
}

/**
 * Cavity component. This component is used to represent a cavity in the schematic. It has a connection handle on the
 * right side of the cavity if it is a source cavity, and on the left side if it is a destination cavity.
 * @param connectionPoint
 * @param conductorId
 * @param isSource
 * @param rest
 * @constructor
 */
export const Cavity = ({ connectionPoint, conductor, isSource = true, ...rest }: Props) => {
  const isHousing = connectionPoint?.name === 'Housing';
  const name = isHousing ? 'BS' : connectionPoint?.name;
  const handleId = connectionPoint && conductor ? getHandleId(connectionPoint, conductor, isSource, isHousing) : null;
  const handlePosition = getHandlePosition(isSource);

  return (
    <Center
      position="relative"
      h={defaultSchematicConfig.cavityHeight}
      w={defaultSchematicConfig.cavityWidth}
      bg={defaultSchematicConfig.cavityColor}
      borderRadius="md"
      {...rest}
    >
      <Text fontFamily="monospace" fontSize="sm">
        {name}
      </Text>
      <Box left={isSource ? '100%' : 'auto'} right={isSource ? 'auto' : '100%'} sx={centerAbsoluteVertical}>
        <HStack spacing={0}>
          {!isHousing && (
            <Box w={10} h={defaultSchematicConfig.conductorStrokeWidth} bg={defaultSchematicConfig.conductorColor} />
          )}
          {handleId && <ConnectionHandle id={handleId} type="source" position={handlePosition} />}
        </HStack>
      </Box>
    </Center>
  );
};
