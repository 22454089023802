import { EdgeProps, getSmoothStepPath, Position } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';

/**
 * HousingEdge component representing a path from a cable's shielding to a connector's housing.
 * @constructor
 * @param props
 */
export const HousingEdge = (props: EdgeProps) => {
  const edgeStyle = {
    strokeDasharray: '4 4',
    strokeLinecap: 'square' as const,
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1.5,
    fill: 'none',
  };

  const [edgePath] = getSmoothStepPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: Position.Bottom,
  });

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
