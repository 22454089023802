import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import ErrorText from './ErrorText';
import HintText from './HintText';

interface Props extends InputProps {
  label?: string;
  error?: string | null;
  hint?: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'date';
  inputRightElement?: React.ReactNode;
  onChangeCallback?: (...args: unknown[]) => void;
  formControlProps?: FormControlProps;
}

const TextInput = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    label,
    error,
    hint,
    type = 'text',
    inputRightElement = null,
    onChange,
    onChangeCallback,
    formControlProps,
    ...rest
  } = props;
  return (
    <FormControl {...formControlProps}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Input
          type={type}
          ref={ref}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(event);
            onChangeCallback?.(event);
          }}
          {...rest}
        />
        {!!inputRightElement && <InputRightElement>{inputRightElement}</InputRightElement>}
      </InputGroup>
      {error && <ErrorText>{error}</ErrorText>}
      {hint && <HintText>{hint}</HintText>}
    </FormControl>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
