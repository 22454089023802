import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';

import { useCurrentUser } from '../../queries/users';

interface Props {
  children: ReactNode;
}

export function AuthenticatedTemplate({ children }: Props) {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? children : null;
}

export function UnauthenticatedTemplate({ children }: Props) {
  const { isAuthenticated } = useAuth0();
  return !isAuthenticated ? children : null;
}

export function EmployeeTemplate({ children }: Props) {
  const { data: user } = useCurrentUser();
  const { isAuthenticated } = useAuth0();
  return isAuthenticated && user?.employee ? children : null;
}
