import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { BoxProps } from '@chakra-ui/react';
import { PartStatus } from '@senrasystems/senra-ui';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props<T extends { id: string; status?: PartStatus }> extends BoxProps {
  item: T;
  onView: (item: T) => void;
  onEdit: (item: T) => void;
  onRelease?: (item: T) => Promise<void>;
  onDelete: (itemId: string) => Promise<void>;
  onClone: (item: T) => void;
}

const DashboardRowActions = <T extends { id: string; status?: PartStatus }>({
  item,
  onView,
  onEdit,
  onRelease,
  onDelete,
  onClone,
  ...rest
}: Props<T>) => {
  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={IconButton} icon={<SlOptionsVertical />} />
        <Portal>
          <MenuList>
            <MenuItem onClick={() => onView(item)}>View</MenuItem>
            <MenuItem onClick={() => onEdit(item)}>Edit</MenuItem>
            {onRelease && item.status === PartStatus.DRAFT && (
              <MenuItem onClick={() => onRelease(item)}>Release</MenuItem>
            )}
            <MenuItem onClick={() => onClone(item)}>Clone</MenuItem>
            <MenuItem color="red.300" onClick={() => onDelete(item.id)}>
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default DashboardRowActions;
