import { Box, Center, ChakraProps, Heading, HStack, Spacer } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends ChakraProps {
  title: string | React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  children?: ReactNode;
}

const Title = ({ title, as = 'h1', size = 'lg', children, ...rest }: Props) => {
  return (
    <HStack verticalAlign={'center'} {...rest}>
      <Box>
        <Heading as={as} size={size}>
          {title}
        </Heading>
      </Box>
      <Spacer />
      <Center as={Box}>{children}</Center>
    </HStack>
  );
};

export default Title;
