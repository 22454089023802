import { ArrowRightIcon } from '@chakra-ui/icons';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Tool } from '@senrasystems/senra-ui';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  title: string;
}

const ToolsLibraryModalHeader = ({ title }: Props) => {
  const { control } = useFormContext<Tool>();
  const { field: partNumberField } = useController({ name: 'partNumber', control });

  const toolNumberColor = useColorModeValue('black', 'white');
  const toolTypeColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Flex alignItems="center">
      <Text as="span" color={toolTypeColor}>
        {title}
      </Text>
      <ArrowRightIcon mx={3} boxSize={4} color={toolTypeColor} />
      {partNumberField.value && (
        <Text as="span" color={toolNumberColor}>
          {partNumberField.value}
        </Text>
      )}
    </Flex>
  );
};

export default ToolsLibraryModalHeader;
