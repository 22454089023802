import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiGetManufacturers, apiPostManufacturer } from '@web/api/manufacturers-api.ts';

const manufacturersCacheKey = ['manufacturers'];

export const useManufacturers = (query?: string) => {
  return useQuery({
    queryKey: manufacturersCacheKey,
    queryFn: () => apiGetManufacturers(query).then((data) => data?.data),
  });
};

export const useCreateManufacturer = () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['post-manufacturer'],
    mutationFn: apiPostManufacturer,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: manufacturersCacheKey });
    },
  });
};
