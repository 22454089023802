import { Button, useBoolean, useToken } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { CollapseButton } from './components/CollapseButton';

interface Props {
  openWidth: number | string;
  isInitiallyOpen?: boolean;
  children: ReactNode;
}

export const CollapsibleSidebar = ({ openWidth, isInitiallyOpen = true, children }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useBoolean(isInitiallyOpen);
  const [isContentHidden, setIsContentHidden] = useState(!isSidebarOpen);

  const full = useToken('sizes', 'full');
  const closedWidth = useToken('spacing', 20);

  return (
    <motion.div
      style={{ height: full }}
      animate={{ width: isSidebarOpen ? openWidth : closedWidth }}
      onAnimationStart={() => setIsContentHidden(false)}
      onAnimationComplete={() => setIsContentHidden(!isSidebarOpen)}
    >
      <motion.div
        style={{ height: full, overflow: 'hidden' }}
        animate={{ opacity: isSidebarOpen ? 1 : 0 }}
        hidden={isContentHidden}
      >
        {children}
      </motion.div>
      <Button
        onClick={setIsSidebarOpen.on}
        borderRadius={0}
        size="xs"
        width="full"
        height="full"
        bgColor="transparent"
        hidden={!isContentHidden}
      />
      <CollapseButton onClick={setIsSidebarOpen.toggle} isOpen={isSidebarOpen} />
    </motion.div>
  );
};
