import { ResolvedConnection } from '@senrasystems/senra-ui';
import {
  getConductorLeftHandle,
  getConductorRightHandle,
  getStartPointHandle,
} from '@web/apps/Design/features/Schematic/utils/handles.ts';
import { Edge, Node } from '@xyflow/react';

import { Graph } from '../../../../types/reactFlow.ts';
import { defaultSchematicConfig } from '../config.ts';
import { CableGroup, ConnectionGroup, SchematicData } from '../hooks/useSchematicData.tsx';
import { EdgeType } from '../types/edges.ts';
import {
  createConductorEdges,
  createEdge,
  createHousingEdge,
  createSchematicNode,
  createTwistOrShieldEdges,
} from './factory.ts';

/**
 * Build the graph for the schematic. Connectors, cavities, conductors, and edge handles are rendered in the schematic
 * node. This function creates the SchematicNode and all the edges connecting the conductors and cavities. It also
 * creates the vertical edges for twisting and shielding groups.
 * @param schematicData
 * @param width
 */
export const buildGraph = (
  schematicData: SchematicData,
  width: number = Number(defaultSchematicConfig.width),
): Graph => {
  console.debug('schematicData', schematicData);

  const nodes: Node[] = [createSchematicNode(schematicData, width)];
  const edges: Edge[] = [];

  Object.entries(schematicData).forEach(([, { connectionGroup }]) => {
    if (isBetweenDifferentParts(connectionGroup)) {
      processConnectionGroup(connectionGroup, edges);
    } else {
      processLoopbackConnections(connectionGroup, edges);
    }
  });

  return { nodes, edges };
};

/**
 * Check if the connection group is between different parts.
 * @param connectionGroup
 */
const isBetweenDifferentParts = (connectionGroup: ConnectionGroup): boolean => {
  const { startPoint, endPoint } = connectionGroup;
  return startPoint?.designPart.id !== endPoint?.designPart.id;
};

/**
 * Process the connection group and add the edges to the graph.
 * @param connectionGroup
 * @param edges
 */
const processConnectionGroup = (connectionGroup: ConnectionGroup, edges: Edge[]) => {
  connectionGroup.rows.forEach(({ connection }) => {
    if (!connection.conductor) return;

    if (connection.conductor.name === 'Shield') {
      addHousingEdges(connection, edges);
    } else {
      addConductorEdges(connection, edges);
    }
  });

  processTwistingGroups(connectionGroup.twistingGroups, edges);
  processShieldingGroups(connectionGroup.shieldingGroups, edges);
};

/**
 * Process Twisting groups and add twisting and shielding edges to the graph.
 * @param twistingGroups
 * @param edges
 */
const processTwistingGroups = (twistingGroups: CableGroup[], edges: Edge[]) => {
  twistingGroups.forEach(({ isTwisted, isShielded, connectionPoints }) => {
    if (isTwisted && connectionPoints[0] !== connectionPoints[1]) {
      edges.push(...createTwistOrShieldEdges(EdgeType.Twisting, connectionPoints[0], connectionPoints[1]));
    }
    if (isShielded) {
      edges.push(...createTwistOrShieldEdges(EdgeType.Shielding, connectionPoints[0], connectionPoints[1]));
    }
  });
};

/**
 * Process Shielding groups and add double shielding edges to the graph.
 * @param shieldingGroups
 * @param edges
 */
const processShieldingGroups = (shieldingGroups: CableGroup[], edges: Edge[]) => {
  shieldingGroups.forEach(({ shieldType, connectionPoints }) => {
    if (shieldType === 'Double') {
      edges.push(...createTwistOrShieldEdges(EdgeType.DoubleShielding, connectionPoints[0], connectionPoints[1]));
    }
  });
};

/**
 * Process Loopback connections and add loopback edges to the graph.
 * @param connectionGroup
 * @param edges
 */
const processLoopbackConnections = (connectionGroup: ConnectionGroup, edges: Edge[]) => {
  connectionGroup.rows.forEach(({ connection, showConductor }) => {
    if (!connection.source || !connection.conductor) return;

    const edgeType = showConductor ? EdgeType.Conductor : EdgeType.Loopback;
    const handle = showConductor
      ? getConductorLeftHandle(connection.conductor)
      : getConductorRightHandle(connection.conductor);

    edges.push(createEdge(edgeType, getStartPointHandle(connection.source), handle));
  });
};

/**
 * Process Housing edges for Shield connections.
 * @param connection
 * @param edges
 */
const addHousingEdges = (connection: ResolvedConnection, edges: Edge[]) => {
  // Check if the source is Housing
  if (connection.source?.name === 'Housing') {
    const housingEdge = createHousingEdge(connection, true);
    if (housingEdge) {
      edges.push(housingEdge);
    }
  }

  // Check if the destination is Housing
  if (connection.destination?.name === 'Housing') {
    const housingEdge = createHousingEdge(connection, false);
    if (housingEdge) {
      edges.push(housingEdge);
    }
  }
};

/**
 * Process Conductor edges for non-Shield connections.
 * @param connection
 * @param edges
 */
const addConductorEdges = (connection: ResolvedConnection, edges: Edge[]) => {
  if (connection.source?.designPart.name !== connection.destination?.designPart.name) {
    edges.push(...createConductorEdges(connection));
  }
};
