import { EdgeProps } from '@xyflow/react';

/**
 * Creates a path from the source to target. The path starts at the source, moves horizontally to align with the
 * target's Y position, then loops back around to meet the target position.
 * @param sourceX
 * @param sourceY
 * @param targetX
 * @param targetY
 */
export function getLoopbackPath({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: EdgeProps): [string, number, number, number, number] {
  // Determine the vertical distance to define the pill height
  const height = Math.abs(targetY - sourceY);

  // Determine the loop's center Y: curve up or down based on the relative positions
  const isUpward = sourceY > targetY;
  const midY = isUpward ? sourceY - height : sourceY + height;

  // Create a pill shape using arcs and lines
  const path = [
    // Start at the source position
    `M ${sourceX} ${sourceY}`,
    // Travel horizontally to align with the target's Y position
    `L ${targetX} ${sourceY}`,
    // Arc upward or downward to the midpoint
    `A ${height / 2} ${height / 2} 0 0 ${isUpward ? 0 : 1} ${targetX} ${midY}`,
    // Arc back to align with the target position
    `A ${height / 2} ${height / 2} 0 0 ${isUpward ? 0 : 1} ${targetX} ${targetY}`,
  ].join(' ');

  // Label position
  const labelX = (sourceX + targetX) / 2;
  const labelY = midY;
  const offsetX = 0;
  const offsetY = height * 0.25;

  return [path, labelX, labelY, offsetX, offsetY];
}
