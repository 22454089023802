import { useColorMode } from '@chakra-ui/react';

import LogoDark from './assets/logo-dark.svg';
import LogoLight from './assets/logo-light.svg';

const Logo = ({ width = 30 }: { width?: number }) => {
  const { colorMode } = useColorMode();

  return <img src={colorMode === 'light' ? LogoLight : LogoDark} width={width} alt="Logo" />;
};

export default Logo;
