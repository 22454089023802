import { HStack, Spacer, Text } from '@chakra-ui/layout';

/**
 * Render the header for the wiring list.
 */
export const ConnectionListHeader = () => (
  <HStack
    fontWeight="500"
    color="gray.600"
    borderTop="1px solid"
    borderBottom="1px solid"
    borderColor="gray.300"
    px={4}
    py={2}
  >
    <Text flex={1}>Cavity</Text>
    <Text flex={1}>Destination</Text>
    <Text flex={1}>Conductor</Text>
    <Text flex={1}>Signal</Text>
    <Text flex={1}>Contact</Text>
    <Text flex={1}>Dest. Contact</Text>
    <Spacer />
    <Text w="100px" display="flex" justifyContent="flex-end"></Text>
  </HStack>
);
