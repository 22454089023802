import { Box, HStack, Text } from '@chakra-ui/layout';
import { DesignPart } from '@senrasystems/senra-ui';

import ConnectorIcon from '../../../components/ConnectorIcon';

interface Props {
  connector?: DesignPart;
}

export const ConnectorLabel = ({ connector }: Props) => (
  <HStack w="full" p={4}>
    <Text color="gray.900" fontWeight="bold" mr={2}>
      {connector?.name}
    </Text>
    <Box display="flex">
      <ConnectorIcon color="gray.500" w="8px" h="8px" />
    </Box>
    <Box display="flex">{connector?.partData.partNumber}</Box>
  </HStack>
);
