import { Tab, TabList, TabPanel, TabPanelProps, TabPanels, TabProps, Tabs, TabsProps } from '@chakra-ui/react';
import { featureFlag, useFeatureFlag } from '@web/hooks/useFeatureFlag.ts';

import BOM from '../features/BOM';
import { LayoutWithDrawer } from '../features/Layout';
import { SchematicWithDrawer } from '../features/Schematic';

const DesignTab = (props: TabProps) => (
  <Tab fontWeight="semibold" color={'gray.500'} _selected={{ color: 'gray.900' }} {...props} />
);

const DesignTabPanel = (props: TabPanelProps) => (
  <TabPanel p={0} display="flex" flexDirection="column" flex={1} width="full" {...props} />
);

export const DesignTabs = (props: Omit<TabsProps, 'children'>) => {
  const isSchematicEnabled = useFeatureFlag(featureFlag.Schematic);

  return (
    <Tabs variant="unstyled" height="full" display="flex" flexDirection="column" isLazy {...props}>
      <TabList justifyContent="center" p={4}>
        <DesignTab>BOM</DesignTab>
        <DesignTab>Layout</DesignTab>
        {isSchematicEnabled && <DesignTab>Schematic</DesignTab>}
      </TabList>
      <TabPanels display="flex" flex={1} minHeight={0}>
        <DesignTabPanel>
          <BOM />
        </DesignTabPanel>
        <DesignTabPanel>
          <LayoutWithDrawer />
        </DesignTabPanel>
        {isSchematicEnabled && (
          <DesignTabPanel>
            <SchematicWithDrawer />
          </DesignTabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
