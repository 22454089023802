import { ChakraProvider, ColorModeProvider, Flex, HStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import TopbarNav from '../../../components/navigation/TopbarNav.tsx';
import lightTheme from '../../themes.ts';
import { PartsSideNav } from '../index.ts';

const PartsLibraryLayout = () => {
  localStorage.setItem('chakra-ui-color-mode', 'light');

  return (
    <ChakraProvider theme={lightTheme}>
      <ColorModeProvider>
        <Flex direction="column" h="full" w="full">
          <TopbarNav />
          <HStack h="full" spacing={0} alignItems="flex-start">
            <PartsSideNav />
            <Outlet />
          </HStack>
        </Flex>
      </ColorModeProvider>
    </ChakraProvider>
  );
};

export default PartsLibraryLayout;
