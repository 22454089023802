import { ToolType } from '@senrasystems/senra-ui';

import ToolsLibraryDashboard from '../../components/ToolsLibraryDashboard.tsx';

const TorqueAdaptersDashboard = () => (
  <ToolsLibraryDashboard
    buttonText="Torque Adapter"
    routeName="torque-adapters"
    title="Torque Adapters"
    toolType={ToolType.TORQUEADAPTER}
  />
);

export default TorqueAdaptersDashboard;
