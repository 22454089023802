import { Box, HStack, StackProps, Text } from '@chakra-ui/react';
import { BOMItem } from '@senrasystems/senra-ui';
import { naturalCompare } from '@web/common/comparison.ts';

import FieldValueList from '../../../components/FieldValueList';
import { usePartData } from '../../../hooks/usePartData.tsx';
import RemoveAlternateButton from '../components/RemoveAlternateButton.tsx';

interface Props extends StackProps {
  bomItem: BOMItem;
  hasFocus?: boolean;
}

/**
 * BOMItemDetails component displays the details of a BOM item.
 * @param bomItem
 * @param hasFocus
 * @param rest
 * @constructor
 */
const BOMItemDetails = ({ bomItem, hasFocus = false, ...rest }: Props) => {
  const { part, usages, alternates } = bomItem;
  const data = usePartData(part);

  // A bit of a hack, but we need to use displayType from BOMItem.displayType, and I don't want to make the more generic
  // usePartData hook more complex to handle this one-off case. If there are more cases like this, we can refactor.
  data[0].value = bomItem?.displayType || data[0].value;

  const renderUsages = () => {
    if (usages.length === 0) {
      return <Text>No usages</Text>;
    }

    return usages
      .sort((a, b) => naturalCompare(a.name, b.name))
      .map((usage) => (
        <HStack key={usage.name} w="full" mb={2} justify="space-between" spacing={8}>
          <Text>{usage.name}</Text>
          <Text>
            {parseFloat(usage.designPart.quantity)} {bomItem?.unit}
          </Text>
        </HStack>
      ));
  };

  const renderAlternates = () => {
    if (alternates.length === 0) {
      return <Text>No alternates</Text>;
    }

    return alternates.map((alternate) => (
      <HStack key={alternate.id} mb={2}>
        <Text w="100px">{alternate.alternatePart.partNumber}</Text>
        <RemoveAlternateButton
          aria-label="Remove alternate part"
          part={alternate}
          visibility={hasFocus ? 'visible' : 'hidden'}
          opacity={hasFocus ? 1 : 0}
          transition="opacity .3s ease-in-out, color .3s ease-in-out"
        />
      </HStack>
    ));
  };

  return (
    <HStack alignItems="flex-start" gap={4} p={4} {...rest}>
      <HStack flex={2}>
        <FieldValueList data={data} fieldProps={{ w: '120px' }} />
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Usage</Box>
        <Box>{renderUsages()}</Box>
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Alternates</Box>
        <Box>{renderAlternates()}</Box>
      </HStack>
    </HStack>
  );
};
export default BOMItemDetails;
