import { Graph } from '../../../../types/reactFlow.ts';
import { AddControlPoint, AddControlPointOperation } from './operations/AddControlPoint.ts';
import { AddElementsToGraph, AddElementsToGraphOperation } from './operations/AddElementsToGraph.ts';
import { BuildLayout, BuildLayoutOperation } from './operations/BuildLayout.ts';
import { MergeBreakoutPoints, MergeBreakoutPointsOperation } from './operations/MergeBreakoutPoints.ts';
import { RemoveControlPoint, RemoveControlPointOperation } from './operations/RemoveControlPoint.ts';
import { UnmergeBundle, UnmergeBundleOperation } from './operations/UnmergeBundle.ts';
import { UpdateBuildNotes, UpdateBuildNotesOperation } from './operations/UpdateBuildNotes.ts';
import { UpdateEdgeData, UpdateEdgeDataOperation } from './operations/UpdateEdgeData.ts';
import { UpdateNodeData, UpdateNodeDataOperation } from './operations/UpdateNodeData.ts';
import { ValidateLayout, ValidateLayoutOperation } from './operations/ValidateLayout.ts';

// Abstract class for GraphOperations
export interface Operations<T extends GraphOperation> {
  // Flag to indicate if the operation requires a rebuild
  requiresRebuild?: boolean;

  // Execute the operation
  execute(graph: Graph, params: T): Graph;
}

// Define a union type for all operations
export type GraphOperation =
  | AddControlPointOperation
  | AddElementsToGraphOperation
  | BuildLayoutOperation
  | MergeBreakoutPointsOperation
  | RemoveControlPointOperation
  | UnmergeBundleOperation
  | UpdateNodeDataOperation
  | UpdateEdgeDataOperation
  | UpdateBuildNotesOperation
  | ValidateLayoutOperation;

// Define a type for the operation class
export type OperationClass<T extends GraphOperation> = new () => Operations<T>;

// Create a mapping from operation type to corresponding class
export const operationsMap: {
  [K in GraphOperation['type']]: OperationClass<Extract<GraphOperation, { type: K }>>;
} = {
  AddControlPoint,
  AddElementsToGraph,
  BuildLayout,
  MergeBreakoutPoints,
  RemoveControlPoint,
  UnmergeBundle,
  UpdateNodeData,
  UpdateEdgeData,
  UpdateBuildNotes,
  ValidateLayout,
} as const;
