import { Box } from '@chakra-ui/react';
import { ImLink } from 'react-icons/im';

/**
 * Link icon with hover effect.
 * @param fill
 * @constructor
 */
const Link = ({ fill }: { fill: string }) => (
  <Box _hover={{ opacity: 0.6 }} display="inline-block">
    <ImLink color={fill} />
  </Box>
);

export default Link;
