import { ResolvedConnection, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { compact } from 'lodash';

import { SchematicData } from '../hooks/useSchematicData.tsx';

/**
 * Create grouped connections from a list of sorted connections. Groups are based on the source and destination design
 * parts.
 * @param sortedConnections
 */
export const createGroupedConnections = (sortedConnections: ResolvedConnection[]): SchematicData => {
  const data: SchematicData = {};

  // Helper to initialize a group
  const initializeGroup = (key: string, source: ResolvedConnectionPoint, destination: ResolvedConnectionPoint) => {
    if (!data[key]) {
      data[key] = {
        connectionGroup: {
          id: key,
          startPoint: source,
          endPoint: destination,
          rows: [],
          twistingGroups: [],
          shieldingGroups: [],
        },
      };
    }
  };

  // Step 1: Group connections
  sortedConnections.forEach((connection) => {
    const { source, destination } = connection;

    if (!source || !destination) return;

    const key = compact([source?.designPart.name, destination?.designPart.name]).join(':');

    initializeGroup(key, source, destination);

    const group = data[key].connectionGroup;

    // Check if the connection is a loopback
    const isLoopback = connection.source?.designPart.name === connection.destination?.designPart.name;

    // Check across all groups for duplicate start points
    const showStartPoint =
      connection.source?.name === 'Housing' ? true : !pointExistsInAnyGroup(data, source.displayName);

    // Check only within the current group for duplicate end points
    const showEndPoint =
      connection.destination?.name === 'Housing'
        ? true
        : !group.rows.some((row) => row.connection.destination?.displayName === destination.displayName);

    // Check if the connection conductor ID exists in the current group
    const conductorExists = group.rows.some((row) => row.connection.conductor?.id === connection.conductor?.id);

    group.rows.push({
      connection,
      showStartPoint,
      showEndPoint: !isLoopback && showEndPoint,
      showConductor: !conductorExists,
    });
  });

  // Step 2: Insert Shield connections into matching groups
  sortedConnections.forEach((connection) => {
    if (connection.conductor?.name !== 'Shield') return;

    const shieldDesignPartName = connection.conductor?.designPart?.name;

    if (!shieldDesignPartName) return;

    Object.values(data).forEach(({ connectionGroup }) => {
      const rows = connectionGroup.rows;

      // Check if the group contains any connection with a matching conductor.designPart.name
      const matchingRows = rows.filter((row) => row.connection.conductor?.designPart?.name === shieldDesignPartName);

      // Check if the new connection's conductor.designPart.name already exists in the rows
      const isDuplicate = rows.some(
        (row) => row.connection.conductor?.displayName === connection.conductor?.displayName,
      );

      // Ensure the new criteria are met
      const matchesSource = connection.source?.designPart.name === connectionGroup.startPoint?.designPart.name;
      const matchesDestination = connection.destination?.designPart.name === connectionGroup.endPoint?.designPart.name;

      if (matchingRows.length > 0 && (matchesSource || matchesDestination) && !isDuplicate) {
        // Find the last occurrence of a matching connection
        const lastIndex = rows.lastIndexOf(matchingRows[matchingRows.length - 1]);

        // Insert the Shield connection after the last matching row
        rows.splice(lastIndex + 1, 0, {
          connection,
          showStartPoint: matchesSource,
          showEndPoint: matchesDestination,
          showConductor: true,
        });
      }
    });
  });

  return data;
};

/**
 * Check if a point exists in any group.
 * @param schematicData
 * @param displayName
 */
const pointExistsInAnyGroup = (schematicData: SchematicData, displayName: string) => {
  return Object.values(schematicData).some((groupData) =>
    groupData.connectionGroup.rows.some((row) => row.connection.source?.displayName === displayName),
  );
};
