import { Link, Stack } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';

const AttachmentsStack = ({ attachments }: { attachments: { id: string; url: string; filename: string }[] }) => {
  const bgColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Stack spacing={3}>
      {attachments.map((attachment) => (
        <Link isExternal key={attachment.id} href={attachment.url} bgColor={bgColor} w={96} px={2} py={1} fontSize="sm">
          {attachment.filename}
        </Link>
      ))}
    </Stack>
  );
};

export default AttachmentsStack;
