import { Box, HStack, MenuItemOption, MenuOptionGroup, Spinner, Text } from '@chakra-ui/react';
import { BuildNote, NoteType, UUID } from '@senrasystems/senra-ui';
import { Edge, Node } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { useUpdateBuildNoteMutation } from '../../../../../api/queries';
import { useDesignBuildNotes } from '../../../../../hooks/useDesignBuildNotes';
import { useDesignId } from '../../../../../hooks/useDesignId';
import { useLayoutBuilder } from '../../useLayoutBuilder';

interface FlagNoteActionsOptions {
  noteGroupNodeId?: UUID;
  createNoteGroupNodeAndEdge?: createNewElements;
  shouldCloseOnAdd?: boolean;
}

type createNewElements = () => { newNode?: Node; newEdge?: Edge };

// Gets the note group id to add the build note to, potentially creating a new note group.
const useGetNoteGroupId = (noteGroupNodeId?: UUID, createNoteGroupNodeAndEdge?: createNewElements) => {
  const { executeGraphOperation } = useLayoutBuilder();

  const getNoteGroupId = useCallback(() => {
    if (noteGroupNodeId) {
      return noteGroupNodeId;
    } else if (createNoteGroupNodeAndEdge) {
      const { newNode, newEdge } = createNoteGroupNodeAndEdge();

      executeGraphOperation({
        type: 'AddElementsToGraph',
        params: {
          newNodes: newNode ? [newNode] : undefined,
          newEdges: newEdge ? [newEdge] : undefined,
        },
      });

      return newNode?.id;
    }

    return null;
  }, [noteGroupNodeId, createNoteGroupNodeAndEdge, executeGraphOperation]);

  return getNoteGroupId;
};

export const useFlagNoteActions = (closeMenu: VoidFunction, options: FlagNoteActionsOptions = {}) => {
  const { noteGroupNodeId, createNoteGroupNodeAndEdge, shouldCloseOnAdd } = options;

  const designId = useDesignId();
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);
  const getNoteGroupId = useGetNoteGroupId(noteGroupNodeId, createNoteGroupNodeAndEdge);

  const { mutateAsync: updateNoteMutation, isPending } = useUpdateBuildNoteMutation();

  const flagNoteIdsForElement = useMemo(() => {
    if (noteGroupNodeId) {
      const flagNotesForElement = flagNotes.filter((note) => note.noteGroupNodeIds.includes(noteGroupNodeId));
      return flagNotesForElement.map(({ id }) => id);
    }

    return [];
  }, [flagNotes, noteGroupNodeId]);

  const handleToggleFlagNote = useCallback(
    async (flagNote: BuildNote) => {
      const noteGroupId = getNoteGroupId();

      if (!designId || !noteGroupId) {
        return;
      }

      const { id: noteId, noteGroupNodeIds } = flagNote;
      const isRemovingNote = noteGroupNodeIds.includes(noteGroupId);

      const updatedNoteGroupNodeIds = isRemovingNote
        ? noteGroupNodeIds.filter((id) => id !== noteGroupId)
        : [...noteGroupNodeIds, noteGroupId];

      // Update the note in the server
      await updateNoteMutation({ designId, noteId, buildNote: { noteGroupNodeIds: updatedNoteGroupNodeIds } });

      if (isRemovingNote || shouldCloseOnAdd) {
        closeMenu();
      }
    },
    [designId, updateNoteMutation, getNoteGroupId, closeMenu, shouldCloseOnAdd],
  );

  const flagNoteActions = useMemo(() => {
    if (flagNotes.length === 0) {
      return (
        <MenuOptionGroup title="Flag Notes">
          <MenuItemOption isDisabled>No Flag Notes</MenuItemOption>
        </MenuOptionGroup>
      );
    }

    return (
      <MenuOptionGroup
        // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
        title={
          <HStack as="span">
            <Box as="span">Flag Notes</Box>
            {isPending && (
              <Box as="span">
                <Spinner as="span" size="xs" />
              </Box>
            )}
          </HStack>
        }
        type="checkbox"
        value={flagNoteIdsForElement}
      >
        {flagNotes.map((note) => (
          <MenuItemOption key={note.id} value={note.id} onClick={() => handleToggleFlagNote(note)}>
            <Text
              width="200px"
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >{`${note.position} - ${note.body}`}</Text>
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    );
  }, [flagNotes, flagNoteIdsForElement, handleToggleFlagNote, isPending]);

  return { flagNoteActions };
};
