import ReactPaginate from 'react-paginate';

interface Props {
  onPageChange: (event: { selected: number }) => void;
  selectedPage: number;
  pageCount: number;
  pageRangeDisplayed?: number;
}

const Paginate = ({ onPageChange, selectedPage, pageCount, pageRangeDisplayed = 5 }: Props) => {
  if (pageCount <= 1) {
    return null;
  }
  return (
    <ReactPaginate
      breakLabel="…"
      onPageChange={onPageChange}
      forcePage={selectedPage}
      pageCount={pageCount}
      pageRangeDisplayed={pageRangeDisplayed}
      renderOnZeroPageCount={null}
      previousLabel="< Previous"
      nextLabel="Next >"
      containerClassName="pagination-container"
      activeClassName="active-pagination-item"
      disabledClassName="disabled-pagination-item"
      pageClassName="pagination-item"
      previousClassName={'pagination-item'}
      nextClassName={'pagination-item'}
    />
  );
};

export default Paginate;
