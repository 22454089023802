import { Box } from '@chakra-ui/react';
import { Handle, Position, XYPosition } from '@xyflow/react';

import { rotatePoint } from '../../../utils/geometry.ts';

interface Props {
  id?: string;
  angle: number;
  offsetPosition: XYPosition;
}

export const RotatingHandle = ({ id = '', angle, offsetPosition }: Props) => {
  const handlePosition = rotatePoint(offsetPosition, angle);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: `calc(50% + ${handlePosition.y}px)`,
        left: `calc(50% + ${handlePosition.x}px)`,
        transform: 'translate(-50%, -50%)',
        visibility: 'hidden',
      }}
    >
      <Handle id={id} type="source" position={Position.Top} />
    </Box>
  );
};
