import { Avatar, Badge, Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import dayjs, { Dayjs } from 'dayjs';
import { Link as LinkTo } from 'react-router-dom';

import { OrderCommentDto } from '../../common/lib';
import { getInitials } from '../../common/lib/string-utils/string-utils';
import { appNameShort } from '../../common/names';
import Link from '../svg/Link';
import Logo from '../svg/Logo';

const dateFormat = 'MMM D, YYYY h:mm A';

export function OrderCommentHeader({
  data,
  isOwnedComment,
  isEmployee,
}: {
  data: OrderCommentDto;
  isOwnedComment: boolean;
  isEmployee: boolean;
}) {
  const userName = data.user?.name ?? data.userId;
  const userMailto = data.user?.email ? `mailto:${data.user.email}` : '';

  const isEdited = !dayjs(data.createdAt).isSame(data.updatedAt);
  const createdAt = (dayjs(data.createdAt) as Dayjs).format(dateFormat);
  const updatedAt = (dayjs(data.updatedAt) as Dayjs).format(dateFormat);

  const employeeComment = data.user?.employee ?? false;
  const internalComment = data.internal ?? false;

  return (
    <HStack gap={4}>
      {isEmployee && (
        <Tooltip
          label={internalComment ? 'Only visible to Senra employees' : 'Visible to customer and Senra employees'}
          placement="right"
        >
          <Badge variant={internalComment ? 'ghost' : 'outline'} colorScheme={internalComment ? 'gray' : 'red'}>
            {internalComment ? 'Internal' : 'External'}
          </Badge>
        </Tooltip>
      )}
      <Avatar
        name={userName}
        getInitials={(name: string) => getInitials(name)}
        size="sm"
        {...(isOwnedComment
          ? {
              bg: 'purple.200',
              color: 'gray.900',
              fontWeight: 'bold',
            }
          : {
              bg: 'neutral',
              border: '1px',
              borderColor: 'gray.50',
              color: 'gray.50',
            })}
      />
      <Box opacity={0.6} _hover={{ opacity: 1 }}>
        <a href={userMailto} title={`Email ${userName} (right-click to copy)`}>
          {userName}
        </a>
      </Box>
      {employeeComment && (
        <Badge gap={1} borderRadius="2" display="flex" fontWeight="normal" textTransform="capitalize">
          <Logo width={13} />
          {appNameShort}
        </Badge>
      )}
      <Text opacity={0.6}>{createdAt}</Text>
      {isEdited && (
        <Tooltip bg="neutral" color="gray.50" label={`Edited at ${updatedAt}`} placement="top">
          <Text opacity={0.6} _hover={{ opacity: 1 }}>
            Edited
          </Text>
        </Tooltip>
      )}
      <Tooltip bg="neutral" color="gray.50" label="Link to comment" placement="top">
        <LinkTo to={`${window.location.pathname}?c=${data.id}`}>
          <Link fill="gray.50" />
        </LinkTo>
      </Tooltip>
    </HStack>
  );
}
