import { FormControl, FormLabel, HStack, Switch } from '@chakra-ui/react';
import { Backshell } from '@senrasystems/senra-ui';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

const BackshellFields = ({ editing }: Props) => {
  const { control } = useFormContext<Backshell>();
  const { field } = useController({ name: 'shielded', control });

  return (
    <HStack>
      <FormControl>
        <FormLabel>Shielded</FormLabel>
        <Switch
          isDisabled={!editing}
          isChecked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
          }}
        />
      </FormControl>
    </HStack>
  );
};

export default BackshellFields;
